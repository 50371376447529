@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url(../fonts/Montserrat-Regular.ttf);
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MontserratBold";
  src: local("Montserrat"), url(../fonts/Montserrat-Bold.ttf);
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MontserratLight";
  src: local("MontserratLight"), url(../fonts/Montserrat-Light.ttf);
  font-weight: light;
  font-style: normal;
  font-display: swap;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  user-select: none;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section {
  display: block
}

body {
  line-height: 1
}

ol,
ul {
  list-style: none
}

blockquote,
q {
  quotes: none
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none
}

table {
  border-spacing: 0;
  border-collapse: collapse
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible
}

a {
  background-color: transparent
}

img {
  border-style: none
}

button,
input,
optgroup,
select,
textarea {
  font: inherit;
  line-height: inherit;
  margin: 0
}

button,
input {
  overflow: visible
}

button,
select {
  text-transform: none
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  white-space: normal
}

progress {
  vertical-align: baseline
}

textarea {
  overflow: auto
}

[type="checkbox"],
[type="radio"] {
  padding: 0
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit
}

summary {
  display: list-item
}

template {
  display: none
}

[hidden] {
  display: none !important
}

@keyframes fade-in {
  0% {
    display: none;
    visibility: hidden;
    opacity: 0
  }

  1% {
    display: block;
    visibility: visible;
    opacity: 0
  }

  100% {
    display: block;
    visibility: visible;
    opacity: 1
  }
}

@keyframes fade-out {
  0% {
    display: block;
    visibility: visible;
    opacity: 1
  }

  99% {
    display: block;
    visibility: visible;
    opacity: 0
  }

  100% {
    display: none;
    visibility: hidden;
    opacity: 0
  }
}

:root {
  --fg: 0, 0, 0;
  --bg: 255, 255, 255;
  --white: #fff;
  --ghost: #F7F7F7;
  --ivory: #F6F6F4;
  --light-wash: #F7FBFB;
  --medium-wash: #EFF7F8;
  --dark-wash: #E6F3F3;
  --light-warm-gray: #EEEDEE;
  --dark-warm-gray: #A3ACB0;
  --cool-gray-0\.5: #EDEEF1;
  --cool-gray-1: #C5C5D2;
  --cool-gray-1\.5: #AEAEBB;
  --cool-gray-2: #8E8EA0;
  --cool-gray-3: #6E6E80;
  --cool-gray-4: #404452;
  --light-black: #191927;
  --medium-black: #0E0E1A;
  --black: #000;
  --breakpoint-xs: 0;
  --breakpoint-sm: 480px;
  --breakpoint-md: 720px;
  --breakpoint-lg: 960px;
  --breakpoint-xl: 1280px;
  --sans-serif: Montserrat, Helvetica, sans-serif;
  --serif: Charter, Georgia, serif;
  --monospace: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  --icons: IconsAI;
  --v: 1.4rem;
  --indent: 1.25em;
  --primary-color: #674188;
  --primary-bg: #FFFBF5;
  --secondary-color: #C3ACD0;
  --secondary-btn: #303F9F;
}

html {
  box-sizing: border-box;
  font-size: 17.5px
}

@media (min-width: 960px) {
  html {
    font-size: 20px
  }
}

*,
*:before,
*:after {
  box-sizing: inherit
}

::placeholder {
  color: rgba(0, 0, 0, 0.4);
  color: rgba(var(--fg), 0.4)
}

body {
  font-family: Montserrat, Georgia, serif;
  line-height: 1.4;
  letter-spacing: 0;
  background-color: var(--primary-bg);
  color: var(--primary-color);
  font-size: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  word-wrap: break-word;
  overflow-x: hidden
}

.clearfix:after {
  display: block;
  clear: both;
  content: ""
}

.no-select {
  user-select: none
}

.sr-only:not(:focus):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px
}

.icon,
[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="icon-"].right:after,
[class*=" icon-"].right:after,
.list-icon li:before {
  font-style: normal;
  font-weight: normal;
  font-family: Montserrat;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

[class^="icon-"]:before,
[class*=" icon-"]:before,
[class^="icon-"].right:after,
[class*=" icon-"].right:after {
  font-size: 0.833333em;
  line-height: 1;
  vertical-align: -15%
}

[class^="icon-"].right:after,
[class*=" icon-"].right:after {
  margin-left: -0.0625em
}

[class^="icon-"].right:before,
[class*=" icon-"].right:before {
  display: none;
  content: ""
}

.icon-left:before,
.icon-left.right:after {
  content: "←"
}

.icon-right:before,
.icon-right.right:after {
  content: "→"
}

.icon-up:before,
.icon-up.right:after {
  content: "↑"
}

.icon-down:before,
.icon-down.right:after {
  content: "↓"
}

.icon-download:before,
.icon-download.right:after {
  content: ""
}

.icon-upload:before,
.icon-upload.right:after {
  content: ""
}

.icon-upright:before,
.icon-upright.right:after,
.icon-external:before,
.icon-external.right:after {
  content: "↗"
}

.icon-downright:before,
.icon-downright.right:after {
  content: "↘"
}

.icon-upleft:before,
.icon-upleft.right:after {
  content: "↖"
}

.icon-downleft:before,
.icon-downleft.right:after {
  content: "↙"
}

.icon-navigateleft:before,
.icon-navigateleft.right:after,
.icon-previous:before,
.icon-previous.right:after {
  content: "◅"
}

.icon-navigateright:before,
.icon-navigateright.right:after,
.icon-next:before,
.icon-next.right:after {
  content: "▻"
}

.icon-navigateup:before,
.icon-navigateup.right:after {
  content: "△"
}

.icon-navigatedown:before,
.icon-navigatedown.right:after {
  content: "▽"
}

.icon-navigateleftwide:before,
.icon-navigateleftwide.right:after {
  content: "‹"
}

.icon-navigaterightwide:before,
.icon-navigaterightwide.right:after {
  content: "›"
}

.icon-navigateupwide:before,
.icon-navigateupwide.right:after {
  content: "⌃"
}

.icon-navigatedownwide:before,
.icon-navigatedownwide.right:after {
  content: "⌄"
}

.icon-menu:before,
.icon-menu.right:after {
  content: "≡"
}

.icon-close:before,
.icon-close.right:after {
  content: "❌"
}

.icon-plus:before,
.icon-plus.right:after {
  content: "+"
}

.icon-minus:before,
.icon-minus.right:after {
  content: "−"
}

.icon-check:before,
.icon-check.right:after {
  content: "✔"
}

.icon-refresh:before,
.icon-refresh.right:after {
  content: "↻"
}

.icon-search:before,
.icon-search.right:after {
  content: "🔍"
}

.icon-equals:before,
.icon-equals.right:after,
.icon-menualt:before,
.icon-menualt.right:after {
  content: "＝"
}

.icon-sync:before,
.icon-sync.right:after {
  content: "🗘"
}

.icon-paper:before,
.icon-paper.right:after {
  content: "📄"
}

.icon-papers:before,
.icon-papers.right:after {
  content: "🗍"
}

.icon-code:before,
.icon-code.right:after {
  content: ""
}

.icon-email:before,
.icon-email.right:after {
  content: "✉"
}

.icon-chat:before,
.icon-chat.right:after {
  content: "💬"
}

.icon-slides:before,
.icon-slides.right:after,
.icon-notes:before,
.icon-notes.right:after {
  content: "🗐"
}

.icon-software:before,
.icon-software.right:after,
.icon-lozenge:before,
.icon-lozenge.right:after {
  content: "◊"
}

.icon-release:before,
.icon-release.right:after,
.icon-hexagon:before,
.icon-hexagon.right:after {
  content: "⬡"
}

.icon-layers:before,
.icon-layers.right:after,
.icon-stack:before,
.icon-stack.right:after {
  content: "層"
}

.icon-playcircle:before,
.icon-playcircle.right:after {
  content: ""
}

.icon-pausecircle:before,
.icon-pausecircle.right:after {
  content: ""
}

.icon-stopcircle:before,
.icon-stopcircle.right:after {
  content: "■"
}

.icon-play:before,
.icon-play.right:after {
  content: "▶"
}

.icon-pause:before,
.icon-pause.right:after {
  content: "⏸"
}

.icon-stop:before,
.icon-stop.right:after {
  content: "⏹"
}

.icon-dropdown:before,
.icon-dropdown.right:after,
.icon-caretdown:before,
.icon-caretdown.right:after {
  content: "▾"
}

.icon-dropup:before,
.icon-dropup.right:after,
.icon-caretup:before,
.icon-caretup.right:after {
  content: "▴"
}

.icon-dropright:before,
.icon-dropright.right:after,
.icon-caretright:before,
.icon-caretright.right:after {
  content: "▸"
}

.icon-dropleft:before,
.icon-dropleft.right:after,
.icon-caretleft:before,
.icon-caretleft.right:after {
  content: "◂"
}

.icon-ellipsis:before,
.icon-ellipsis.right:after {
  content: "…"
}

.icon-verticalellipsis:before,
.icon-verticalellipsis.right:after {
  content: "⋮"
}

.icon-share:before,
.icon-share.right:after {
  content: "股"
}

.icon-twitter:before,
.icon-twitter.right:after,
.icon-tweet:before,
.icon-tweet.right:after {
  content: "🐦"
}

.icon-facebook:before,
.icon-facebook.right:after {
  content: ""
}

.icon-github:before,
.icon-github.right:after {
  content: ""
}

.icon-youtube:before,
.icon-youtube.right:after {
  content: ""
}

.icon-soundcloud:before,
.icon-soundcloud.right:after {
  content: ""
}

.icon-instagram:before,
.icon-instagram.right:after {
  content: ""
}

.icon-linkedin:before,
.icon-linkedin.right:after {
  content: ""
}

.icon-vimeo:before,
.icon-vimeo.right:after {
  content: ""
}

.icon-volumethree:before,
.icon-volumethree.right:after,
.icon-speakerthree:before,
.icon-speakerthree.right:after {
  content: "🔊"
}

.icon-volumetwo:before,
.icon-volumetwo.right:after,
.icon-speakertwo:before,
.icon-speakertwo.right:after {
  content: ""
}

.icon-volumeone:before,
.icon-volumeone.right:after,
.icon-speakerone:before,
.icon-speakerone.right:after {
  content: "🔉"
}

.icon-volumezero:before,
.icon-volumezero.right:after,
.icon-speakerzero:before,
.icon-speakerzero.right:after {
  content: "🔈"
}

.icon-fullscreen:before,
.icon-fullscreen.right:after,
.icon-expand:before,
.icon-expand.right:after {
  content: "扩"
}

.icon-fullscreenexit:before,
.icon-fullscreenexit.right:after,
.icon-contract:before,
.icon-contract.right:after {
  content: "㾭"
}

.icon-barup:before,
.icon-barup.right:after {
  content: ""
}

.icon-bardown:before,
.icon-bardown.right:after {
  content: ""
}

.icon-fork:before,
.icon-fork.right:after {
  content: ""
}

.icon-merge:before,
.icon-merge.right:after {
  content: ""
}

.icon-block:before,
.icon-block.right:after,
.icon-prohibited:before,
.icon-prohibited.right:after {
  content: "🚫"
}

.icon-globe:before,
.icon-globe.right:after,
.icon-earth:before,
.icon-earth.right:after,
.icon-world:before,
.icon-world.right:after {
  content: "🌐"
}

.icon-flag:before,
.icon-flag.right:after {
  content: "🏳"
}

.icon-lightning:before,
.icon-lightning.right:after {
  content: "⚡"
}

h1,
.h0,
.h1,
.h1\.5,
h2,
h3,
h4,
h5,
h6,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: normal
}

.h0 {
  font-size: 2.3333333333rem;
  line-height: 1.05;
  letter-spacing: -0.02em
}

@media (min-width: 480px) {
  .h0 {
    font-size: 2.6666666667rem
  }
}

@media (min-width: 960px) {
  .h0 {
    font-size: 3rem
  }
}

h1,
.h1 {
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: -0.0175em
}

@media (min-width: 960px) {

  h1,
  .h1 {
    font-size: 2.25rem;
    line-height: 1.05
  }
}

@media (min-width: 1280px) {

  h1,
  .h1 {
    font-size: 2.5rem
  }
}

.content h1,
.content .h1 {
  margin-top: 2.8rem;
  margin-bottom: .7rem
}

.h1\.5 {
  font-size: 1.6666666667rem;
  line-height: 1.1;
  letter-spacing: -0.015em
}

@media (min-width: 480px) {
  .h1\.5 {
    font-size: 1.8333333333rem
  }
}

@media (min-width: 960px) {
  .h1\.5 {
    font-size: 2rem
  }
}

.content .h1\.5 {
  margin-top: 2.8rem;
  margin-bottom: .7rem
}

h2,
.h2 {
  font-size: 1.3333333333rem;
  line-height: 1.25;
  letter-spacing: -0.0075em
}

@media (min-width: 960px) {

  h2,
  .h2 {
    font-size: 1.5rem
  }
}

.content h2,
.content .h2 {
  margin-top: 2.8rem;
  margin-bottom: .7rem
}

h3,
.h3 {
  font-size: 1.1666666667rem;
  line-height: 1.3;
  letter-spacing: -0.005em
}

@media (min-width: 960px) {

  h3,
  .h3 {
    font-size: 1.25rem
  }
}

.content h3,
.content .h3 {
  margin-top: 2.1rem;
  margin-bottom: .7rem
}

h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-size: 1rem
}

.content h4,
.content .h4,
.content h5,
.content .h5,
.content h6,
.content .h6 {
  margin-top: 2.1rem;
  margin-bottom: .7rem
}

.content h4,
.content .h4 {
  font-weight: bold
}

.content h5,
.content .h5 {
  font-variant-caps: small-caps;
  text-transform: lowercase
}

.content h6,
.content .h6 {
  font-style: italic
}

.content h1:first-child,
.content .h0:first-child,
.content .h1:first-child,
.content .h1\.5:first-child,
.content h2:first-child,
.content h3:first-child,
.content h4:first-child,
.content h5:first-child,
.content h6:first-child,
.content .h2:first-child,
.content .h3:first-child,
.content .h4:first-child,
.content .h5:first-child,
.content .h6:first-child {
  margin-top: 0
}

.h4-alt {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: unset;
  letter-spacing: unset;
  font-weight: bold !important
}

.h5-alt {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .8333333333rem;
  font-weight: unset;
  letter-spacing: unset;
  font-weight: bold !important;
  font-variant-caps: unset !important;
  text-transform: unset !important
}

.h6-alt {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: unset;
  letter-spacing: unset;
  font-weight: bold !important;
  font-style: normal !important
}

.xxlarge-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: -0.0175em;
  font-weight: unset
}

@media (min-width: 960px) {
  .xxlarge-copy {
    font-size: 2.25rem;
    line-height: 1.05
  }
}

@media (min-width: 1280px) {
  .xxlarge-copy {
    font-size: 2.5rem
  }
}

.xxlarge-small-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1.6666666667rem;
  line-height: 1.1;
  letter-spacing: -0.015em;
  font-weight: unset
}

@media (min-width: 480px) {
  .xxlarge-small-copy {
    font-size: 1.8333333333rem
  }
}

@media (min-width: 960px) {
  .xxlarge-small-copy {
    font-size: 2rem
  }
}

.xlarge-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.01em;
  font-weight: unset
}

@media (min-width: 960px) {
  .xlarge-copy {
    font-size: 1.6666666667rem
  }
}

.xlarge-small-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1.3333333333rem;
  line-height: 1.25;
  letter-spacing: -0.0075em;
  font-weight: unset
}

@media (min-width: 960px) {
  .xlarge-small-copy {
    font-size: 1.5rem
  }
}

.large-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1.1666666667rem;
  line-height: 1.3;
  letter-spacing: -0.005em;
  font-weight: unset
}

@media (min-width: 960px) {
  .large-copy {
    font-size: 1.25rem
  }
}

.medium-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: unset;
  letter-spacing: unset
}

.medium-small-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .9166666667rem;
  font-weight: unset;
  letter-spacing: unset
}

.medium-xsmall-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .8333333333rem;
  font-weight: unset;
  letter-spacing: unset
}

.small-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: unset;
  letter-spacing: unset
}

.xsmall-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .6666666667rem;
  font-weight: unset;
  letter-spacing: unset
}

.xxsmall-copy {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .5833333333rem;
  font-weight: unset;
  letter-spacing: unset
}

.small-caps {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .6666666667rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase
}

.small-caps-regular {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .6666666667rem;
  letter-spacing: 0.05em;
  font-weight: normal;
  text-transform: uppercase
}

.xsmall-caps {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .5rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase
}

.xsmall-caps-regular {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .5rem;
  letter-spacing: 0.05em;
  font-weight: normal;
  text-transform: uppercase
}

.xxlarge {
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: -0.0175em
}

@media (min-width: 960px) {
  .xxlarge {
    font-size: 2.25rem;
    line-height: 1.05
  }
}

@media (min-width: 1280px) {
  .xxlarge {
    font-size: 2.5rem
  }
}

.xxlarge-small {
  font-size: 1.6666666667rem;
  line-height: 1.1;
  letter-spacing: -0.015em
}

@media (min-width: 480px) {
  .xxlarge-small {
    font-size: 1.8333333333rem
  }
}

@media (min-width: 960px) {
  .xxlarge-small {
    font-size: 2rem
  }
}

.xlarge {
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.01em
}

@media (min-width: 960px) {
  .xlarge {
    font-size: 1.6666666667rem
  }
}

.xlarge-small {
  font-size: 1.3333333333rem;
  line-height: 1.25;
  letter-spacing: -0.0075em
}

@media (min-width: 960px) {
  .xlarge-small {
    font-size: 1.5rem
  }
}

.large {
  font-size: 1.1666666667rem;
  line-height: 1.3;
  letter-spacing: -0.005em
}

@media (min-width: 960px) {
  .large {
    font-size: 1.25rem
  }
}

.small-caps-alt {
  font-variant-caps: small-caps;
  text-transform: lowercase
}

hr {
  opacity: 0.1;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 1px;
  background-color: currentColor
}

.color-white hr {
  background: #fff
}

.content hr {
  margin: 2.1rem 0
}

.hr-no-height {
  height: 0
}

.hr-strong {
  opacity: 0.2
}

.content p,
.content ol,
.content ul,
.content blockquote {
  font-size: 1em;
  margin-bottom: 1.4rem
}

.content ol,
.content ul {
  padding-left: 1.25em
}

@media (min-width: 1280px) {

  .content ol,
  .content ul {
    padding-left: 0
  }
}

.content ol {
  list-style-type: decimal
}

.content ul {
  list-style-type: disc
}

.content li {
  margin-bottom: .4666666667rem
}

.content li ol,
.content li ul {
  padding-left: 1.25em;
  margin-top: .4666666667rem;
  margin-bottom: 0
}

.content li ol {
  list-style-type: lower-alpha
}

.content li li ol {
  list-style-type: lower-roman
}

.content li ul {
  list-style-type: circle
}

.content li li ul {
  list-style-type: square
}

blockquote {
  padding-left: 1.25em;
  position: relative;
  font-style: italic
}

@media (min-width: 1280px) {
  blockquote {
    padding-left: 0
  }
}

blockquote em {
  font-style: normal
}

blockquote p {
  margin-bottom: 0
}

blockquote:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(var(--fg), 0.2);
  left: 0
}

@media (min-width: 1280px) {
  blockquote:after {
    left: -1em
  }
}

strong,
.strong {
  font-weight: bold
}

em,
.em {
  font-style: italic
}

small,
.small {
  font-size: .8333333333em
}

mark,
.mark {
  background-color: #000;
  background-color: rgba(var(--fg), 1);
  color: #fff;
  color: rgba(var(--bg), 1)
}

sub,
sup {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: unset;
  letter-spacing: unset;
  font-size: .6666666667em;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sub a:not(.no-underline),
sup a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important
}

sub {
  bottom: -0.25em
}

sup {
  top: -0.5em
}

pre,
code,
kbd,
samp {
  font-family: Montserrat, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  border-radius: 4px
}

code,
kbd,
samp {
  font-size: .8333333333em
}

pre,
code,
kbd {
  background-color: rgba(0, 0, 0, 0.03);
  background-color: rgba(var(--fg), 0.03)
}

code,
kbd {
  padding: 0 0.2em
}

pre {
  font-size: .75em;
  padding: 1em;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  overflow: auto
}

pre code {
  font-size: 1em;
  background-color: transparent !important;
  color: inherit;
  padding: 0
}

.list-inline>* {
  display: inline-block
}

.list-inline>*:not(:last-child) {
  margin-right: .75em
}

.list-inline-0>* {
  display: inline-block
}

.list-inline-0>*:not(:last-child) {
  margin-right: 0
}

.list-unstyled {
  padding-left: 0 !important;
  list-style-type: none !important
}

.list-unstyled li {
  margin-bottom: 0
}

.list-unbulleted {
  padding-left: 0 !important;
  list-style-type: none !important
}

.list-indented {
  padding-left: 1.25em !important
}

.list-compact li {
  margin-bottom: 0
}

.content ol.list-lower-latin {
  list-style-type: lower-latin
}

.content ol.list-lower-roman {
  list-style-type: lower-roman
}

.content ol.list-upper-latin {
  list-style-type: upper-latin
}

.content ol.list-upper-roman {
  list-style-type: upper-roman
}

.content ol.list-decimal-leading-zero {
  list-style-type: decimal-leading-zero
}

.list-icon {
  list-style-type: none !important
}

.list-icon li {
  position: relative
}

.list-icon li:before {
  font-size: 0.833333em;
  position: absolute;
  left: -1.416667em;
  top: 0.1em
}

.list-icon-check li:before {
  content: "check"
}

.list-icon-close li:before {
  content: "close"
}

.content section:not(.footnotes) {
  margin-bottom: 1.4rem
}

a {
  text-decoration: none;
  color: inherit
}

.content a:not(.btn):not(.no-style):not(.faded):not(.faded-heavy):not(.faded-light):not(.faded-xlight):not(.faded-xxlight) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.4);
  text-decoration-color: rgba(var(--fg), 0.4)
}

.content a:not(.btn):not(.no-style):not(.faded):not(.faded-heavy):not(.faded-light):not(.faded-xlight):not(.faded-xxlight):hover {
  opacity: .6 !important
}

.color-current a:not(.btn) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: currentColor !important
}

.fade:hover {
  opacity: .6 !important
}

.fade-heavy:hover {
  opacity: .7 !important
}

.fade-xheavy:hover {
  opacity: .8 !important
}

.fade-xxheavy:hover {
  opacity: .9 !important
}

.no-fade:hover {
  opacity: 1 !important
}

.faded-heavy {
  opacity: .7 !important
}

.faded-heavy:hover {
  opacity: 1 !important
}

.faded {
  opacity: .6 !important
}

.faded:hover {
  opacity: 1 !important
}

.faded-light {
  opacity: .5 !important
}

.faded-light:hover {
  opacity: 1 !important
}

.faded-xlight {
  opacity: .4 !important
}

.faded-xlight:hover {
  opacity: 1 !important
}

.faded-xxlight {
  opacity: .3 !important
}

.faded-xxlight:hover {
  opacity: 1 !important
}

.fade-parent:hover .fade-child {
  opacity: 0.6
}

.fade-parent:hover .fade-child-heavy {
  opacity: 0.7
}

.fade-parent:hover .fade-child-xheavy {
  opacity: 0.8
}

.fade-parent:hover .fade-child-xxheavy {
  opacity: 0.9
}

.underline:hover {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.4);
  text-decoration-color: rgba(var(--fg), 0.4)
}

.underlined {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.4);
  text-decoration-color: rgba(var(--fg), 0.4)
}

.no-underline {
  text-decoration: none !important
}

.no-underline-parent a {
  text-decoration: none !important
}

.darken {
  transition: filter 100ms cubic-bezier(0.645, 0.045, 0.355, 1)
}

.darken:hover {
  filter: brightness(80%)
}

.transition-opacity {
  transition: opacity 150ms cubic-bezier(0.645, 0.045, 0.355, 1)
}

.btns .btn:not(:last-child),
.btns button:not(:last-child) {
  margin-right: 1.6666666667em
}

.btns .btn-padded:not(:last-child) {
  margin-right: .8333333333em
}

.btn.btn-empty {
  opacity: 1 !important;
  transition-duration: 0.3s;
  box-shadow: inset 0 0 0 0 var(--primary-color);
}

.btn.btn-empty:hover {
  opacity: 1 !important;
  box-shadow: inset 0 0 0 60px var(--primary-color);
  color: var(--primary-bg) !important;
  border-color: var(--primary-color) !important;
}

.btn,
button {
  cursor: pointer;
  color: inherit;
  background-color: inherit;
  display: inline-block;
  border: 0;
  border-radius: 4px;
  padding: 0;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .6666666667rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none !important;
  transition: .3s;
}

.btn:hover,
button:hover {
  opacity: .6
}

.btn.icon,
.btn[class^="icon-"]:before,
.btn[class*=" icon-"]:before,
.btn[class^="icon-"].right:after,
.btn[class*=" icon-"].right:after,
button.icon,
button[class^="icon-"]:before,
button[class*=" icon-"]:before,
button[class^="icon-"].right:after,
button[class*=" icon-"].right:after {
  position: relative;
  font-size: 1.166667em;
  vertical-align: -3px
}

.btn[class^="icon-"]:before,
.btn[class*=" icon-"]:before,
button[class^="icon-"]:before,
button[class*=" icon-"]:before {
  margin-left: -0.25em;
  margin-right: 0.2em
}

.btn.disabled,
.btn:disabled,
button.disabled,
button:disabled {
  opacity: 0.4 !important;
  cursor: default
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none
}

.btn-padded {
  color: #000 !important;
  color: rgba(var(--fg), 1) !important;
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05);
  backdrop-filter: blur(1.5rem);
  -webkit-backdrop-filter: blur(1.5rem);
  padding: 0.7em 1.125em 0.6em;
  margin-bottom: .8333333333em
}

.btn-padded:hover:not(.disabled):not(:disabled) {
  color: #000 !important;
  color: rgba(var(--fg), 1) !important;
  background-color: rgba(0, 0, 0, 0.08);
  background-color: rgba(var(--fg), 0.08);
  opacity: 1 !important
}

.btn-padded.btn-bg-bg {
  background-color: rgba(255, 255, 255, 0.7);
  background-color: rgba(var(--bg), 0.7)
}

.btn-padded.btn-bg-bg:hover:not(.disabled):not(:disabled) {
  background-color: rgba(255, 255, 255, 0.8);
  background-color: rgba(var(--bg), 0.8)
}

.btn-padded.right {
  padding-right: 1em
}

.btn-padded[class^="icon-"].right:after,
.btn-padded[class*=" icon-"].right:after {
  right: -0.2em
}

.btn-padded.btn-circle {
  padding: 0.7em 1.333333em 0.6em;
  border-radius: 3em;
  margin-left: -1px;
  border: 3px solid var(--primary-color);
}

.btn-padded.btn-dark {
  color: var(--primary-bg) !important;
  background-color: var(--primary-color);
  transition: .3s;
}

.btn-padded.btn-dark:hover:not(.disabled):not(:disabled) {
  color: var(--primary-color) !important;
  background-color: var(--primary-bg);
  opacity: 1 !important;
  animation-duration: 4s;
  animation-timing-function: ease;
}

.btn-ypadded {
  padding-top: 0.75em;
  padding-bottom: 0.65em;
  display: block
}

.button-unstyled {
  border: 0;
  padding: 0;
  font: inherit;
  text-transform: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  color: inherit
}

img,
video,
iframe {
  max-width: 100%;
  display: block
}

.content img,
.content video,
.content iframe {
  margin-bottom: 1.4rem
}

svg {
  fill: currentColor
}

figure {
  font-family: Montserrat, Helvetica, sans-serif;
  margin-bottom: 1.4rem
}

figcaption,
.caption,
.caption-unspaced {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: unset;
  letter-spacing: unset
}

figcaption a:not(.no-underline),
.caption a:not(.no-underline),
.caption-unspaced a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important
}

.small-caption {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .6666666667rem;
  font-weight: unset;
  letter-spacing: unset;
  padding-top: .35rem
}

.small-caption a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important
}

figcaption,
.caption,
.small-caption,
.caption-unspaced {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5)
}

figcaption,
.caption,
.small-caption {
  padding-top: .4666666667rem;
  margin-top: -1.4rem
}

.caption,
.small-caption {
  display: block;
  margin-bottom: 1.4rem
}

p>.caption:only-of-type {
  margin-top: -1.4rem
}

img+br+.caption {
  margin-top: -2.8rem !important
}

.caption-unspaced {
  display: block
}

@media (min-width: 720px) {

  .footnote-aside,
  .aside,
  .aside-left,
  .aside-right {
    padding-left: .7rem;
    padding-right: .7rem;
    width: 33.3333333333%;
    position: absolute;
    right: 0
  }
}

@media (min-width: 960px) {

  .footnote-aside,
  .aside,
  .aside-left,
  .aside-right {
    width: 50%
  }
}

@media (min-width: 720px) {

  .aside,
  .aside-left {
    transform: translateX(100%)
  }
}

@media (min-width: 1280px) {

  .aside,
  .aside-left {
    left: 0;
    transform: translateX(-100%)
  }
}

.footnote-aside {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: unset;
  letter-spacing: unset;
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5);
  display: none;
  margin-top: calc(-1.4em - 2px)
}

.footnote-aside a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important
}

@media (min-width: 720px) {
  .footnote-aside {
    display: block
  }
}

@media (min-width: 720px) {

  .footnote-aside,
  .aside-right {
    transform: translateX(100%)
  }
}

@media (min-width: 1280px) {
  .title-aside {
    padding-left: .7rem;
    padding-right: .7rem;
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    width: 50%
  }
}

.scrim,
.inset-border {
  position: relative
}

.scrim:before,
.inset-border:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  pointer-events: none
}

.scrim:before {
  background-color: rgba(0, 0, 0, 0.025)
}

.inset-border:before {
  border: 1px inset rgba(0, 0, 0, 0.075)
}

.bg-cover {
  display: block;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat
}

.bg-contain {
  display: block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat
}

.bg-shadow {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.02) 0%, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 0) 95%, rgba(0, 0, 0, 0.02) 100%)
}

.shadowed-light {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.03)
}

.shadowed {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05)
}

.shadowed-medium-heavy {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.075)
}

.shadowed-heavy {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1)
}

.shadowed-xheavy {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15)
}

.text-shadowed {
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.1)
}

.backdrop-filter-blur {
  backdrop-filter: blur(1.5rem);
  -webkit-backdrop-filter: blur(1.5rem)
}

table {
  font-family: Montserrat, Helvetica, sans-serif;
  font-variant-numeric: tabular-nums;
  border-collapse: separate;
  border-spacing: 2px;
  overflow-x: auto;
  margin-top: 2.1rem;
  margin-bottom: 2.1rem;
  width: 100%;
  display: inherit
}

th {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .6666666667rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  vertical-align: bottom
}

th,
td {
  padding: .35rem
}

.text-align-left {
  text-align: left;
}

td {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .8333333333rem;
  font-weight: unset;
  letter-spacing: unset
}

tr:nth-child(odd) td {
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05)
}

.table-unstyled,
.table-unstyled>table {
  margin-top: unset;
  margin-bottom: unset;
  border-spacing: unset
}

.table-unstyled th,
.table-unstyled td {
  padding: unset;
  font-family: unset;
  font-size: unset;
  font-weight: unset;
  text-transform: unset;
  letter-spacing: unset;
  line-height: unset;
  background-color: unset !important
}

.table-compact,
.table-compact>table {
  border-collapse: collapse;
  border-spacing: unset
}

.table-compact th,
.table-compact td {
  border-bottom: 1px solid rgba(var(--fg), 0.0875);
  padding: .175rem;
  background-color: unset !important
}

.table-compact th.th-strong,
.table-compact th.td-strong,
.table-compact td.th-strong,
.table-compact td.td-strong {
  border-color: rgba(var(--fg), 0.2)
}

.container {
  max-width: 1470px;
  width: 93.3333333333%;
  margin-left: auto;
  margin-right: auto
}

@media (min-width: 720px) {
  .container {
    width: 93.3333333333%
  }
}

.wide {
  position: relative;
  max-width: 1470px;
  width: 93.3333333333vw;
  left: 0;
  margin-top: 4.2rem;
  margin-bottom: 4.2rem
}

@media (min-width: 720px) {
  .wide {
    width: 93.3333333333vw
  }
}

@media (min-width: 1280px) {
  .wide {
    margin-left: calc(50% - 46.6666666667vw);
    transform: translateX(calc(46.6666666667vw - 50%))
  }
}

.wide img {
  width: 100%
}

.full {
  position: relative;
  width: 100vw;
  left: 0;
  transform: translateX(-3.3333333333vw);
  margin-top: 4.2rem;
  margin-bottom: 4.2rem
}

@media (min-width: 720px) {
  .full {
    transform: translateX(-3.3333333333vw)
  }
}

@media (min-width: 1280px) {
  .full {
    left: 50%;
    transform: translateX(-50vw)
  }
}

.full img {
  width: 100%
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.6666666667vw;
  margin-right: -1.6666666667vw
}

@media (min-width: 720px) {
  .row {
    margin-left: -.7rem;
    margin-right: -.7rem
  }
}

.no-gutters {
  margin-right: 0;
  margin-left: 0
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-right: 0;
  padding-left: 0
}

.hairline-gutters {
  margin-left: -1.5px;
  margin-right: -1.5px
}

@media (min-width: 720px) {
  .hairline-gutters {
    margin-left: -2px;
    margin-right: -2px
  }
}

.hairline-gutters>.col,
.hairline-gutters>[class*="col-"] {
  padding-left: 1.5px;
  padding-right: 1.5px
}

@media (min-width: 720px) {

  .hairline-gutters>.col,
  .hairline-gutters>[class*="col-"] {
    padding-left: 2px;
    padding-right: 2px
  }
}

.thin-gutters {
  margin-right: -.25rem;
  margin-left: -.25rem
}

.thin-gutters>.col,
.thin-gutters>[class*="col-"] {
  padding-right: .25rem;
  padding-left: .25rem
}

.narrow-gutters {
  margin-left: -.8333333333vw;
  margin-right: -.8333333333vw
}

@media (min-width: 720px) {
  .narrow-gutters {
    margin-left: -.35rem;
    margin-right: -.35rem
  }
}

.narrow-gutters>.col,
.narrow-gutters>[class*="col-"] {
  padding-left: .8333333333vw;
  padding-right: .8333333333vw
}

@media (min-width: 720px) {

  .narrow-gutters>.col,
  .narrow-gutters>[class*="col-"] {
    padding-left: .35rem;
    padding-right: .35rem
  }
}

.col,
.col-auto,
.col-2\.4,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm,
.col-sm-auto,
.col-sm-2\.4,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md,
.col-md-auto,
.col-md-2\.4,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg,
.col-lg-auto,
.col-lg-2\.4,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl,
.col-xl-auto,
.col-xl-2\.4,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.content:not(.no-col) {
  position: relative;
  width: 100%;
  padding-right: 1.6666666667vw;
  padding-left: 1.6666666667vw
}

@media (min-width: 720px) {

  .col,
  .col-auto,
  .col-2\.4,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-sm,
  .col-sm-auto,
  .col-sm-2\.4,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-md,
  .col-md-auto,
  .col-md-2\.4,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-lg,
  .col-lg-auto,
  .col-lg-2\.4,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-xl,
  .col-xl-auto,
  .col-xl-2\.4,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .content:not(.no-col) {
    padding-right: .7rem;
    padding-left: .7rem
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.col,
.col-auto,
.col-2\.4,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm,
.col-sm-auto,
.col-sm-2\.4,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md,
.col-md-auto,
.col-md-2\.4,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg,
.col-lg-auto,
.col-lg-2\.4,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-xl,
.col-xl-auto,
.col-xl-2\.4,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.content:not(.no-col) {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%
}

.col-2\.4 {
  flex: 0 0 20%;
  max-width: 20%
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%
}

.content:not(.no-col),
.post-footer .col:first-of-type,
.post-footer .col:nth-of-type(2) {
  flex: 0 0 100%;
  max-width: 100%
}

.order-first {
  order: -1
}

.order-last {
  order: 13
}

.order-0 {
  order: 0
}

.order-1 {
  order: 1
}

.order-2 {
  order: 2
}

.order-3 {
  order: 3
}

.order-4 {
  order: 4
}

.order-5 {
  order: 5
}

.order-6 {
  order: 6
}

.order-7 {
  order: 7
}

.order-8 {
  order: 8
}

.order-9 {
  order: 9
}

.order-10 {
  order: 10
}

.order-11 {
  order: 11
}

.order-12 {
  order: 12
}

.offset-1 {
  margin-left: 8.3333333333%
}

.offset-2 {
  margin-left: 16.6666666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.3333333333%
}

.offset-5 {
  margin-left: 41.6666666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.3333333333%
}

.offset-8 {
  margin-left: 66.6666666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.3333333333%
}

.offset-11 {
  margin-left: 91.6666666667%
}

@media (min-width: 480px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-sm-2\.4 {
    flex: 0 0 20%;
    max-width: 20%
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-sm-first {
    order: -1
  }

  .order-sm-last {
    order: 13
  }

  .order-sm-0 {
    order: 0
  }

  .order-sm-1 {
    order: 1
  }

  .order-sm-2 {
    order: 2
  }

  .order-sm-3 {
    order: 3
  }

  .order-sm-4 {
    order: 4
  }

  .order-sm-5 {
    order: 5
  }

  .order-sm-6 {
    order: 6
  }

  .order-sm-7 {
    order: 7
  }

  .order-sm-8 {
    order: 8
  }

  .order-sm-9 {
    order: 9
  }

  .order-sm-10 {
    order: 10
  }

  .order-sm-11 {
    order: 11
  }

  .order-sm-12 {
    order: 12
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%
  }
}

@media (min-width: 720px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-md-2\.4 {
    flex: 0 0 20%;
    max-width: 20%
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .content:not(.no-col),
  .post-footer .col:nth-of-type(2) {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-md-first {
    order: -1
  }

  .order-md-last {
    order: 13
  }

  .order-md-0 {
    order: 0
  }

  .order-md-1 {
    order: 1
  }

  .order-md-2 {
    order: 2
  }

  .order-md-3 {
    order: 3
  }

  .order-md-4 {
    order: 4
  }

  .order-md-5 {
    order: 5
  }

  .order-md-6 {
    order: 6
  }

  .order-md-7 {
    order: 7
  }

  .order-md-8 {
    order: 8
  }

  .order-md-9 {
    order: 9
  }

  .order-md-10 {
    order: 10
  }

  .order-md-11 {
    order: 11
  }

  .order-md-12 {
    order: 12
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.3333333333%
  }

  .offset-md-2 {
    margin-left: 16.6666666667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.3333333333%
  }

  .offset-md-5 {
    margin-left: 41.6666666667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.3333333333%
  }

  .offset-md-8 {
    margin-left: 66.6666666667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.3333333333%
  }

  .offset-md-11 {
    margin-left: 91.6666666667%
  }
}

@media (min-width: 960px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-lg-2\.4 {
    flex: 0 0 20%;
    max-width: 20%
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .content:not(.no-col),
  .post-footer .col:nth-of-type(2) {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-lg-first {
    order: -1
  }

  .order-lg-last {
    order: 13
  }

  .order-lg-0 {
    order: 0
  }

  .order-lg-1 {
    order: 1
  }

  .order-lg-2 {
    order: 2
  }

  .order-lg-3 {
    order: 3
  }

  .order-lg-4 {
    order: 4
  }

  .order-lg-5 {
    order: 5
  }

  .order-lg-6 {
    order: 6
  }

  .order-lg-7 {
    order: 7
  }

  .order-lg-8 {
    order: 8
  }

  .order-lg-9 {
    order: 9
  }

  .order-lg-10 {
    order: 10
  }

  .order-lg-11 {
    order: 11
  }

  .order-lg-12 {
    order: 12
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%
  }
}

@media (min-width: 1280px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
  }

  .col-xl-2\.4 {
    flex: 0 0 20%;
    max-width: 20%
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .post-footer .col:first-of-type {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .content:not(.no-col),
  .post-footer .col:nth-of-type(2) {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .order-xl-first {
    order: -1
  }

  .order-xl-last {
    order: 13
  }

  .order-xl-0 {
    order: 0
  }

  .order-xl-1 {
    order: 1
  }

  .order-xl-2 {
    order: 2
  }

  .order-xl-3 {
    order: 3
  }

  .order-xl-4 {
    order: 4
  }

  .order-xl-5 {
    order: 5
  }

  .order-xl-6 {
    order: 6
  }

  .order-xl-7 {
    order: 7
  }

  .order-xl-8 {
    order: 8
  }

  .order-xl-9 {
    order: 9
  }

  .order-xl-10 {
    order: 10
  }

  .order-xl-11 {
    order: 11
  }

  .order-xl-12 {
    order: 12
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .content:not(.no-col) {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%
  }
}

.multicol-1,
.multicol-2,
.multicol-3,
.multicol-4,
.multicol-5,
.multicol-6,
.multicol-sm-1,
.multicol-sm-2,
.multicol-sm-3,
.multicol-sm-4,
.multicol-sm-5,
.multicol-sm-6,
.multicol-md-1,
.multicol-md-2,
.multicol-md-3,
.multicol-md-4,
.multicol-md-5,
.multicol-md-6,
.multicol-lg-1,
.multicol-lg-2,
.multicol-lg-3,
.multicol-lg-4,
.multicol-lg-5,
.multicol-lg-6,
.multicol-xl-1,
.multicol-xl-2,
.multicol-xl-3,
.multicol-xl-4,
.multicol-xl-5,
.multicol-xl-6 {
  column-gap: 3.3333333333vw
}

@media (min-width: 720px) {

  .multicol-1,
  .multicol-2,
  .multicol-3,
  .multicol-4,
  .multicol-5,
  .multicol-6,
  .multicol-sm-1,
  .multicol-sm-2,
  .multicol-sm-3,
  .multicol-sm-4,
  .multicol-sm-5,
  .multicol-sm-6,
  .multicol-md-1,
  .multicol-md-2,
  .multicol-md-3,
  .multicol-md-4,
  .multicol-md-5,
  .multicol-md-6,
  .multicol-lg-1,
  .multicol-lg-2,
  .multicol-lg-3,
  .multicol-lg-4,
  .multicol-lg-5,
  .multicol-lg-6,
  .multicol-xl-1,
  .multicol-xl-2,
  .multicol-xl-3,
  .multicol-xl-4,
  .multicol-xl-5,
  .multicol-xl-6 {
    column-gap: 1.4rem
  }
}

.multicol-1 {
  column-count: 1
}

.multicol-2 {
  column-count: 2
}

.multicol-3 {
  column-count: 3
}

.multicol-4 {
  column-count: 4
}

.multicol-5 {
  column-count: 5
}

.multicol-6 {
  column-count: 6
}

@media (min-width: 480px) {
  .multicol-sm-1 {
    column-count: 1
  }

  .multicol-sm-2 {
    column-count: 2
  }

  .multicol-sm-3 {
    column-count: 3
  }

  .multicol-sm-4 {
    column-count: 4
  }

  .multicol-sm-5 {
    column-count: 5
  }

  .multicol-sm-6 {
    column-count: 6
  }
}

@media (min-width: 720px) {
  .multicol-md-1 {
    column-count: 1
  }

  .multicol-md-2 {
    column-count: 2
  }

  .multicol-md-3 {
    column-count: 3
  }

  .multicol-md-4 {
    column-count: 4
  }

  .multicol-md-5 {
    column-count: 5
  }

  .multicol-md-6 {
    column-count: 6
  }
}

@media (min-width: 960px) {
  .multicol-lg-1 {
    column-count: 1
  }

  .multicol-lg-2 {
    column-count: 2
  }

  .multicol-lg-3 {
    column-count: 3
  }

  .multicol-lg-4 {
    column-count: 4
  }

  .multicol-lg-5 {
    column-count: 5
  }

  .multicol-lg-6 {
    column-count: 6
  }
}

@media (min-width: 1280px) {
  .multicol-xl-1 {
    column-count: 1
  }

  .multicol-xl-2 {
    column-count: 2
  }

  .multicol-xl-3 {
    column-count: 3
  }

  .multicol-xl-4 {
    column-count: 4
  }

  .multicol-xl-5 {
    column-count: 5
  }

  .multicol-xl-6 {
    column-count: 6
  }
}

legend,
label {
  display: inline-block
}

select {
  word-wrap: normal;
  background-color: transparent;
  background-image: none
}

.form-group {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .8333333333rem;
  font-weight: unset;
  letter-spacing: unset;
  margin-bottom: .7rem
}

.form-control {
  color: inherit;
  display: block;
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  outline: none;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 0.5em !important;
  padding-right: 0.5em;
  height: calc(1.4em + 10px + 2px);
  border-color: rgba(0, 0, 0, 0);
  border-color: rgba(var(--fg), 0);
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05)
}

.form-control:hover {
  border-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(var(--fg), 0.1)
}

.form-control:focus {
  border-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(var(--fg), 0.5)
}

.form-control:disabled,
.form-control[readonly] {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(var(--fg), 0.1);
  opacity: 1
}

.form-control-plaintext {
  color: inherit;
  background-color: transparent;
  display: block;
  width: 100%;
  border-width: 1px 0;
  outline: none;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  height: calc(1.4em + 10px + 2px);
  border-color: rgba(0, 0, 0, 0);
  border-color: rgba(var(--fg), 0)
}

textarea.form-control {
  height: auto
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
  padding-top: 0.2em;
  padding-bottom: 0.2em
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%
}

::-webkit-file-upload-button {
  color: inherit;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  outline: none;
  padding-top: 6px;
  padding-bottom: 4px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  height: calc(1.4em + 10px + 2px);
  border-color: rgba(0, 0, 0, 0);
  border-color: rgba(var(--fg), 0);
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05)
}

::-webkit-file-upload-button:hover {
  border-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(var(--fg), 0.1)
}

::-webkit-file-upload-button:focus {
  border-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(var(--fg), 0.5)
}

label+.form-control,
label+.form-control-wrap {
  margin-top: .175rem
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -.4166666667em;
  margin-left: -.4166666667em
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-right: .4166666667em;
  padding-left: .4166666667em
}

.col-form-label {
  padding-top: 3px;
  padding-bottom: 1px;
  margin-bottom: 0;
  font-size: inherit
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.125rem
}

.form-check-input {
  position: absolute;
  margin-top: 0.25em;
  margin-left: -1.125rem
}

.form-check-input:disabled~.form-check-label {
  opacity: 0.4 !important
}

.form-check-input[type="checkbox"] {
  transform: translateY(0.02em)
}

.form-check-label {
  margin-bottom: 0
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 1em
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: -0.2em;
  margin-right: 0.4em;
  margin-left: 0
}

.form-check-inline .form-check-input[type="checkbox"] {
  transform: translateY(0)
}

.content .timeline,
.timeline {
  list-style-type: none;
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
  padding-left: 0;
  margin-bottom: 0
}

@media (min-width: 720px) {

  .content .timeline,
  .timeline {
    margin-left: 8.5rem;
    padding-left: 1.5rem;
    padding-top: 4.2rem;
    padding-bottom: 4.2rem;
    border-left: 1px solid #CDCDCD
  }
}

@media (min-width: 720px) {

  .content .timeline.no-date,
  .timeline.no-date {
    margin-left: 0
  }
}

@media (min-width: 1280px) {

  .content .timeline,
  .timeline {
    margin-left: 0
  }
}

.content .timeline h2,
.content .timeline h3,
.content .timeline h4,
.content .timeline h5,
.content .timeline h6,
.timeline h2,
.timeline h3,
.timeline h4,
.timeline h5,
.timeline h6 {
  margin-top: 0;
  margin-bottom: 1.4rem
}

.content .timeline>li,
.timeline>li {
  position: relative;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .9166666667rem;
  font-weight: unset;
  letter-spacing: unset
}

.content .timeline>li:not(:last-of-type),
.timeline>li:not(:last-of-type) {
  margin-bottom: 1.4rem
}

.content .timeline>li:before,
.timeline>li:before {
  display: block;
  position: unset;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .5rem;
  letter-spacing: 0.05em;
  font-weight: bold;
  text-transform: uppercase;
  content: attr(data-date);
  color: rgba(0, 0, 0, 0.4);
  color: rgba(var(--fg), 0.4);
  text-align: left;
  width: auto;
  padding-right: 0;
  margin-left: 0;
  margin-bottom: .35rem
}

.content .timeline>li:after,
.timeline>li:after {
  display: none
}

@media (min-width: 720px) {

  .content .timeline>li:before,
  .content .timeline>li:after,
  .timeline>li:before,
  .timeline>li:after {
    position: absolute;
    display: block
  }

  .content .timeline>li:before,
  .timeline>li:before {
    text-align: right;
    top: 0;
    left: calc(-10rem - 1px);
    width: 7rem;
    transform: translateY(0.291667rem);
    margin-bottom: 0
  }

  .content .timeline>li:after,
  .timeline>li:after {
    content: "";
    background-color: #CDCDCD;
    top: calc(0.4rem);
    left: calc(-1.5rem - 5px);
    height: 9px;
    width: 9px;
    border-radius: 50%
  }
}

.accordion-input {
  display: none
}

.accordion-label {
  display: block;
  cursor: pointer
}

.accordion-label-inner {
  position: relative
}

.accordion-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
  right: 0;
  user-select: none
}

.accordion-icon-open {
  display: none
}

.accordion-input:checked~.accordion-label .accordion-icon-open {
  display: block
}

.accordion-icon-closed {
  display: block
}

.accordion-input:checked~.accordion-label .accordion-icon-closed {
  display: none
}

.accordion-content {
  overflow: hidden;
  position: relative
}

.accordion-content-transition {
  max-height: 20em;
  transition: max-height 250ms ease-in-out
}

.accordion-input:not(:checked)~.accordion-content {
  max-height: 0 !important
}

.switch-input {
  position: absolute;
  opacity: 0
}

.switch,
.switch-label {
  position: relative
}

.switch,
.switch-selection {
  border-radius: 4px;
  height: 1.68rem
}

.switch-label,
.switch-selection {
  width: 50%
}

.switch {
  max-width: 16rem;
  background-color: rgba(0, 0, 0, 0.03);
  background-color: rgba(var(--fg), 0.03)
}

.switch-label {
  z-index: 2;
  float: left;
  text-align: center;
  cursor: pointer;
  top: calc(50% + 0.05rem);
  transform: translateY(-50%);
  line-height: 3;
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5);
  margin-bottom: 0
}

.switch-label:hover {
  color: rgba(0, 0, 0, 0.7);
  color: rgba(var(--fg), 0.7)
}

.switch-selection {
  display: block;
  position: absolute;
  z-index: 1;
  left: 0;
  background-color: rgba(0, 0, 0, 0.08);
  background-color: rgba(var(--fg), 0.08);
  transition: left 0.1s ease-out
}

.switch-input:checked+.switch-label {
  color: #000;
  color: rgba(var(--fg), 1)
}

.switch-input:checked+.switch-label-on~.switch-selection {
  left: 50%
}

.tabs>input {
  position: absolute;
  opacity: 0
}

.tabs>label {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5);
  cursor: pointer;
  margin-bottom: 0
}

.tabs>label:hover {
  color: rgba(0, 0, 0, 0.7);
  color: rgba(var(--fg), 0.7)
}

.tabs>input:checked+label {
  color: #000;
  color: rgba(var(--fg), 1)
}

.dropselect-wrap {
  position: relative;
  display: inline-block;
  min-width: 5rem
}

.dropselect-wrap:after {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  pointer-events: none;
  font-style: normal;
  font-weight: normal;
  font-family: IconsAI;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "▽"
}

.dropselect-wrap.form-control-wrap:after {
  right: 0.25rem
}

.dropselect {
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  color: inherit;
  padding-right: 1.4em
}

.dropselect:not(.form-control) {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0
}

.dropselect:focus {
  outline: none
}

.dropselect::-ms-expand {
  display: none
}

.dropselect option {
  font-weight: normal
}

.aspect-7\/2:before,
.aspect-3\/1:before,
.aspect-5\/2:before,
.aspect-2\/1:before,
.aspect-16\/9:before,
.aspect-8\/5:before,
.aspect-3\/2:before,
.aspect-4\/3:before,
.aspect-1\/1:before,
.aspect-4\/5:before,
.aspect-3\/4:before,
.aspect-100vh:before,
.aspect-sm-7\/2:before,
.aspect-sm-3\/1:before,
.aspect-sm-5\/2:before,
.aspect-sm-2\/1:before,
.aspect-sm-16\/9:before,
.aspect-sm-8\/5:before,
.aspect-sm-3\/2:before,
.aspect-sm-4\/3:before,
.aspect-sm-1\/1:before,
.aspect-sm-4\/5:before,
.aspect-sm-3\/4:before,
.aspect-sm-100vh:before,
.aspect-md-7\/2:before,
.aspect-md-3\/1:before,
.aspect-md-5\/2:before,
.aspect-md-2\/1:before,
.aspect-md-16\/9:before,
.aspect-md-8\/5:before,
.aspect-md-3\/2:before,
.aspect-md-4\/3:before,
.aspect-md-1\/1:before,
.aspect-md-4\/5:before,
.aspect-md-3\/4:before,
.aspect-md-100vh:before,
.aspect-lg-7\/2:before,
.aspect-lg-3\/1:before,
.aspect-lg-5\/2:before,
.aspect-lg-2\/1:before,
.aspect-lg-16\/9:before,
.aspect-lg-8\/5:before,
.aspect-lg-3\/2:before,
.aspect-lg-4\/3:before,
.aspect-lg-1\/1:before,
.aspect-lg-4\/5:before,
.aspect-lg-3\/4:before,
.aspect-lg-100vh:before,
.aspect-xl-7\/2:before,
.aspect-xl-3\/1:before,
.aspect-xl-5\/2:before,
.aspect-xl-2\/1:before,
.aspect-xl-16\/9:before,
.aspect-xl-8\/5:before,
.aspect-xl-3\/2:before,
.aspect-xl-4\/3:before,
.aspect-xl-1\/1:before,
.aspect-xl-4\/5:before,
.aspect-xl-3\/4:before,
.aspect-xl-100vh:before {
  content: "";
  float: left;
  height: 0
}

.aspect-7\/2:after,
.aspect-3\/1:after,
.aspect-5\/2:after,
.aspect-2\/1:after,
.aspect-16\/9:after,
.aspect-8\/5:after,
.aspect-3\/2:after,
.aspect-4\/3:after,
.aspect-1\/1:after,
.aspect-4\/5:after,
.aspect-3\/4:after,
.aspect-100vh:after,
.aspect-sm-7\/2:after,
.aspect-sm-3\/1:after,
.aspect-sm-5\/2:after,
.aspect-sm-2\/1:after,
.aspect-sm-16\/9:after,
.aspect-sm-8\/5:after,
.aspect-sm-3\/2:after,
.aspect-sm-4\/3:after,
.aspect-sm-1\/1:after,
.aspect-sm-4\/5:after,
.aspect-sm-3\/4:after,
.aspect-sm-100vh:after,
.aspect-md-7\/2:after,
.aspect-md-3\/1:after,
.aspect-md-5\/2:after,
.aspect-md-2\/1:after,
.aspect-md-16\/9:after,
.aspect-md-8\/5:after,
.aspect-md-3\/2:after,
.aspect-md-4\/3:after,
.aspect-md-1\/1:after,
.aspect-md-4\/5:after,
.aspect-md-3\/4:after,
.aspect-md-100vh:after,
.aspect-lg-7\/2:after,
.aspect-lg-3\/1:after,
.aspect-lg-5\/2:after,
.aspect-lg-2\/1:after,
.aspect-lg-16\/9:after,
.aspect-lg-8\/5:after,
.aspect-lg-3\/2:after,
.aspect-lg-4\/3:after,
.aspect-lg-1\/1:after,
.aspect-lg-4\/5:after,
.aspect-lg-3\/4:after,
.aspect-lg-100vh:after,
.aspect-xl-7\/2:after,
.aspect-xl-3\/1:after,
.aspect-xl-5\/2:after,
.aspect-xl-2\/1:after,
.aspect-xl-16\/9:after,
.aspect-xl-8\/5:after,
.aspect-xl-3\/2:after,
.aspect-xl-4\/3:after,
.aspect-xl-1\/1:after,
.aspect-xl-4\/5:after,
.aspect-xl-3\/4:after,
.aspect-xl-100vh:after {
  content: "";
  display: table;
  clear: both
}

.aspect-7\/2:before {
  padding-top: 28.5714285714% !important
}

.aspect-3\/1:before {
  padding-top: 33.3333333333% !important
}

.aspect-5\/2:before {
  padding-top: 40% !important
}

.aspect-2\/1:before {
  padding-top: 50% !important
}

.aspect-16\/9:before {
  padding-top: 56.25% !important
}

.aspect-8\/5:before {
  padding-top: 62.5% !important
}

.aspect-3\/2:before {
  padding-top: 66.6666666667% !important
}

.aspect-4\/3:before {
  padding-top: 75% !important
}

.aspect-1\/1:before {
  padding-top: 100% !important
}

.aspect-4\/5:before {
  padding-top: 125% !important
}

.aspect-3\/4:before {
  padding-top: 133.333333333% !important
}

.aspect-100vh:before {
  padding-top: 100vh !important
}

@media (min-width: 480px) {
  .aspect-sm-7\/2:before {
    padding-top: 28.5714285714% !important
  }

  .aspect-sm-3\/1:before {
    padding-top: 33.3333333333% !important
  }

  .aspect-sm-5\/2:before {
    padding-top: 40% !important
  }

  .aspect-sm-2\/1:before {
    padding-top: 50% !important
  }

  .aspect-sm-16\/9:before {
    padding-top: 56.25% !important
  }

  .aspect-sm-8\/5:before {
    padding-top: 62.5% !important
  }

  .aspect-sm-3\/2:before {
    padding-top: 66.6666666667% !important
  }

  .aspect-sm-4\/3:before {
    padding-top: 75% !important
  }

  .aspect-sm-1\/1:before {
    padding-top: 100% !important
  }

  .aspect-sm-4\/5:before {
    padding-top: 125% !important
  }

  .aspect-sm-3\/4:before {
    padding-top: 133.333333333% !important
  }

  .aspect-sm-100vh:before {
    padding-top: 100vh !important
  }
}

@media (min-width: 720px) {
  .aspect-md-7\/2:before {
    padding-top: 28.5714285714% !important
  }

  .aspect-md-3\/1:before {
    padding-top: 33.3333333333% !important
  }

  .aspect-md-5\/2:before {
    padding-top: 40% !important
  }

  .aspect-md-2\/1:before {
    padding-top: 50% !important
  }

  .aspect-md-16\/9:before {
    padding-top: 56.25% !important
  }

  .aspect-md-8\/5:before {
    padding-top: 62.5% !important
  }

  .aspect-md-3\/2:before {
    padding-top: 66.6666666667% !important
  }

  .aspect-md-4\/3:before {
    padding-top: 75% !important
  }

  .aspect-md-1\/1:before {
    padding-top: 100% !important
  }

  .aspect-md-4\/5:before {
    padding-top: 125% !important
  }

  .aspect-md-3\/4:before {
    padding-top: 133.333333333% !important
  }

  .aspect-md-100vh:before {
    padding-top: 100vh !important
  }
}

@media (min-width: 960px) {
  .aspect-lg-7\/2:before {
    padding-top: 28.5714285714% !important
  }

  .aspect-lg-3\/1:before {
    padding-top: 33.3333333333% !important
  }

  .aspect-lg-5\/2:before {
    padding-top: 40% !important
  }

  .aspect-lg-2\/1:before {
    padding-top: 50% !important
  }

  .aspect-lg-16\/9:before {
    padding-top: 56.25% !important
  }

  .aspect-lg-8\/5:before {
    padding-top: 62.5% !important
  }

  .aspect-lg-3\/2:before {
    padding-top: 66.6666666667% !important
  }

  .aspect-lg-4\/3:before {
    padding-top: 75% !important
  }

  .aspect-lg-1\/1:before {
    padding-top: 100% !important
  }

  .aspect-lg-4\/5:before {
    padding-top: 125% !important
  }

  .aspect-lg-3\/4:before {
    padding-top: 133.333333333% !important
  }

  .aspect-lg-100vh:before {
    padding-top: 100vh !important
  }
}

@media (min-width: 1280px) {
  .aspect-xl-7\/2:before {
    padding-top: 28.5714285714% !important
  }

  .aspect-xl-3\/1:before {
    padding-top: 33.3333333333% !important
  }

  .aspect-xl-5\/2:before {
    padding-top: 40% !important
  }

  .aspect-xl-2\/1:before {
    padding-top: 50% !important
  }

  .aspect-xl-16\/9:before {
    padding-top: 56.25% !important
  }

  .aspect-xl-8\/5:before {
    padding-top: 62.5% !important
  }

  .aspect-xl-3\/2:before {
    padding-top: 66.6666666667% !important
  }

  .aspect-xl-4\/3:before {
    padding-top: 75% !important
  }

  .aspect-xl-1\/1:before {
    padding-top: 100% !important
  }

  .aspect-xl-4\/5:before {
    padding-top: 125% !important
  }

  .aspect-xl-3\/4:before {
    padding-top: 133.333333333% !important
  }

  .aspect-xl-100vh:before {
    padding-top: 100vh !important
  }
}

.rounded,
.rounded.scrim:before,
.rounded.inset-border:before {
  border-radius: 4px !important
}

.rounded-top,
.rounded-top.scrim:before,
.rounded-top.inset-border:before {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important
}

.rounded-right,
.rounded-right.scrim:before,
.rounded-right.inset-border:before {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important
}

.rounded-bottom,
.rounded-bottom.scrim:before,
.rounded-bottom.inset-border:before {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important
}

.rounded-left,
.rounded-left.scrim:before,
.rounded-left.inset-border:before {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important
}

.rounded-small,
.rounded-small.scrim:before,
.rounded-small.inset-border:before {
  border-radius: 2px !important
}

.rounded-large,
.rounded-large.scrim:before,
.rounded-large.inset-border:before {
  border-radius: 6px !important
}

.rounded-circle,
.rounded-circle.scrim:before,
.rounded-circle.inset-border:before {
  border-radius: 50% !important
}

.color-white {
  color: var(--primary-bg) !important
}

.color-primary {
  color: var(--primary-color) !important
}

.bg-white {
  background-color: #fff !important
}

.border-white {
  border-color: #fff !important
}

.fill-white {
  fill: #fff !important
}

.color-ghost {
  color: #F7F7F7 !important
}

.bg-ghost {
  background-color: #F7F7F7 !important
}

.border-ghost {
  border-color: #F7F7F7 !important
}

.fill-ghost {
  fill: #F7F7F7 !important
}

.color-ivory {
  color: #F6F6F4 !important
}

.bg-ivory {
  background-color: #F6F6F4 !important
}

.border-ivory {
  border-color: #F6F6F4 !important
}

.fill-ivory {
  fill: #F6F6F4 !important
}

.color-light-wash {
  color: #F7FBFB !important
}

.bg-light-wash {
  background-color: #F7FBFB !important
}

.border-light-wash {
  border-color: #F7FBFB !important
}

.fill-light-wash {
  fill: #F7FBFB !important
}

.color-medium-wash {
  color: #EFF7F8 !important
}

.bg-medium-wash {
  background-color: #EFF7F8 !important
}

.border-medium-wash {
  border-color: #EFF7F8 !important
}

.fill-medium-wash {
  fill: #EFF7F8 !important
}

.color-dark-wash {
  color: #E6F3F3 !important
}

.bg-dark-wash {
  background-color: #E6F3F3 !important
}

.border-dark-wash {
  border-color: #E6F3F3 !important
}

.fill-dark-wash {
  fill: #E6F3F3 !important
}

.color-light-warm-gray {
  color: #EEEDEE !important
}

.bg-light-warm-gray {
  background-color: #EEEDEE !important
}

.border-light-warm-gray {
  border-color: #EEEDEE !important
}

.fill-light-warm-gray {
  fill: #EEEDEE !important
}

.color-dark-warm-gray {
  color: #A3ACB0 !important
}

.bg-dark-warm-gray {
  background-color: #A3ACB0 !important
}

.border-dark-warm-gray {
  border-color: #A3ACB0 !important
}

.fill-dark-warm-gray {
  fill: #A3ACB0 !important
}

.color-cool-gray-0\.5 {
  color: #EDEEF1 !important
}

.bg-cool-gray-0\.5 {
  background-color: #EDEEF1 !important
}

.border-cool-gray-0\.5 {
  border-color: #EDEEF1 !important
}

.fill-cool-gray-0\.5 {
  fill: #EDEEF1 !important
}

.color-cool-gray-1 {
  color: #C5C5D2 !important
}

.bg-cool-gray-1 {
  background-color: #C5C5D2 !important
}

.border-cool-gray-1 {
  border-color: #C5C5D2 !important
}

.fill-cool-gray-1 {
  fill: #C5C5D2 !important
}

.color-cool-gray-1\.5 {
  color: #AEAEBB !important
}

.bg-cool-gray-1\.5 {
  background-color: #AEAEBB !important
}

.border-cool-gray-1\.5 {
  border-color: #AEAEBB !important
}

.fill-cool-gray-1\.5 {
  fill: #AEAEBB !important
}

.color-cool-gray-2 {
  color: #8E8EA0 !important
}

.bg-cool-gray-2 {
  background-color: #8E8EA0 !important
}

.border-cool-gray-2 {
  border-color: #8E8EA0 !important
}

.fill-cool-gray-2 {
  fill: #8E8EA0 !important
}

.color-cool-gray-3 {
  color: #6E6E80 !important
}

.bg-cool-gray-3 {
  background-color: #6E6E80 !important
}

.border-cool-gray-3 {
  border-color: #6E6E80 !important
}

.fill-cool-gray-3 {
  fill: #6E6E80 !important
}

.color-cool-gray-4 {
  color: #404452 !important
}

.bg-cool-gray-4 {
  background-color: #404452 !important
}

.border-cool-gray-4 {
  border-color: #404452 !important
}

.fill-cool-gray-4 {
  fill: #404452 !important
}

.color-light-black {
  color: #191927 !important
}

.bg-light-black {
  background-color: #191927 !important
}

.border-light-black {
  border-color: #191927 !important
}

.fill-light-black {
  fill: #191927 !important
}

.color-medium-black {
  color: #0E0E1A !important
}

.bg-medium-black {
  background-color: #0E0E1A !important
}

.border-medium-black {
  border-color: #0E0E1A !important
}

.fill-medium-black {
  fill: #0E0E1A !important
}

.color-black {
  color: #000 !important
}

.bg-black {
  background-color: #000 !important
}

.border-black {
  border-color: #000 !important
}

.fill-black {
  fill: #000 !important
}

.color-current {
  color: currentColor !important
}

.bg-current {
  background-color: currentColor !important
}

.border-current {
  border-color: currentColor !important
}

.fill-current {
  fill: currentColor !important
}

.color-fg {
  color: #000 !important;
  color: rgba(var(--fg), 1) !important
}

.bg-fg {
  background-color: #000 !important;
  background-color: rgba(var(--fg), 1) !important
}

.border-fg {
  border-color: #000 !important;
  border-color: rgba(var(--fg), 1) !important
}

.fill-fg {
  fill: #000 !important;
  fill: rgba(var(--fg), 1) !important
}

.color-bg {
  color: #fff !important;
  color: rgba(var(--bg), 1) !important
}

.bg-bg {
  background-color: #fff !important;
  background-color: rgba(var(--bg), 1) !important
}

.border-bg {
  border-color: #fff !important;
  border-color: rgba(var(--bg), 1) !important
}

.fill-bg {
  fill: #fff !important;
  fill: rgba(var(--bg), 1) !important
}

.color-fg-1 {
  color: rgba(0, 0, 0, 0.01);
  color: rgba(var(--fg), 0.01)
}

.color-fg-2 {
  color: rgba(0, 0, 0, 0.02);
  color: rgba(var(--fg), 0.02)
}

.color-fg-3 {
  color: rgba(0, 0, 0, 0.03);
  color: rgba(var(--fg), 0.03)
}

.color-fg-4 {
  color: rgba(0, 0, 0, 0.04);
  color: rgba(var(--fg), 0.04)
}

.color-fg-5 {
  color: rgba(0, 0, 0, 0.05);
  color: rgba(var(--fg), 0.05)
}

.color-fg-7\.5 {
  color: rgba(0, 0, 0, 0.075);
  color: rgba(var(--fg), 0.075)
}

.color-fg-10 {
  color: rgba(0, 0, 0, 0.1);
  color: rgba(var(--fg), 0.1)
}

.color-fg-15 {
  color: rgba(0, 0, 0, 0.15);
  color: rgba(var(--fg), 0.15)
}

.color-fg-20 {
  color: rgba(0, 0, 0, 0.2);
  color: rgba(var(--fg), 0.2)
}

.color-fg-25 {
  color: rgba(0, 0, 0, 0.25);
  color: rgba(var(--fg), 0.25)
}

.color-fg-30 {
  color: rgba(0, 0, 0, 0.3);
  color: rgba(var(--fg), 0.3)
}

.color-fg-35 {
  color: rgba(0, 0, 0, 0.35);
  color: rgba(var(--fg), 0.35)
}

.color-fg-40 {
  color: rgba(0, 0, 0, 0.4);
  color: rgba(var(--fg), 0.4)
}

.color-fg-45 {
  color: rgba(0, 0, 0, 0.45);
  color: rgba(var(--fg), 0.45)
}

.color-fg-50 {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5)
}

.color-fg-55 {
  color: rgba(0, 0, 0, 0.55);
  color: rgba(var(--fg), 0.55)
}

.color-fg-60 {
  color: rgba(0, 0, 0, 0.6);
  color: rgba(var(--fg), 0.6)
}

.color-fg-65 {
  color: rgba(0, 0, 0, 0.65);
  color: rgba(var(--fg), 0.65)
}

.color-fg-70 {
  color: rgba(0, 0, 0, 0.7);
  color: rgba(var(--fg), 0.7)
}

.color-fg-75 {
  color: rgba(0, 0, 0, 0.75);
  color: rgba(var(--fg), 0.75)
}

.color-fg-80 {
  color: rgba(0, 0, 0, 0.8);
  color: rgba(var(--fg), 0.8)
}

.color-fg-85 {
  color: rgba(0, 0, 0, 0.85);
  color: rgba(var(--fg), 0.85)
}

.color-fg-90 {
  color: rgba(0, 0, 0, 0.9);
  color: rgba(var(--fg), 0.9)
}

.color-fg-95 {
  color: rgba(0, 0, 0, 0.95);
  color: rgba(var(--fg), 0.95)
}

.color-bg-1 {
  color: rgba(255, 255, 255, 0.01);
  color: rgba(var(--bg), 0.01)
}

.color-bg-2 {
  color: rgba(255, 255, 255, 0.02);
  color: rgba(var(--bg), 0.02)
}

.color-bg-3 {
  color: rgba(255, 255, 255, 0.03);
  color: rgba(var(--bg), 0.03)
}

.color-bg-4 {
  color: rgba(255, 255, 255, 0.04);
  color: rgba(var(--bg), 0.04)
}

.color-bg-5 {
  color: rgba(255, 255, 255, 0.05);
  color: rgba(var(--bg), 0.05)
}

.color-bg-7\.5 {
  color: rgba(255, 255, 255, 0.075);
  color: rgba(var(--bg), 0.075)
}

.color-bg-10 {
  color: rgba(255, 255, 255, 0.1);
  color: rgba(var(--bg), 0.1)
}

.color-bg-15 {
  color: rgba(255, 255, 255, 0.15);
  color: rgba(var(--bg), 0.15)
}

.color-bg-20 {
  color: rgba(255, 255, 255, 0.2);
  color: rgba(var(--bg), 0.2)
}

.color-bg-25 {
  color: rgba(255, 255, 255, 0.25);
  color: rgba(var(--bg), 0.25)
}

.color-bg-30 {
  color: rgba(255, 255, 255, 0.3);
  color: rgba(var(--bg), 0.3)
}

.color-bg-35 {
  color: rgba(255, 255, 255, 0.35);
  color: rgba(var(--bg), 0.35)
}

.color-bg-40 {
  color: rgba(255, 255, 255, 0.4);
  color: rgba(var(--bg), 0.4)
}

.color-bg-45 {
  color: rgba(255, 255, 255, 0.45);
  color: rgba(var(--bg), 0.45)
}

.color-bg-50 {
  color: rgba(255, 255, 255, 0.5);
  color: rgba(var(--bg), 0.5)
}

.color-bg-55 {
  color: rgba(255, 255, 255, 0.55);
  color: rgba(var(--bg), 0.55)
}

.color-bg-60 {
  color: rgba(255, 255, 255, 0.6);
  color: rgba(var(--bg), 0.6)
}

.color-bg-65 {
  color: rgba(255, 255, 255, 0.65);
  color: rgba(var(--bg), 0.65)
}

.color-bg-70 {
  color: rgba(255, 255, 255, 0.7);
  color: rgba(var(--bg), 0.7)
}

.color-bg-75 {
  color: rgba(255, 255, 255, 0.75);
  color: rgba(var(--bg), 0.75)
}

.color-bg-80 {
  color: rgba(255, 255, 255, 0.8);
  color: rgba(var(--bg), 0.8)
}

.color-bg-85 {
  color: rgba(255, 255, 255, 0.85);
  color: rgba(var(--bg), 0.85)
}

.color-bg-90 {
  color: rgba(255, 255, 255, 0.9);
  color: rgba(var(--bg), 0.9)
}

.color-bg-95 {
  color: rgba(255, 255, 255, 0.95);
  color: rgba(var(--bg), 0.95)
}

.bg-fg-1 {
  background-color: rgba(0, 0, 0, 0.01);
  background-color: rgba(var(--fg), 0.01)
}

.bg-fg-2 {
  background-color: rgba(0, 0, 0, 0.02);
  background-color: rgba(var(--fg), 0.02)
}

.bg-fg-3 {
  background-color: rgba(0, 0, 0, 0.03);
  background-color: rgba(var(--fg), 0.03)
}

.bg-fg-4 {
  background-color: rgba(0, 0, 0, 0.04);
  background-color: rgba(var(--fg), 0.04)
}

.bg-fg-5 {
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05)
}

.bg-fg-7\.5 {
  background-color: rgba(0, 0, 0, 0.075);
  background-color: rgba(var(--fg), 0.075)
}

.bg-fg-10 {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(var(--fg), 0.1)
}

.bg-fg-15 {
  background-color: rgba(0, 0, 0, 0.15);
  background-color: rgba(var(--fg), 0.15)
}

.bg-fg-20 {
  background-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(var(--fg), 0.2)
}

.bg-fg-25 {
  background-color: rgba(0, 0, 0, 0.25);
  background-color: rgba(var(--fg), 0.25)
}

.bg-fg-30 {
  background-color: rgba(0, 0, 0, 0.3);
  background-color: rgba(var(--fg), 0.3)
}

.bg-fg-35 {
  background-color: rgba(0, 0, 0, 0.35);
  background-color: rgba(var(--fg), 0.35)
}

.bg-fg-40 {
  background-color: rgba(0, 0, 0, 0.4);
  background-color: rgba(var(--fg), 0.4)
}

.bg-fg-45 {
  background-color: rgba(0, 0, 0, 0.45);
  background-color: rgba(var(--fg), 0.45)
}

.bg-fg-50 {
  background-color: rgba(0, 0, 0, 0.5);
  background-color: rgba(var(--fg), 0.5)
}

.bg-fg-55 {
  background-color: rgba(0, 0, 0, 0.55);
  background-color: rgba(var(--fg), 0.55)
}

.bg-fg-60 {
  background-color: rgba(0, 0, 0, 0.6);
  background-color: rgba(var(--fg), 0.6)
}

.bg-fg-65 {
  background-color: rgba(0, 0, 0, 0.65);
  background-color: rgba(var(--fg), 0.65)
}

.bg-fg-70 {
  background-color: rgba(0, 0, 0, 0.7);
  background-color: rgba(var(--fg), 0.7)
}

.bg-fg-75 {
  background-color: rgba(0, 0, 0, 0.75);
  background-color: rgba(var(--fg), 0.75)
}

.bg-fg-80 {
  background-color: rgba(0, 0, 0, 0.8);
  background-color: rgba(var(--fg), 0.8)
}

.bg-fg-85 {
  background-color: rgba(0, 0, 0, 0.85);
  background-color: rgba(var(--fg), 0.85)
}

.bg-fg-90 {
  background-color: rgba(0, 0, 0, 0.9);
  background-color: rgba(var(--fg), 0.9)
}

.bg-fg-95 {
  background-color: rgba(0, 0, 0, 0.95);
  background-color: rgba(var(--fg), 0.95)
}

.bg-bg-1 {
  background-color: rgba(255, 255, 255, 0.01);
  background-color: rgba(var(--bg), 0.01)
}

.bg-bg-2 {
  background-color: rgba(255, 255, 255, 0.02);
  background-color: rgba(var(--bg), 0.02)
}

.bg-bg-3 {
  background-color: rgba(255, 255, 255, 0.03);
  background-color: rgba(var(--bg), 0.03)
}

.bg-bg-4 {
  background-color: rgba(255, 255, 255, 0.04);
  background-color: rgba(var(--bg), 0.04)
}

.bg-bg-5 {
  background-color: rgba(255, 255, 255, 0.05);
  background-color: rgba(var(--bg), 0.05)
}

.bg-bg-7\.5 {
  background-color: rgba(255, 255, 255, 0.075);
  background-color: rgba(var(--bg), 0.075)
}

.bg-bg-10 {
  background-color: rgba(255, 255, 255, 0.1);
  background-color: rgba(var(--bg), 0.1)
}

.bg-bg-15 {
  background-color: rgba(255, 255, 255, 0.15);
  background-color: rgba(var(--bg), 0.15)
}

.bg-bg-20 {
  background-color: rgba(255, 255, 255, 0.2);
  background-color: rgba(var(--bg), 0.2)
}

.bg-bg-25 {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: rgba(var(--bg), 0.25)
}

.bg-bg-30 {
  background-color: rgba(255, 255, 255, 0.3);
  background-color: rgba(var(--bg), 0.3)
}

.bg-bg-35 {
  background-color: rgba(255, 255, 255, 0.35);
  background-color: rgba(var(--bg), 0.35)
}

.bg-bg-40 {
  background-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(var(--bg), 0.4)
}

.bg-bg-45 {
  background-color: rgba(255, 255, 255, 0.45);
  background-color: rgba(var(--bg), 0.45)
}

.bg-bg-50 {
  background-color: rgba(255, 255, 255, 0.5);
  background-color: rgba(var(--bg), 0.5)
}

.bg-bg-55 {
  background-color: rgba(255, 255, 255, 0.55);
  background-color: rgba(var(--bg), 0.55)
}

.bg-bg-60 {
  background-color: rgba(255, 255, 255, 0.6);
  background-color: rgba(var(--bg), 0.6)
}

.bg-bg-65 {
  background-color: rgba(255, 255, 255, 0.65);
  background-color: rgba(var(--bg), 0.65)
}

.bg-bg-70 {
  background-color: rgba(255, 255, 255, 0.7);
  background-color: rgba(var(--bg), 0.7)
}

.bg-bg-75 {
  background-color: rgba(255, 255, 255, 0.75);
  background-color: rgba(var(--bg), 0.75)
}

.bg-bg-80 {
  background-color: rgba(255, 255, 255, 0.8);
  background-color: rgba(var(--bg), 0.8)
}

.bg-bg-85 {
  background-color: rgba(255, 255, 255, 0.85);
  background-color: rgba(var(--bg), 0.85)
}

.bg-bg-90 {
  background-color: rgba(255, 255, 255, 0.9);
  background-color: rgba(var(--bg), 0.9)
}

.bg-bg-95 {
  background-color: rgba(255, 255, 255, 0.95);
  background-color: rgba(var(--bg), 0.95)
}

.border-fg-1 {
  border-color: rgba(0, 0, 0, 0.01);
  border-color: rgba(var(--fg), 0.01)
}

.border-fg-2 {
  border-color: rgba(0, 0, 0, 0.02);
  border-color: rgba(var(--fg), 0.02)
}

.border-fg-3 {
  border-color: rgba(0, 0, 0, 0.03);
  border-color: rgba(var(--fg), 0.03)
}

.border-fg-4 {
  border-color: rgba(0, 0, 0, 0.04);
  border-color: rgba(var(--fg), 0.04)
}

.border-fg-5 {
  border-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(var(--fg), 0.05)
}

.border-fg-7\.5 {
  border-color: rgba(0, 0, 0, 0.075);
  border-color: rgba(var(--fg), 0.075)
}

.border-fg-10 {
  border-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(var(--fg), 0.1)
}

.border-fg-15 {
  border-color: rgba(0, 0, 0, 0.15);
  border-color: rgba(var(--fg), 0.15)
}

.border-fg-20 {
  border-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(var(--fg), 0.2)
}

.border-fg-25 {
  border-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(var(--fg), 0.25)
}

.border-fg-30 {
  border-color: rgba(0, 0, 0, 0.3);
  border-color: rgba(var(--fg), 0.3)
}

.border-fg-35 {
  border-color: rgba(0, 0, 0, 0.35);
  border-color: rgba(var(--fg), 0.35)
}

.border-fg-40 {
  border-color: rgba(0, 0, 0, 0.4);
  border-color: rgba(var(--fg), 0.4)
}

.border-fg-45 {
  border-color: rgba(0, 0, 0, 0.45);
  border-color: rgba(var(--fg), 0.45)
}

.border-fg-50 {
  border-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(var(--fg), 0.5)
}

.border-fg-55 {
  border-color: rgba(0, 0, 0, 0.55);
  border-color: rgba(var(--fg), 0.55)
}

.border-fg-60 {
  border-color: rgba(0, 0, 0, 0.6);
  border-color: rgba(var(--fg), 0.6)
}

.border-fg-65 {
  border-color: rgba(0, 0, 0, 0.65);
  border-color: rgba(var(--fg), 0.65)
}

.border-fg-70 {
  border-color: rgba(0, 0, 0, 0.7);
  border-color: rgba(var(--fg), 0.7)
}

.border-fg-75 {
  border-color: rgba(0, 0, 0, 0.75);
  border-color: rgba(var(--fg), 0.75)
}

.border-fg-80 {
  border-color: rgba(0, 0, 0, 0.8);
  border-color: rgba(var(--fg), 0.8)
}

.border-fg-85 {
  border-color: rgba(0, 0, 0, 0.85);
  border-color: rgba(var(--fg), 0.85)
}

.border-fg-90 {
  border-color: rgba(0, 0, 0, 0.9);
  border-color: rgba(var(--fg), 0.9)
}

.border-fg-95 {
  border-color: rgba(0, 0, 0, 0.95);
  border-color: rgba(var(--fg), 0.95)
}

.border-bg-1 {
  border-color: rgba(255, 255, 255, 0.01);
  border-color: rgba(var(--bg), 0.01)
}

.border-bg-2 {
  border-color: rgba(255, 255, 255, 0.02);
  border-color: rgba(var(--bg), 0.02)
}

.border-bg-3 {
  border-color: rgba(255, 255, 255, 0.03);
  border-color: rgba(var(--bg), 0.03)
}

.border-bg-4 {
  border-color: rgba(255, 255, 255, 0.04);
  border-color: rgba(var(--bg), 0.04)
}

.border-bg-5 {
  border-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(var(--bg), 0.05)
}

.border-bg-7\.5 {
  border-color: rgba(255, 255, 255, 0.075);
  border-color: rgba(var(--bg), 0.075)
}

.border-bg-10 {
  border-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(var(--bg), 0.1)
}

.border-bg-15 {
  border-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(var(--bg), 0.15)
}

.border-bg-20 {
  border-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(var(--bg), 0.2)
}

.border-bg-25 {
  border-color: rgba(255, 255, 255, 0.25);
  border-color: rgba(var(--bg), 0.25)
}

.border-bg-30 {
  border-color: rgba(255, 255, 255, 0.3);
  border-color: rgba(var(--bg), 0.3)
}

.border-bg-35 {
  border-color: rgba(255, 255, 255, 0.35);
  border-color: rgba(var(--bg), 0.35)
}

.border-bg-40 {
  border-color: rgba(255, 255, 255, 0.4);
  border-color: rgba(var(--bg), 0.4)
}

.border-bg-45 {
  border-color: rgba(255, 255, 255, 0.45);
  border-color: rgba(var(--bg), 0.45)
}

.border-bg-50 {
  border-color: rgba(255, 255, 255, 0.5);
  border-color: rgba(var(--bg), 0.5)
}

.border-bg-55 {
  border-color: rgba(255, 255, 255, 0.55);
  border-color: rgba(var(--bg), 0.55)
}

.border-bg-60 {
  border-color: rgba(255, 255, 255, 0.6);
  border-color: rgba(var(--bg), 0.6)
}

.border-bg-65 {
  border-color: rgba(255, 255, 255, 0.65);
  border-color: rgba(var(--bg), 0.65)
}

.border-bg-70 {
  border-color: rgba(255, 255, 255, 0.7);
  border-color: rgba(var(--bg), 0.7)
}

.border-bg-75 {
  border-color: rgba(255, 255, 255, 0.75);
  border-color: rgba(var(--bg), 0.75)
}

.border-bg-80 {
  border-color: rgba(255, 255, 255, 0.8);
  border-color: rgba(var(--bg), 0.8)
}

.border-bg-85 {
  border-color: rgba(255, 255, 255, 0.85);
  border-color: rgba(var(--bg), 0.85)
}

.border-bg-90 {
  border-color: rgba(255, 255, 255, 0.9);
  border-color: rgba(var(--bg), 0.9)
}

.border-bg-95 {
  border-color: rgba(255, 255, 255, 0.95);
  border-color: rgba(var(--bg), 0.95)
}

.fill-fg-1 {
  fill: rgba(0, 0, 0, 0.01);
  fill: rgba(var(--fg), 0.01)
}

.fill-fg-2 {
  fill: rgba(0, 0, 0, 0.02);
  fill: rgba(var(--fg), 0.02)
}

.fill-fg-3 {
  fill: rgba(0, 0, 0, 0.03);
  fill: rgba(var(--fg), 0.03)
}

.fill-fg-4 {
  fill: rgba(0, 0, 0, 0.04);
  fill: rgba(var(--fg), 0.04)
}

.fill-fg-5 {
  fill: rgba(0, 0, 0, 0.05);
  fill: rgba(var(--fg), 0.05)
}

.fill-fg-7\.5 {
  fill: rgba(0, 0, 0, 0.075);
  fill: rgba(var(--fg), 0.075)
}

.fill-fg-10 {
  fill: rgba(0, 0, 0, 0.1);
  fill: rgba(var(--fg), 0.1)
}

.fill-fg-15 {
  fill: rgba(0, 0, 0, 0.15);
  fill: rgba(var(--fg), 0.15)
}

.fill-fg-20 {
  fill: rgba(0, 0, 0, 0.2);
  fill: rgba(var(--fg), 0.2)
}

.fill-fg-25 {
  fill: rgba(0, 0, 0, 0.25);
  fill: rgba(var(--fg), 0.25)
}

.fill-fg-30 {
  fill: rgba(0, 0, 0, 0.3);
  fill: rgba(var(--fg), 0.3)
}

.fill-fg-35 {
  fill: rgba(0, 0, 0, 0.35);
  fill: rgba(var(--fg), 0.35)
}

.fill-fg-40 {
  fill: rgba(0, 0, 0, 0.4);
  fill: rgba(var(--fg), 0.4)
}

.fill-fg-45 {
  fill: rgba(0, 0, 0, 0.45);
  fill: rgba(var(--fg), 0.45)
}

.fill-fg-50 {
  fill: rgba(0, 0, 0, 0.5);
  fill: rgba(var(--fg), 0.5)
}

.fill-fg-55 {
  fill: rgba(0, 0, 0, 0.55);
  fill: rgba(var(--fg), 0.55)
}

.fill-fg-60 {
  fill: rgba(0, 0, 0, 0.6);
  fill: rgba(var(--fg), 0.6)
}

.fill-fg-65 {
  fill: rgba(0, 0, 0, 0.65);
  fill: rgba(var(--fg), 0.65)
}

.fill-fg-70 {
  fill: rgba(0, 0, 0, 0.7);
  fill: rgba(var(--fg), 0.7)
}

.fill-fg-75 {
  fill: rgba(0, 0, 0, 0.75);
  fill: rgba(var(--fg), 0.75)
}

.fill-fg-80 {
  fill: rgba(0, 0, 0, 0.8);
  fill: rgba(var(--fg), 0.8)
}

.fill-fg-85 {
  fill: rgba(0, 0, 0, 0.85);
  fill: rgba(var(--fg), 0.85)
}

.fill-fg-90 {
  fill: rgba(0, 0, 0, 0.9);
  fill: rgba(var(--fg), 0.9)
}

.fill-fg-95 {
  fill: rgba(0, 0, 0, 0.95);
  fill: rgba(var(--fg), 0.95)
}

.fill-bg-1 {
  fill: rgba(255, 255, 255, 0.01);
  fill: rgba(var(--bg), 0.01)
}

.fill-bg-2 {
  fill: rgba(255, 255, 255, 0.02);
  fill: rgba(var(--bg), 0.02)
}

.fill-bg-3 {
  fill: rgba(255, 255, 255, 0.03);
  fill: rgba(var(--bg), 0.03)
}

.fill-bg-4 {
  fill: rgba(255, 255, 255, 0.04);
  fill: rgba(var(--bg), 0.04)
}

.fill-bg-5 {
  fill: rgba(255, 255, 255, 0.05);
  fill: rgba(var(--bg), 0.05)
}

.fill-bg-7\.5 {
  fill: rgba(255, 255, 255, 0.075);
  fill: rgba(var(--bg), 0.075)
}

.fill-bg-10 {
  fill: rgba(255, 255, 255, 0.1);
  fill: rgba(var(--bg), 0.1)
}

.fill-bg-15 {
  fill: rgba(255, 255, 255, 0.15);
  fill: rgba(var(--bg), 0.15)
}

.fill-bg-20 {
  fill: rgba(255, 255, 255, 0.2);
  fill: rgba(var(--bg), 0.2)
}

.fill-bg-25 {
  fill: rgba(255, 255, 255, 0.25);
  fill: rgba(var(--bg), 0.25)
}

.fill-bg-30 {
  fill: rgba(255, 255, 255, 0.3);
  fill: rgba(var(--bg), 0.3)
}

.fill-bg-35 {
  fill: rgba(255, 255, 255, 0.35);
  fill: rgba(var(--bg), 0.35)
}

.fill-bg-40 {
  fill: rgba(255, 255, 255, 0.4);
  fill: rgba(var(--bg), 0.4)
}

.fill-bg-45 {
  fill: rgba(255, 255, 255, 0.45);
  fill: rgba(var(--bg), 0.45)
}

.fill-bg-50 {
  fill: rgba(255, 255, 255, 0.5);
  fill: rgba(var(--bg), 0.5)
}

.fill-bg-55 {
  fill: rgba(255, 255, 255, 0.55);
  fill: rgba(var(--bg), 0.55)
}

.fill-bg-60 {
  fill: rgba(255, 255, 255, 0.6);
  fill: rgba(var(--bg), 0.6)
}

.fill-bg-65 {
  fill: rgba(255, 255, 255, 0.65);
  fill: rgba(var(--bg), 0.65)
}

.fill-bg-70 {
  fill: rgba(255, 255, 255, 0.7);
  fill: rgba(var(--bg), 0.7)
}

.fill-bg-75 {
  fill: rgba(255, 255, 255, 0.75);
  fill: rgba(var(--bg), 0.75)
}

.fill-bg-80 {
  fill: rgba(255, 255, 255, 0.8);
  fill: rgba(var(--bg), 0.8)
}

.fill-bg-85 {
  fill: rgba(255, 255, 255, 0.85);
  fill: rgba(var(--bg), 0.85)
}

.fill-bg-90 {
  fill: rgba(255, 255, 255, 0.9);
  fill: rgba(var(--bg), 0.9)
}

.fill-bg-95 {
  fill: rgba(255, 255, 255, 0.95);
  fill: rgba(var(--bg), 0.95)
}

.bg-transparent {
  background-color: transparent !important
}

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: flex !important
}

.d-inline-flex {
  display: inline-flex !important
}

@media (min-width: 480px) {
  .d-sm-none {
    display: none !important
  }

  .d-sm-inline {
    display: inline !important
  }

  .d-sm-inline-block {
    display: inline-block !important
  }

  .d-sm-block {
    display: block !important
  }

  .d-sm-table {
    display: table !important
  }

  .d-sm-table-row {
    display: table-row !important
  }

  .d-sm-table-cell {
    display: table-cell !important
  }

  .d-sm-flex {
    display: flex !important
  }

  .d-sm-inline-flex {
    display: inline-flex !important
  }
}

@media (min-width: 720px) {
  .d-md-none {
    display: none !important
  }

  .d-md-inline {
    display: inline !important
  }

  .d-md-inline-block {
    display: inline-block !important
  }

  .d-md-block {
    display: block !important
  }

  .d-md-table {
    display: table !important
  }

  .d-md-table-row {
    display: table-row !important
  }

  .d-md-table-cell {
    display: table-cell !important
  }

  .d-md-flex {
    display: flex !important
  }

  .d-md-inline-flex {
    display: inline-flex !important
  }
}

@media (min-width: 960px) {
  .d-lg-none {
    display: none !important
  }

  .d-lg-inline {
    display: inline !important
  }

  .d-lg-inline-block {
    display: inline-block !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-table {
    display: table !important
  }

  .d-lg-table-row {
    display: table-row !important
  }

  .d-lg-table-cell {
    display: table-cell !important
  }

  .d-lg-flex {
    display: flex !important
  }

  .d-lg-inline-flex {
    display: inline-flex !important
  }
}

@media (min-width: 1280px) {
  .d-xl-none {
    display: none !important
  }

  .d-xl-inline {
    display: inline !important
  }

  .d-xl-inline-block {
    display: inline-block !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-table {
    display: table !important
  }

  .d-xl-table-row {
    display: table-row !important
  }

  .d-xl-table-cell {
    display: table-cell !important
  }

  .d-xl-flex {
    display: flex !important
  }

  .d-xl-inline-flex {
    display: inline-flex !important
  }
}

@media (min-width: 720px) {
  .d-desktop-none {
    display: none !important
  }

  .d-desktop-inline {
    display: inline !important
  }

  .d-desktop-inline-block {
    display: inline-block !important
  }

  .d-desktop-block {
    display: block !important
  }

  .d-desktop-table {
    display: table !important
  }

  .d-desktop-table-row {
    display: table-row !important
  }

  .d-desktop-table-cell {
    display: table-cell !important
  }

  .d-desktop-flex {
    display: flex !important
  }

  .d-desktop-inline-flex {
    display: inline-flex !important
  }
}

@media print {
  .d-print-none {
    display: none !important
  }

  .d-print-inline {
    display: inline !important
  }

  .d-print-inline-block {
    display: inline-block !important
  }

  .d-print-block {
    display: block !important
  }

  .d-print-table {
    display: table !important
  }

  .d-print-table-row {
    display: table-row !important
  }

  .d-print-table-cell {
    display: table-cell !important
  }

  .d-print-flex {
    display: flex !important
  }

  .d-print-inline-flex {
    display: inline-flex !important
  }
}

.flex-row {
  flex-direction: row !important
}

.flex-column {
  flex-direction: column !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  flex-direction: column-reverse !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-nowrap {
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.flex-fill {
  flex: 1 1 auto !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow-1 {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  flex-shrink: 1 !important
}

.justify-content-start {
  justify-content: flex-start !important
}

.justify-content-end {
  justify-content: flex-end !important
}

.justify-content-center {
  justify-content: center !important
}

.justify-content-between {
  justify-content: space-between !important
}

.justify-content-around {
  justify-content: space-around !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-end {
  align-items: flex-end !important
}

.align-items-center {
  align-items: center !important
}

.align-items-baseline {
  align-items: baseline !important
}

.align-items-stretch {
  align-items: stretch !important
}

.align-content-start {
  align-content: flex-start !important
}

.align-content-end {
  align-content: flex-end !important
}

.align-content-center {
  align-content: center !important
}

.align-content-between {
  align-content: space-between !important
}

.align-content-around {
  align-content: space-around !important
}

.align-content-stretch {
  align-content: stretch !important
}

.align-self-auto {
  align-self: auto !important
}

.align-self-start {
  align-self: flex-start !important
}

.align-self-end {
  align-self: flex-end !important
}

.align-self-center {
  align-self: center !important
}

.align-self-baseline {
  align-self: baseline !important
}

.align-self-stretch {
  align-self: stretch !important
}

.object-fit-contain {
  object-fit: contain;
}

@media (min-width: 480px) {
  .flex-sm-row {
    flex-direction: row !important
  }

  .flex-sm-column {
    flex-direction: column !important
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-sm-fill {
    flex: 1 1 auto !important
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-sm-start {
    justify-content: flex-start !important
  }

  .justify-content-sm-end {
    justify-content: flex-end !important
  }

  .justify-content-sm-center {
    justify-content: center !important
  }

  .justify-content-sm-between {
    justify-content: space-between !important
  }

  .justify-content-sm-around {
    justify-content: space-around !important
  }

  .align-items-sm-start {
    align-items: flex-start !important
  }

  .align-items-sm-end {
    align-items: flex-end !important
  }

  .align-items-sm-center {
    align-items: center !important
  }

  .align-items-sm-baseline {
    align-items: baseline !important
  }

  .align-items-sm-stretch {
    align-items: stretch !important
  }

  .align-content-sm-start {
    align-content: flex-start !important
  }

  .align-content-sm-end {
    align-content: flex-end !important
  }

  .align-content-sm-center {
    align-content: center !important
  }

  .align-content-sm-between {
    align-content: space-between !important
  }

  .align-content-sm-around {
    align-content: space-around !important
  }

  .align-content-sm-stretch {
    align-content: stretch !important
  }

  .align-self-sm-auto {
    align-self: auto !important
  }

  .align-self-sm-start {
    align-self: flex-start !important
  }

  .align-self-sm-end {
    align-self: flex-end !important
  }

  .align-self-sm-center {
    align-self: center !important
  }

  .align-self-sm-baseline {
    align-self: baseline !important
  }

  .align-self-sm-stretch {
    align-self: stretch !important
  }
}

@media (min-width: 720px) {
  .flex-md-row {
    flex-direction: row !important
  }

  .flex-md-column {
    flex-direction: column !important
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-md-wrap {
    flex-wrap: wrap !important
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-md-fill {
    flex: 1 1 auto !important
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-md-start {
    justify-content: flex-start !important
  }

  .justify-content-md-end {
    justify-content: flex-end !important
  }

  .justify-content-md-center {
    justify-content: center !important
  }

  .justify-content-md-between {
    justify-content: space-between !important
  }

  .justify-content-md-around {
    justify-content: space-around !important
  }

  .align-items-md-start {
    align-items: flex-start !important
  }

  .align-items-md-end {
    align-items: flex-end !important
  }

  .align-items-md-center {
    align-items: center !important
  }

  .align-items-md-baseline {
    align-items: baseline !important
  }

  .align-items-md-stretch {
    align-items: stretch !important
  }

  .align-content-md-start {
    align-content: flex-start !important
  }

  .align-content-md-end {
    align-content: flex-end !important
  }

  .align-content-md-center {
    align-content: center !important
  }

  .align-content-md-between {
    align-content: space-between !important
  }

  .align-content-md-around {
    align-content: space-around !important
  }

  .align-content-md-stretch {
    align-content: stretch !important
  }

  .align-self-md-auto {
    align-self: auto !important
  }

  .align-self-md-start {
    align-self: flex-start !important
  }

  .align-self-md-end {
    align-self: flex-end !important
  }

  .align-self-md-center {
    align-self: center !important
  }

  .align-self-md-baseline {
    align-self: baseline !important
  }

  .align-self-md-stretch {
    align-self: stretch !important
  }
}

@media (min-width: 960px) {
  .flex-lg-row {
    flex-direction: row !important
  }

  .flex-lg-column {
    flex-direction: column !important
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-lg-fill {
    flex: 1 1 auto !important
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-lg-start {
    justify-content: flex-start !important
  }

  .justify-content-lg-end {
    justify-content: flex-end !important
  }

  .justify-content-lg-center {
    justify-content: center !important
  }

  .justify-content-lg-between {
    justify-content: space-between !important
  }

  .justify-content-lg-around {
    justify-content: space-around !important
  }

  .align-items-lg-start {
    align-items: flex-start !important
  }

  .align-items-lg-end {
    align-items: flex-end !important
  }

  .align-items-lg-center {
    align-items: center !important
  }

  .align-items-lg-baseline {
    align-items: baseline !important
  }

  .align-items-lg-stretch {
    align-items: stretch !important
  }

  .align-content-lg-start {
    align-content: flex-start !important
  }

  .align-content-lg-end {
    align-content: flex-end !important
  }

  .align-content-lg-center {
    align-content: center !important
  }

  .align-content-lg-between {
    align-content: space-between !important
  }

  .align-content-lg-around {
    align-content: space-around !important
  }

  .align-content-lg-stretch {
    align-content: stretch !important
  }

  .align-self-lg-auto {
    align-self: auto !important
  }

  .align-self-lg-start {
    align-self: flex-start !important
  }

  .align-self-lg-end {
    align-self: flex-end !important
  }

  .align-self-lg-center {
    align-self: center !important
  }

  .align-self-lg-baseline {
    align-self: baseline !important
  }

  .align-self-lg-stretch {
    align-self: stretch !important
  }
}

@media (min-width: 1280px) {
  .flex-xl-row {
    flex-direction: row !important
  }

  .flex-xl-column {
    flex-direction: column !important
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .flex-xl-fill {
    flex: 1 1 auto !important
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important
  }

  .justify-content-xl-start {
    justify-content: flex-start !important
  }

  .justify-content-xl-end {
    justify-content: flex-end !important
  }

  .justify-content-xl-center {
    justify-content: center !important
  }

  .justify-content-xl-between {
    justify-content: space-between !important
  }

  .justify-content-xl-around {
    justify-content: space-around !important
  }

  .align-items-xl-start {
    align-items: flex-start !important
  }

  .align-items-xl-end {
    align-items: flex-end !important
  }

  .align-items-xl-center {
    align-items: center !important
  }

  .align-items-xl-baseline {
    align-items: baseline !important
  }

  .align-items-xl-stretch {
    align-items: stretch !important
  }

  .align-content-xl-start {
    align-content: flex-start !important
  }

  .align-content-xl-end {
    align-content: flex-end !important
  }

  .align-content-xl-center {
    align-content: center !important
  }

  .align-content-xl-between {
    align-content: space-between !important
  }

  .align-content-xl-around {
    align-content: space-around !important
  }

  .align-content-xl-stretch {
    align-content: stretch !important
  }

  .align-self-xl-auto {
    align-self: auto !important
  }

  .align-self-xl-start {
    align-self: flex-start !important
  }

  .align-self-xl-end {
    align-self: flex-end !important
  }

  .align-self-xl-center {
    align-self: center !important
  }

  .align-self-xl-baseline {
    align-self: baseline !important
  }

  .align-self-xl-stretch {
    align-self: stretch !important
  }
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-x-auto {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: sticky !important
}

@media (min-width: 480px) {
  .position-sm-static {
    position: static !important
  }

  .position-sm-relative {
    position: relative !important
  }

  .position-sm-absolute {
    position: absolute !important
  }

  .position-sm-fixed {
    position: fixed !important
  }

  .position-sm-sticky {
    position: sticky !important
  }
}

@media (min-width: 720px) {
  .position-md-static {
    position: static !important
  }

  .position-md-relative {
    position: relative !important
  }

  .position-md-absolute {
    position: absolute !important
  }

  .position-md-fixed {
    position: fixed !important
  }

  .position-md-sticky {
    position: sticky !important
  }
}

@media (min-width: 960px) {
  .position-lg-static {
    position: static !important
  }

  .position-lg-relative {
    position: relative !important
  }

  .position-lg-absolute {
    position: absolute !important
  }

  .position-lg-fixed {
    position: fixed !important
  }

  .position-lg-sticky {
    position: sticky !important
  }
}

@media (min-width: 1280px) {
  .position-xl-static {
    position: static !important
  }

  .position-xl-relative {
    position: relative !important
  }

  .position-xl-absolute {
    position: absolute !important
  }

  .position-xl-fixed {
    position: fixed !important
  }

  .position-xl-sticky {
    position: sticky !important
  }
}

@supports (position: sticky) {

  .sticky,
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1
  }
}

.trbl-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0
}

.trbl-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

.w-1\/12 {
  width: 8.3333333333% !important
}

.w-2\/12 {
  width: 16.6666666667% !important
}

.w-25 {
  width: 25% !important
}

.w-1\/3 {
  width: 33.3333333333% !important
}

.w-5\/12 {
  width: 41.6666666667% !important
}

.w-50 {
  width: 50% !important
}

.w-7\/12 {
  width: 58.3333333333% !important
}

.w-2\/3 {
  width: 66.6666666667% !important
}

.w-75 {
  width: 75% !important
}

.w-10\/12 {
  width: 83.3333333333% !important
}

.w-11\/12 {
  width: 91.6666666667% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.h-1\/12 {
  height: 8.3333333333% !important
}

.h-2\/12 {
  height: 16.6666666667% !important
}

.h-25 {
  height: 25% !important
}

.h-1\/3 {
  height: 33.3333333333% !important
}

.h-5\/12 {
  height: 41.6666666667% !important
}

.h-50 {
  height: 50% !important
}

.h-7\/12 {
  height: 58.3333333333% !important
}

.h-2\/3 {
  height: 66.6666666667% !important
}

.h-75 {
  height: 75% !important
}

.h-10\/12 {
  height: 83.3333333333% !important
}

.h-11\/12 {
  height: 91.6666666667% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

@media (min-width: 480px) {
  .w-sm-1\/12 {
    width: 8.3333333333% !important
  }

  .w-sm-2\/12 {
    width: 16.6666666667% !important
  }

  .w-sm-25 {
    width: 25% !important
  }

  .w-sm-1\/3 {
    width: 33.3333333333% !important
  }

  .w-sm-5\/12 {
    width: 41.6666666667% !important
  }

  .w-sm-50 {
    width: 50% !important
  }

  .w-sm-7\/12 {
    width: 58.3333333333% !important
  }

  .w-sm-2\/3 {
    width: 66.6666666667% !important
  }

  .w-sm-75 {
    width: 75% !important
  }

  .w-sm-10\/12 {
    width: 83.3333333333% !important
  }

  .w-sm-11\/12 {
    width: 91.6666666667% !important
  }

  .w-sm-100 {
    width: 100% !important
  }

  .w-sm-auto {
    width: auto !important
  }

  .h-sm-1\/12 {
    height: 8.3333333333% !important
  }

  .h-sm-2\/12 {
    height: 16.6666666667% !important
  }

  .h-sm-25 {
    height: 25% !important
  }

  .h-sm-1\/3 {
    height: 33.3333333333% !important
  }

  .h-sm-5\/12 {
    height: 41.6666666667% !important
  }

  .h-sm-50 {
    height: 50% !important
  }

  .h-sm-7\/12 {
    height: 58.3333333333% !important
  }

  .h-sm-2\/3 {
    height: 66.6666666667% !important
  }

  .h-sm-75 {
    height: 75% !important
  }

  .h-sm-10\/12 {
    height: 83.3333333333% !important
  }

  .h-sm-11\/12 {
    height: 91.6666666667% !important
  }

  .h-sm-100 {
    height: 100% !important
  }

  .h-sm-auto {
    height: auto !important
  }
}

@media (min-width: 720px) {
  .w-md-1\/12 {
    width: 8.3333333333% !important
  }

  .w-md-2\/12 {
    width: 16.6666666667% !important
  }

  .w-md-25 {
    width: 25% !important
  }

  .w-md-1\/3 {
    width: 33.3333333333% !important
  }

  .w-md-5\/12 {
    width: 41.6666666667% !important
  }

  .w-md-50 {
    width: 50% !important
  }

  .w-md-7\/12 {
    width: 58.3333333333% !important
  }

  .w-md-2\/3 {
    width: 66.6666666667% !important
  }

  .w-md-75 {
    width: 75% !important
  }

  .w-md-10\/12 {
    width: 83.3333333333% !important
  }

  .w-md-11\/12 {
    width: 91.6666666667% !important
  }

  .w-md-100 {
    width: 100% !important
  }

  .w-md-auto {
    width: auto !important
  }

  .h-md-1\/12 {
    height: 8.3333333333% !important
  }

  .h-md-2\/12 {
    height: 16.6666666667% !important
  }

  .h-md-25 {
    height: 25% !important
  }

  .h-md-1\/3 {
    height: 33.3333333333% !important
  }

  .h-md-5\/12 {
    height: 41.6666666667% !important
  }

  .h-md-50 {
    height: 50% !important
  }

  .h-md-7\/12 {
    height: 58.3333333333% !important
  }

  .h-md-2\/3 {
    height: 66.6666666667% !important
  }

  .h-md-75 {
    height: 75% !important
  }

  .h-md-10\/12 {
    height: 83.3333333333% !important
  }

  .h-md-11\/12 {
    height: 91.6666666667% !important
  }

  .h-md-100 {
    height: 100% !important
  }

  .h-md-auto {
    height: auto !important
  }
}

@media (min-width: 960px) {
  .w-lg-1\/12 {
    width: 8.3333333333% !important
  }

  .w-lg-2\/12 {
    width: 16.6666666667% !important
  }

  .w-lg-25 {
    width: 25% !important
  }

  .w-lg-1\/3 {
    width: 33.3333333333% !important
  }

  .w-lg-5\/12 {
    width: 41.6666666667% !important
  }

  .w-lg-50 {
    width: 50% !important
  }

  .w-lg-7\/12 {
    width: 58.3333333333% !important
  }

  .w-lg-2\/3 {
    width: 66.6666666667% !important
  }

  .w-lg-75 {
    width: 75% !important
  }

  .w-lg-10\/12 {
    width: 83.3333333333% !important
  }

  .w-lg-11\/12 {
    width: 91.6666666667% !important
  }

  .w-lg-100 {
    width: 100% !important
  }

  .w-lg-auto {
    width: auto !important
  }

  .h-lg-1\/12 {
    height: 8.3333333333% !important
  }

  .h-lg-2\/12 {
    height: 16.6666666667% !important
  }

  .h-lg-25 {
    height: 25% !important
  }

  .h-lg-1\/3 {
    height: 33.3333333333% !important
  }

  .h-lg-5\/12 {
    height: 41.6666666667% !important
  }

  .h-lg-50 {
    height: 50% !important
  }

  .h-lg-7\/12 {
    height: 58.3333333333% !important
  }

  .h-lg-2\/3 {
    height: 66.6666666667% !important
  }

  .h-lg-75 {
    height: 75% !important
  }

  .h-lg-10\/12 {
    height: 83.3333333333% !important
  }

  .h-lg-11\/12 {
    height: 91.6666666667% !important
  }

  .h-lg-100 {
    height: 100% !important
  }

  .h-lg-auto {
    height: auto !important
  }
}

@media (min-width: 1280px) {
  .w-xl-1\/12 {
    width: 8.3333333333% !important
  }

  .w-xl-2\/12 {
    width: 16.6666666667% !important
  }

  .w-xl-25 {
    width: 25% !important
  }

  .w-xl-1\/3 {
    width: 33.3333333333% !important
  }

  .w-xl-5\/12 {
    width: 41.6666666667% !important
  }

  .w-xl-50 {
    width: 50% !important
  }

  .w-xl-7\/12 {
    width: 58.3333333333% !important
  }

  .w-xl-2\/3 {
    width: 66.6666666667% !important
  }

  .w-xl-75 {
    width: 75% !important
  }

  .w-xl-10\/12 {
    width: 83.3333333333% !important
  }

  .w-xl-11\/12 {
    width: 91.6666666667% !important
  }

  .w-xl-100 {
    width: 100% !important
  }

  .w-xl-auto {
    width: auto !important
  }

  .h-xl-1\/12 {
    height: 8.3333333333% !important
  }

  .h-xl-2\/12 {
    height: 16.6666666667% !important
  }

  .h-xl-25 {
    height: 25% !important
  }

  .h-xl-1\/3 {
    height: 33.3333333333% !important
  }

  .h-xl-5\/12 {
    height: 41.6666666667% !important
  }

  .h-xl-50 {
    height: 50% !important
  }

  .h-xl-7\/12 {
    height: 58.3333333333% !important
  }

  .h-xl-2\/3 {
    height: 66.6666666667% !important
  }

  .h-xl-75 {
    height: 75% !important
  }

  .h-xl-10\/12 {
    height: 83.3333333333% !important
  }

  .h-xl-11\/12 {
    height: 91.6666666667% !important
  }

  .h-xl-100 {
    height: 100% !important
  }

  .h-xl-auto {
    height: auto !important
  }
}

.max-width-container {
  max-width: 1470px
}

.max-width-xxwide {
  max-width: 54rem
}

.max-width-xwide {
  max-width: 48rem
}

.max-width-wide {
  max-width: 42rem
}

.max-width-narrow {
  max-width: 36rem
}

.max-width-xnarrow {
  max-width: 30rem
}

.max-width-xxnarrow {
  max-width: 24rem
}

.max-width-xxxnarrow {
  max-width: 18rem
}

.m-0 {
  margin: 0 !important
}

.mt-0,
.my-0 {
  margin-top: 0 !important
}

.mr-0,
.mx-0 {
  margin-right: 0 !important
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important
}

.ml-0,
.mx-0 {
  margin-left: 0 !important
}

.m-1\/12 {
  margin: .1166666667rem !important
}

.mt-1\/12,
.my-1\/12 {
  margin-top: .1166666667rem !important
}

.mr-1\/12,
.mx-1\/12 {
  margin-right: .1166666667rem !important
}

.mb-1\/12,
.my-1\/12 {
  margin-bottom: .1166666667rem !important
}

.ml-1\/12,
.mx-1\/12 {
  margin-left: .1166666667rem !important
}

.m-0\.1 {
  margin: .14rem !important
}

.mt-0\.1,
.my-0\.1 {
  margin-top: .14rem !important
}

.mr-0\.1,
.mx-0\.1 {
  margin-right: .14rem !important
}

.mb-0\.1,
.my-0\.1 {
  margin-bottom: .14rem !important
}

.ml-0\.1,
.mx-0\.1 {
  margin-left: .14rem !important
}

.m-0\.125 {
  margin: .175rem !important
}

.mt-0\.125,
.my-0\.125 {
  margin-top: .175rem !important
}

.mr-0\.125,
.mx-0\.125 {
  margin-right: .175rem !important
}

.mb-0\.125,
.my-0\.125 {
  margin-bottom: .175rem !important
}

.ml-0\.125,
.mx-0\.125 {
  margin-left: .175rem !important
}

.m-1\/6 {
  margin: .2333333333rem !important
}

.mt-1\/6,
.my-1\/6 {
  margin-top: .2333333333rem !important
}

.mr-1\/6,
.mx-1\/6 {
  margin-right: .2333333333rem !important
}

.mb-1\/6,
.my-1\/6 {
  margin-bottom: .2333333333rem !important
}

.ml-1\/6,
.mx-1\/6 {
  margin-left: .2333333333rem !important
}

.m-0\.2 {
  margin: .28rem !important
}

.mt-0\.2,
.my-0\.2 {
  margin-top: .28rem !important
}

.mr-0\.2,
.mx-0\.2 {
  margin-right: .28rem !important
}

.mb-0\.2,
.my-0\.2 {
  margin-bottom: .28rem !important
}

.ml-0\.2,
.mx-0\.2 {
  margin-left: .28rem !important
}

.m-0\.25 {
  margin: .35rem !important
}

.mt-0\.25,
.my-0\.25 {
  margin-top: .35rem !important
}

.mr-0\.25,
.mx-0\.25 {
  margin-right: .35rem !important
}

.mb-0\.25,
.my-0\.25 {
  margin-bottom: .35rem !important
}

.ml-0\.25,
.mx-0\.25 {
  margin-left: .35rem !important
}

.m-1\/3 {
  margin: .4666666667rem !important
}

.mt-1\/3,
.my-1\/3 {
  margin-top: .4666666667rem !important
}

.mr-1\/3,
.mx-1\/3 {
  margin-right: .4666666667rem !important
}

.mb-1\/3,
.my-1\/3 {
  margin-bottom: .4666666667rem !important
}

.ml-1\/3,
.mx-1\/3 {
  margin-left: .4666666667rem !important
}

.m-5\/12 {
  margin: .5833333333rem !important
}

.mt-5\/12,
.my-5\/12 {
  margin-top: .5833333333rem !important
}

.mr-5\/12,
.mx-5\/12 {
  margin-right: .5833333333rem !important
}

.mb-5\/12,
.my-5\/12 {
  margin-bottom: .5833333333rem !important
}

.ml-5\/12,
.mx-5\/12 {
  margin-left: .5833333333rem !important
}

.m-0\.5 {
  margin: .7rem !important
}

.mt-0\.5,
.my-0\.5 {
  margin-top: .7rem !important
}

.mr-0\.5,
.mx-0\.5 {
  margin-right: .7rem !important
}

.mb-0\.5,
.my-0\.5 {
  margin-bottom: .7rem !important
}

.ml-0\.5,
.mx-0\.5 {
  margin-left: .7rem !important
}

.m-7\/12 {
  margin: .8166666667rem !important
}

.mt-7\/12,
.my-7\/12 {
  margin-top: .8166666667rem !important
}

.mr-7\/12,
.mx-7\/12 {
  margin-right: .8166666667rem !important
}

.mb-7\/12,
.my-7\/12 {
  margin-bottom: .8166666667rem !important
}

.ml-7\/12,
.mx-7\/12 {
  margin-left: .8166666667rem !important
}

.m-2\/3 {
  margin: .9333333333rem !important
}

.mt-2\/3,
.my-2\/3 {
  margin-top: .9333333333rem !important
}

.mr-2\/3,
.mx-2\/3 {
  margin-right: .9333333333rem !important
}

.mb-2\/3,
.my-2\/3 {
  margin-bottom: .9333333333rem !important
}

.ml-2\/3,
.mx-2\/3 {
  margin-left: .9333333333rem !important
}

.m-0\.75 {
  margin: 1.05rem !important
}

.mt-0\.75,
.my-0\.75 {
  margin-top: 1.05rem !important
}

.mr-0\.75,
.mx-0\.75 {
  margin-right: 1.05rem !important
}

.mb-0\.75,
.my-0\.75 {
  margin-bottom: 1.05rem !important
}

.ml-0\.75,
.mx-0\.75 {
  margin-left: 1.05rem !important
}

.m-10\/12 {
  margin: 1.1666666667rem !important
}

.mt-10\/12,
.my-10\/12 {
  margin-top: 1.1666666667rem !important
}

.mr-10\/12,
.mx-10\/12 {
  margin-right: 1.1666666667rem !important
}

.mb-10\/12,
.my-10\/12 {
  margin-bottom: 1.1666666667rem !important
}

.ml-10\/12,
.mx-10\/12 {
  margin-left: 1.1666666667rem !important
}

.m-11\/12 {
  margin: 1.2833333333rem !important
}

.mt-11\/12,
.my-11\/12 {
  margin-top: 1.2833333333rem !important
}

.mr-11\/12,
.mx-11\/12 {
  margin-right: 1.2833333333rem !important
}

.mb-11\/12,
.my-11\/12 {
  margin-bottom: 1.2833333333rem !important
}

.ml-11\/12,
.mx-11\/12 {
  margin-left: 1.2833333333rem !important
}

.m-1 {
  margin: 1.4rem !important
}

.mt-1,
.my-1 {
  margin-top: 1.4rem !important
}

.mr-1,
.mx-1 {
  margin-right: 1.4rem !important
}

.mb-1,
.my-1 {
  margin-bottom: 1.4rem !important
}

.ml-1,
.mx-1 {
  margin-left: 1.4rem !important
}

.m-1\.25 {
  margin: 1.75rem !important
}

.mt-1\.25,
.my-1\.25 {
  margin-top: 1.75rem !important
}

.mr-1\.25,
.mx-1\.25 {
  margin-right: 1.75rem !important
}

.mb-1\.25,
.my-1\.25 {
  margin-bottom: 1.75rem !important
}

.ml-1\.25,
.mx-1\.25 {
  margin-left: 1.75rem !important
}

.m-1\.5 {
  margin: 2.1rem !important
}

.mt-1\.5,
.my-1\.5 {
  margin-top: 2.1rem !important
}

.mr-1\.5,
.mx-1\.5 {
  margin-right: 2.1rem !important
}

.mb-1\.5,
.my-1\.5 {
  margin-bottom: 2.1rem !important
}

.ml-1\.5,
.mx-1\.5 {
  margin-left: 2.1rem !important
}

.m-1\.75 {
  margin: 2.45rem !important
}

.mt-1\.75,
.my-1\.75 {
  margin-top: 2.45rem !important
}

.mr-1\.75,
.mx-1\.75 {
  margin-right: 2.45rem !important
}

.mb-1\.75,
.my-1\.75 {
  margin-bottom: 2.45rem !important
}

.ml-1\.75,
.mx-1\.75 {
  margin-left: 2.45rem !important
}

.m-2 {
  margin: 2.8rem !important
}

.mt-2,
.my-2 {
  margin-top: 2.8rem !important
}

.mr-2,
.mx-2 {
  margin-right: 2.8rem !important
}

.mb-2,
.my-2 {
  margin-bottom: 2.8rem !important
}

.ml-2,
.mx-2 {
  margin-left: 2.8rem !important
}

.m-2\.25 {
  margin: 3.15rem !important
}

.mt-2\.25,
.my-2\.25 {
  margin-top: 3.15rem !important
}

.mr-2\.25,
.mx-2\.25 {
  margin-right: 3.15rem !important
}

.mb-2\.25,
.my-2\.25 {
  margin-bottom: 3.15rem !important
}

.ml-2\.25,
.mx-2\.25 {
  margin-left: 3.15rem !important
}

.m-2\.5 {
  margin: 3.5rem !important
}

.mt-2\.5,
.my-2\.5 {
  margin-top: 3.5rem !important
}

.mr-2\.5,
.mx-2\.5 {
  margin-right: 3.5rem !important
}

.mb-2\.5,
.my-2\.5 {
  margin-bottom: 3.5rem !important
}

.ml-2\.5,
.mx-2\.5 {
  margin-left: 3.5rem !important
}

.m-2\.75 {
  margin: 3.85rem !important
}

.mt-2\.75,
.my-2\.75 {
  margin-top: 3.85rem !important
}

.mr-2\.75,
.mx-2\.75 {
  margin-right: 3.85rem !important
}

.mb-2\.75,
.my-2\.75 {
  margin-bottom: 3.85rem !important
}

.ml-2\.75,
.mx-2\.75 {
  margin-left: 3.85rem !important
}

.m-3 {
  margin: 4.2rem !important
}

.mt-3,
.my-3 {
  margin-top: 4.2rem !important
}

.mr-3,
.mx-3 {
  margin-right: 4.2rem !important
}

.mb-3,
.my-3 {
  margin-bottom: 4.2rem !important
}

.ml-3,
.mx-3 {
  margin-left: 4.2rem !important
}

.m-3\.5 {
  margin: 4.9rem !important
}

.mt-3\.5,
.my-3\.5 {
  margin-top: 4.9rem !important
}

.mr-3\.5,
.mx-3\.5 {
  margin-right: 4.9rem !important
}

.mb-3\.5,
.my-3\.5 {
  margin-bottom: 4.9rem !important
}

.ml-3\.5,
.mx-3\.5 {
  margin-left: 4.9rem !important
}

.m-4 {
  margin: 5.6rem !important
}

.mt-4,
.my-4 {
  margin-top: 5.6rem !important
}

.mr-4,
.mx-4 {
  margin-right: 5.6rem !important
}

.mb-4,
.my-4 {
  margin-bottom: 5.6rem !important
}

.ml-4,
.mx-4 {
  margin-left: 5.6rem !important
}

.m-5 {
  margin: 7rem !important
}

.mt-5,
.my-5 {
  margin-top: 7rem !important
}

.mr-5,
.mx-5 {
  margin-right: 7rem !important
}

.mb-5,
.my-5 {
  margin-bottom: 7rem !important
}

.ml-5,
.mx-5 {
  margin-left: 7rem !important
}

.m-6 {
  margin: 8.4rem !important
}

.mt-6,
.my-6 {
  margin-top: 8.4rem !important
}

.mr-6,
.mx-6 {
  margin-right: 8.4rem !important
}

.mb-6,
.my-6 {
  margin-bottom: 8.4rem !important
}

.ml-6,
.mx-6 {
  margin-left: 8.4rem !important
}

.m-7 {
  margin: 9.8rem !important
}

.mt-7,
.my-7 {
  margin-top: 9.8rem !important
}

.mr-7,
.mx-7 {
  margin-right: 9.8rem !important
}

.mb-7,
.my-7 {
  margin-bottom: 9.8rem !important
}

.ml-7,
.mx-7 {
  margin-left: 9.8rem !important
}

.m-8 {
  margin: 11.2rem !important
}

.mt-8,
.my-8 {
  margin-top: 11.2rem !important
}

.mr-8,
.mx-8 {
  margin-right: 11.2rem !important
}

.mb-8,
.my-8 {
  margin-bottom: 11.2rem !important
}

.ml-8,
.mx-8 {
  margin-left: 11.2rem !important
}

.m-9 {
  margin: 12.6rem !important
}

.mt-9,
.my-9 {
  margin-top: 12.6rem !important
}

.mr-9,
.mx-9 {
  margin-right: 12.6rem !important
}

.mb-9,
.my-9 {
  margin-bottom: 12.6rem !important
}

.ml-9,
.mx-9 {
  margin-left: 12.6rem !important
}

.m-10 {
  margin: 14rem !important
}

.mt-10,
.my-10 {
  margin-top: 14rem !important
}

.mr-10,
.mx-10 {
  margin-right: 14rem !important
}

.mb-10,
.my-10 {
  margin-bottom: 14rem !important
}

.ml-10,
.mx-10 {
  margin-left: 14rem !important
}

.p-0 {
  padding: 0 !important
}

.pt-0,
.py-0 {
  padding-top: 0 !important
}

.pr-0,
.px-0 {
  padding-right: 0 !important
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important
}

.pl-0,
.px-0 {
  padding-left: 0 !important
}

.p-1\/12 {
  padding: .1166666667rem !important
}

.pt-1\/12,
.py-1\/12 {
  padding-top: .1166666667rem !important
}

.pr-1\/12,
.px-1\/12 {
  padding-right: .1166666667rem !important
}

.pb-1\/12,
.py-1\/12 {
  padding-bottom: .1166666667rem !important
}

.pl-1\/12,
.px-1\/12 {
  padding-left: .1166666667rem !important
}

.p-0\.1 {
  padding: .14rem !important
}

.pt-0\.1,
.py-0\.1 {
  padding-top: .14rem !important
}

.pr-0\.1,
.px-0\.1 {
  padding-right: .14rem !important
}

.pb-0\.1,
.py-0\.1 {
  padding-bottom: .14rem !important
}

.pl-0\.1,
.px-0\.1 {
  padding-left: .14rem !important
}

.p-0\.125 {
  padding: .175rem !important
}

.pt-0\.125,
.py-0\.125 {
  padding-top: .175rem !important
}

.pr-0\.125,
.px-0\.125 {
  padding-right: .175rem !important
}

.pb-0\.125,
.py-0\.125 {
  padding-bottom: .175rem !important
}

.pl-0\.125,
.px-0\.125 {
  padding-left: .175rem !important
}

.p-1\/6 {
  padding: .2333333333rem !important
}

.pt-1\/6,
.py-1\/6 {
  padding-top: .2333333333rem !important
}

.pr-1\/6,
.px-1\/6 {
  padding-right: .2333333333rem !important
}

.pb-1\/6,
.py-1\/6 {
  padding-bottom: .2333333333rem !important
}

.pl-1\/6,
.px-1\/6 {
  padding-left: .2333333333rem !important
}

.p-0\.2 {
  padding: .28rem !important
}

.pt-0\.2,
.py-0\.2 {
  padding-top: .28rem !important
}

.pr-0\.2,
.px-0\.2 {
  padding-right: .28rem !important
}

.pb-0\.2,
.py-0\.2 {
  padding-bottom: .28rem !important
}

.pl-0\.2,
.px-0\.2 {
  padding-left: .28rem !important
}

.p-0\.25 {
  padding: .35rem !important
}

.pt-0\.25,
.py-0\.25 {
  padding-top: .35rem !important
}

.pr-0\.25,
.px-0\.25 {
  padding-right: .35rem !important
}

.pb-0\.25,
.py-0\.25 {
  padding-bottom: .35rem !important
}

.pl-0\.25,
.px-0\.25 {
  padding-left: .35rem !important
}

.p-1\/3 {
  padding: .4666666667rem !important
}

.pt-1\/3,
.py-1\/3 {
  padding-top: .4666666667rem !important
}

.pr-1\/3,
.px-1\/3 {
  padding-right: .4666666667rem !important
}

.pb-1\/3,
.py-1\/3 {
  padding-bottom: .4666666667rem !important
}

.pl-1\/3,
.px-1\/3 {
  padding-left: .4666666667rem !important
}

.p-5\/12 {
  padding: .5833333333rem !important
}

.pt-5\/12,
.py-5\/12 {
  padding-top: .5833333333rem !important
}

.pr-5\/12,
.px-5\/12 {
  padding-right: .5833333333rem !important
}

.pb-5\/12,
.py-5\/12 {
  padding-bottom: .5833333333rem !important
}

.pl-5\/12,
.px-5\/12 {
  padding-left: .5833333333rem !important
}

.p-0\.5 {
  padding: .7rem !important
}

.pt-0\.5,
.py-0\.5 {
  padding-top: .7rem !important
}

.pr-0\.5,
.px-0\.5 {
  padding-right: .7rem !important
}

.pb-0\.5,
.py-0\.5 {
  padding-bottom: .7rem !important
}

.pl-0\.5,
.px-0\.5 {
  padding-left: .7rem !important
}

.p-7\/12 {
  padding: .8166666667rem !important
}

.pt-7\/12,
.py-7\/12 {
  padding-top: .8166666667rem !important
}

.pr-7\/12,
.px-7\/12 {
  padding-right: .8166666667rem !important
}

.pb-7\/12,
.py-7\/12 {
  padding-bottom: .8166666667rem !important
}

.pl-7\/12,
.px-7\/12 {
  padding-left: .8166666667rem !important
}

.p-2\/3 {
  padding: .9333333333rem !important
}

.pt-2\/3,
.py-2\/3 {
  padding-top: .9333333333rem !important
}

.pr-2\/3,
.px-2\/3 {
  padding-right: .9333333333rem !important
}

.pb-2\/3,
.py-2\/3 {
  padding-bottom: .9333333333rem !important
}

.pl-2\/3,
.px-2\/3 {
  padding-left: .9333333333rem !important
}

.p-0\.75 {
  padding: 1.05rem !important
}

.pt-0\.75,
.py-0\.75 {
  padding-top: 1.05rem !important
}

.pr-0\.75,
.px-0\.75 {
  padding-right: 1.05rem !important
}

.pb-0\.75,
.py-0\.75 {
  padding-bottom: 1.05rem !important
}

.pl-0\.75,
.px-0\.75 {
  padding-left: 1.05rem !important
}

.p-10\/12 {
  padding: 1.1666666667rem !important
}

.pt-10\/12,
.py-10\/12 {
  padding-top: 1.1666666667rem !important
}

.pr-10\/12,
.px-10\/12 {
  padding-right: 1.1666666667rem !important
}

.pb-10\/12,
.py-10\/12 {
  padding-bottom: 1.1666666667rem !important
}

.pl-10\/12,
.px-10\/12 {
  padding-left: 1.1666666667rem !important
}

.p-11\/12 {
  padding: 1.2833333333rem !important
}

.pt-11\/12,
.py-11\/12 {
  padding-top: 1.2833333333rem !important
}

.pr-11\/12,
.px-11\/12 {
  padding-right: 1.2833333333rem !important
}

.pb-11\/12,
.py-11\/12 {
  padding-bottom: 1.2833333333rem !important
}

.pl-11\/12,
.px-11\/12 {
  padding-left: 1.2833333333rem !important
}

.p-1 {
  padding: 1.4rem !important
}

.pt-1,
.py-1 {
  padding-top: 1.4rem !important
}

.pr-1,
.px-1 {
  padding-right: 1.4rem !important
}

.pb-1,
.py-1 {
  padding-bottom: 1.4rem !important
}

.pl-1,
.px-1 {
  padding-left: 1.4rem !important
}

.p-1\.25 {
  padding: 1.75rem !important
}

.pt-1\.25,
.py-1\.25 {
  padding-top: 1.75rem !important
}

.pr-1\.25,
.px-1\.25 {
  padding-right: 1.75rem !important
}

.pb-1\.25,
.py-1\.25 {
  padding-bottom: 1.75rem !important
}

.pl-1\.25,
.px-1\.25 {
  padding-left: 1.75rem !important
}

.p-1\.5 {
  padding: 2.1rem !important
}

.pt-1\.5,
.py-1\.5 {
  padding-top: 2.1rem !important
}

.pr-1\.5,
.px-1\.5 {
  padding-right: 2.1rem !important
}

.pb-1\.5,
.py-1\.5 {
  padding-bottom: 2.1rem !important
}

.pl-1\.5,
.px-1\.5 {
  padding-left: 2.1rem !important
}

.p-1\.75 {
  padding: 2.45rem !important
}

.pt-1\.75,
.py-1\.75 {
  padding-top: 2.45rem !important
}

.pr-1\.75,
.px-1\.75 {
  padding-right: 2.45rem !important
}

.pb-1\.75,
.py-1\.75 {
  padding-bottom: 2.45rem !important
}

.pl-1\.75,
.px-1\.75 {
  padding-left: 2.45rem !important
}

.p-2 {
  padding: 2.8rem !important
}

.pt-2,
.py-2 {
  padding-top: 2.8rem !important
}

.pr-2,
.px-2 {
  padding-right: 2.8rem !important
}

.pb-2,
.py-2 {
  padding-bottom: 2.8rem !important
}

.pl-2,
.px-2 {
  padding-left: 2.8rem !important
}

.p-2\.25 {
  padding: 3.15rem !important
}

.pt-2\.25,
.py-2\.25 {
  padding-top: 3.15rem !important
}

.pr-2\.25,
.px-2\.25 {
  padding-right: 3.15rem !important
}

.pb-2\.25,
.py-2\.25 {
  padding-bottom: 3.15rem !important
}

.pl-2\.25,
.px-2\.25 {
  padding-left: 3.15rem !important
}

.p-2\.5 {
  padding: 3.5rem !important
}

.pt-2\.5,
.py-2\.5 {
  padding-top: 3.5rem !important
}

.pr-2\.5,
.px-2\.5 {
  padding-right: 3.5rem !important
}

.pb-2\.5,
.py-2\.5 {
  padding-bottom: 3.5rem !important
}

.pl-2\.5,
.px-2\.5 {
  padding-left: 3.5rem !important
}

.p-2\.75 {
  padding: 3.85rem !important
}

.pt-2\.75,
.py-2\.75 {
  padding-top: 3.85rem !important
}

.pr-2\.75,
.px-2\.75 {
  padding-right: 3.85rem !important
}

.pb-2\.75,
.py-2\.75 {
  padding-bottom: 3.85rem !important
}

.pl-2\.75,
.px-2\.75 {
  padding-left: 3.85rem !important
}

.p-3 {
  padding: 4.2rem !important
}

.pt-3,
.py-3 {
  padding-top: 4.2rem !important
}

.pr-3,
.px-3 {
  padding-right: 4.2rem !important
}

.pb-3,
.py-3 {
  padding-bottom: 4.2rem !important
}

.pl-3,
.px-3 {
  padding-left: 4.2rem !important
}

.p-3\.5 {
  padding: 4.9rem !important
}

.pt-3\.5,
.py-3\.5 {
  padding-top: 4.9rem !important
}

.pr-3\.5,
.px-3\.5 {
  padding-right: 4.9rem !important
}

.pb-3\.5,
.py-3\.5 {
  padding-bottom: 4.9rem !important
}

.pl-3\.5,
.px-3\.5 {
  padding-left: 4.9rem !important
}

.p-4 {
  padding: 5.6rem !important
}

.pt-4,
.py-4 {
  padding-top: 5.6rem !important
}

.pr-4,
.px-4 {
  padding-right: 5.6rem !important
}

.pb-4,
.py-4 {
  padding-bottom: 5.6rem !important
}

.pl-4,
.px-4 {
  padding-left: 5.6rem !important
}

.p-5 {
  padding: 7rem !important
}

.pt-5,
.py-5 {
  padding-top: 7rem !important
}

.pr-5,
.px-5 {
  padding-right: 7rem !important
}

.pb-5,
.py-5 {
  padding-bottom: 7rem !important
}

.pl-5,
.px-5 {
  padding-left: 7rem !important
}

.p-6 {
  padding: 8.4rem !important
}

.pt-6,
.py-6 {
  padding-top: 8.4rem !important
}

.pr-6,
.px-6 {
  padding-right: 8.4rem !important
}

.pb-6,
.py-6 {
  padding-bottom: 8.4rem !important
}

.pl-6,
.px-6 {
  padding-left: 8.4rem !important
}

.p-7 {
  padding: 9.8rem !important
}

.pt-7,
.py-7 {
  padding-top: 9.8rem !important
}

.pr-7,
.px-7 {
  padding-right: 9.8rem !important
}

.pb-7,
.py-7 {
  padding-bottom: 9.8rem !important
}

.pl-7,
.px-7 {
  padding-left: 9.8rem !important
}

.p-8 {
  padding: 11.2rem !important
}

.pt-8,
.py-8 {
  padding-top: 11.2rem !important
}

.pr-8,
.px-8 {
  padding-right: 11.2rem !important
}

.pb-8,
.py-8 {
  padding-bottom: 11.2rem !important
}

.pl-8,
.px-8 {
  padding-left: 11.2rem !important
}

.p-9 {
  padding: 12.6rem !important
}

.pt-9,
.py-9 {
  padding-top: 12.6rem !important
}

.pr-9,
.px-9 {
  padding-right: 12.6rem !important
}

.pb-9,
.py-9 {
  padding-bottom: 12.6rem !important
}

.pl-9,
.px-9 {
  padding-left: 12.6rem !important
}

.p-10 {
  padding: 14rem !important
}

.pt-10,
.py-10 {
  padding-top: 14rem !important
}

.pr-10,
.px-10 {
  padding-right: 14rem !important
}

.pb-10,
.py-10 {
  padding-bottom: 14rem !important
}

.pl-10,
.px-10 {
  padding-left: 14rem !important
}

.m-n1\/12 {
  margin: -.1166666667rem !important
}

.mt-n1\/12,
.my-n1\/12 {
  margin-top: -.1166666667rem !important
}

.mr-n1\/12,
.mx-n1\/12 {
  margin-right: -.1166666667rem !important
}

.mb-n1\/12,
.my-n1\/12 {
  margin-bottom: -.1166666667rem !important
}

.ml-n1\/12,
.mx-n1\/12 {
  margin-left: -.1166666667rem !important
}

.m-n0\.1 {
  margin: -.14rem !important
}

.mt-n0\.1,
.my-n0\.1 {
  margin-top: -.14rem !important
}

.mr-n0\.1,
.mx-n0\.1 {
  margin-right: -.14rem !important
}

.mb-n0\.1,
.my-n0\.1 {
  margin-bottom: -.14rem !important
}

.ml-n0\.1,
.mx-n0\.1 {
  margin-left: -.14rem !important
}

.m-n0\.125 {
  margin: -.175rem !important
}

.mt-n0\.125,
.my-n0\.125 {
  margin-top: -.175rem !important
}

.mr-n0\.125,
.mx-n0\.125 {
  margin-right: -.175rem !important
}

.mb-n0\.125,
.my-n0\.125 {
  margin-bottom: -.175rem !important
}

.ml-n0\.125,
.mx-n0\.125 {
  margin-left: -.175rem !important
}

.m-n1\/6 {
  margin: -.2333333333rem !important
}

.mt-n1\/6,
.my-n1\/6 {
  margin-top: -.2333333333rem !important
}

.mr-n1\/6,
.mx-n1\/6 {
  margin-right: -.2333333333rem !important
}

.mb-n1\/6,
.my-n1\/6 {
  margin-bottom: -.2333333333rem !important
}

.ml-n1\/6,
.mx-n1\/6 {
  margin-left: -.2333333333rem !important
}

.m-n0\.2 {
  margin: -.28rem !important
}

.mt-n0\.2,
.my-n0\.2 {
  margin-top: -.28rem !important
}

.mr-n0\.2,
.mx-n0\.2 {
  margin-right: -.28rem !important
}

.mb-n0\.2,
.my-n0\.2 {
  margin-bottom: -.28rem !important
}

.ml-n0\.2,
.mx-n0\.2 {
  margin-left: -.28rem !important
}

.m-n0\.25 {
  margin: -.35rem !important
}

.mt-n0\.25,
.my-n0\.25 {
  margin-top: -.35rem !important
}

.mr-n0\.25,
.mx-n0\.25 {
  margin-right: -.35rem !important
}

.mb-n0\.25,
.my-n0\.25 {
  margin-bottom: -.35rem !important
}

.ml-n0\.25,
.mx-n0\.25 {
  margin-left: -.35rem !important
}

.m-n1\/3 {
  margin: -.4666666667rem !important
}

.mt-n1\/3,
.my-n1\/3 {
  margin-top: -.4666666667rem !important
}

.mr-n1\/3,
.mx-n1\/3 {
  margin-right: -.4666666667rem !important
}

.mb-n1\/3,
.my-n1\/3 {
  margin-bottom: -.4666666667rem !important
}

.ml-n1\/3,
.mx-n1\/3 {
  margin-left: -.4666666667rem !important
}

.m-n5\/12 {
  margin: -.5833333333rem !important
}

.mt-n5\/12,
.my-n5\/12 {
  margin-top: -.5833333333rem !important
}

.mr-n5\/12,
.mx-n5\/12 {
  margin-right: -.5833333333rem !important
}

.mb-n5\/12,
.my-n5\/12 {
  margin-bottom: -.5833333333rem !important
}

.ml-n5\/12,
.mx-n5\/12 {
  margin-left: -.5833333333rem !important
}

.m-n0\.5 {
  margin: -.7rem !important
}

.mt-n0\.5,
.my-n0\.5 {
  margin-top: -.7rem !important
}

.mr-n0\.5,
.mx-n0\.5 {
  margin-right: -.7rem !important
}

.mb-n0\.5,
.my-n0\.5 {
  margin-bottom: -.7rem !important
}

.ml-n0\.5,
.mx-n0\.5 {
  margin-left: -.7rem !important
}

.m-n7\/12 {
  margin: -.8166666667rem !important
}

.mt-n7\/12,
.my-n7\/12 {
  margin-top: -.8166666667rem !important
}

.mr-n7\/12,
.mx-n7\/12 {
  margin-right: -.8166666667rem !important
}

.mb-n7\/12,
.my-n7\/12 {
  margin-bottom: -.8166666667rem !important
}

.ml-n7\/12,
.mx-n7\/12 {
  margin-left: -.8166666667rem !important
}

.m-n2\/3 {
  margin: -.9333333333rem !important
}

.mt-n2\/3,
.my-n2\/3 {
  margin-top: -.9333333333rem !important
}

.mr-n2\/3,
.mx-n2\/3 {
  margin-right: -.9333333333rem !important
}

.mb-n2\/3,
.my-n2\/3 {
  margin-bottom: -.9333333333rem !important
}

.ml-n2\/3,
.mx-n2\/3 {
  margin-left: -.9333333333rem !important
}

.m-n0\.75 {
  margin: -1.05rem !important
}

.mt-n0\.75,
.my-n0\.75 {
  margin-top: -1.05rem !important
}

.mr-n0\.75,
.mx-n0\.75 {
  margin-right: -1.05rem !important
}

.mb-n0\.75,
.my-n0\.75 {
  margin-bottom: -1.05rem !important
}

.ml-n0\.75,
.mx-n0\.75 {
  margin-left: -1.05rem !important
}

.m-n10\/12 {
  margin: -1.1666666667rem !important
}

.mt-n10\/12,
.my-n10\/12 {
  margin-top: -1.1666666667rem !important
}

.mr-n10\/12,
.mx-n10\/12 {
  margin-right: -1.1666666667rem !important
}

.mb-n10\/12,
.my-n10\/12 {
  margin-bottom: -1.1666666667rem !important
}

.ml-n10\/12,
.mx-n10\/12 {
  margin-left: -1.1666666667rem !important
}

.m-n11\/12 {
  margin: -1.2833333333rem !important
}

.mt-n11\/12,
.my-n11\/12 {
  margin-top: -1.2833333333rem !important
}

.mr-n11\/12,
.mx-n11\/12 {
  margin-right: -1.2833333333rem !important
}

.mb-n11\/12,
.my-n11\/12 {
  margin-bottom: -1.2833333333rem !important
}

.ml-n11\/12,
.mx-n11\/12 {
  margin-left: -1.2833333333rem !important
}

.m-n1 {
  margin: -1.4rem !important
}

.mt-n1,
.my-n1 {
  margin-top: -1.4rem !important
}

.mr-n1,
.mx-n1 {
  margin-right: -1.4rem !important
}

.mb-n1,
.my-n1 {
  margin-bottom: -1.4rem !important
}

.ml-n1,
.mx-n1 {
  margin-left: -1.4rem !important
}

.m-n1\.25 {
  margin: -1.75rem !important
}

.mt-n1\.25,
.my-n1\.25 {
  margin-top: -1.75rem !important
}

.mr-n1\.25,
.mx-n1\.25 {
  margin-right: -1.75rem !important
}

.mb-n1\.25,
.my-n1\.25 {
  margin-bottom: -1.75rem !important
}

.ml-n1\.25,
.mx-n1\.25 {
  margin-left: -1.75rem !important
}

.m-n1\.5 {
  margin: -2.1rem !important
}

.mt-n1\.5,
.my-n1\.5 {
  margin-top: -2.1rem !important
}

.mr-n1\.5,
.mx-n1\.5 {
  margin-right: -2.1rem !important
}

.mb-n1\.5,
.my-n1\.5 {
  margin-bottom: -2.1rem !important
}

.ml-n1\.5,
.mx-n1\.5 {
  margin-left: -2.1rem !important
}

.m-n1\.75 {
  margin: -2.45rem !important
}

.mt-n1\.75,
.my-n1\.75 {
  margin-top: -2.45rem !important
}

.mr-n1\.75,
.mx-n1\.75 {
  margin-right: -2.45rem !important
}

.mb-n1\.75,
.my-n1\.75 {
  margin-bottom: -2.45rem !important
}

.ml-n1\.75,
.mx-n1\.75 {
  margin-left: -2.45rem !important
}

.m-n2 {
  margin: -2.8rem !important
}

.mt-n2,
.my-n2 {
  margin-top: -2.8rem !important
}

.mr-n2,
.mx-n2 {
  margin-right: -2.8rem !important
}

.mb-n2,
.my-n2 {
  margin-bottom: -2.8rem !important
}

.ml-n2,
.mx-n2 {
  margin-left: -2.8rem !important
}

.m-n2\.25 {
  margin: -3.15rem !important
}

.mt-n2\.25,
.my-n2\.25 {
  margin-top: -3.15rem !important
}

.mr-n2\.25,
.mx-n2\.25 {
  margin-right: -3.15rem !important
}

.mb-n2\.25,
.my-n2\.25 {
  margin-bottom: -3.15rem !important
}

.ml-n2\.25,
.mx-n2\.25 {
  margin-left: -3.15rem !important
}

.m-n2\.5 {
  margin: -3.5rem !important
}

.mt-n2\.5,
.my-n2\.5 {
  margin-top: -3.5rem !important
}

.mr-n2\.5,
.mx-n2\.5 {
  margin-right: -3.5rem !important
}

.mb-n2\.5,
.my-n2\.5 {
  margin-bottom: -3.5rem !important
}

.ml-n2\.5,
.mx-n2\.5 {
  margin-left: -3.5rem !important
}

.m-n2\.75 {
  margin: -3.85rem !important
}

.mt-n2\.75,
.my-n2\.75 {
  margin-top: -3.85rem !important
}

.mr-n2\.75,
.mx-n2\.75 {
  margin-right: -3.85rem !important
}

.mb-n2\.75,
.my-n2\.75 {
  margin-bottom: -3.85rem !important
}

.ml-n2\.75,
.mx-n2\.75 {
  margin-left: -3.85rem !important
}

.m-n3 {
  margin: -4.2rem !important
}

.mt-n3,
.my-n3 {
  margin-top: -4.2rem !important
}

.mr-n3,
.mx-n3 {
  margin-right: -4.2rem !important
}

.mb-n3,
.my-n3 {
  margin-bottom: -4.2rem !important
}

.ml-n3,
.mx-n3 {
  margin-left: -4.2rem !important
}

.m-n3\.5 {
  margin: -4.9rem !important
}

.mt-n3\.5,
.my-n3\.5 {
  margin-top: -4.9rem !important
}

.mr-n3\.5,
.mx-n3\.5 {
  margin-right: -4.9rem !important
}

.mb-n3\.5,
.my-n3\.5 {
  margin-bottom: -4.9rem !important
}

.ml-n3\.5,
.mx-n3\.5 {
  margin-left: -4.9rem !important
}

.m-n4 {
  margin: -5.6rem !important
}

.mt-n4,
.my-n4 {
  margin-top: -5.6rem !important
}

.mr-n4,
.mx-n4 {
  margin-right: -5.6rem !important
}

.mb-n4,
.my-n4 {
  margin-bottom: -5.6rem !important
}

.ml-n4,
.mx-n4 {
  margin-left: -5.6rem !important
}

.m-n5 {
  margin: -7rem !important
}

.mt-n5,
.my-n5 {
  margin-top: -7rem !important
}

.mr-n5,
.mx-n5 {
  margin-right: -7rem !important
}

.mb-n5,
.my-n5 {
  margin-bottom: -7rem !important
}

.ml-n5,
.mx-n5 {
  margin-left: -7rem !important
}

.m-n6 {
  margin: -8.4rem !important
}

.mt-n6,
.my-n6 {
  margin-top: -8.4rem !important
}

.mr-n6,
.mx-n6 {
  margin-right: -8.4rem !important
}

.mb-n6,
.my-n6 {
  margin-bottom: -8.4rem !important
}

.ml-n6,
.mx-n6 {
  margin-left: -8.4rem !important
}

.m-n7 {
  margin: -9.8rem !important
}

.mt-n7,
.my-n7 {
  margin-top: -9.8rem !important
}

.mr-n7,
.mx-n7 {
  margin-right: -9.8rem !important
}

.mb-n7,
.my-n7 {
  margin-bottom: -9.8rem !important
}

.ml-n7,
.mx-n7 {
  margin-left: -9.8rem !important
}

.m-n8 {
  margin: -11.2rem !important
}

.mt-n8,
.my-n8 {
  margin-top: -11.2rem !important
}

.mr-n8,
.mx-n8 {
  margin-right: -11.2rem !important
}

.mb-n8,
.my-n8 {
  margin-bottom: -11.2rem !important
}

.ml-n8,
.mx-n8 {
  margin-left: -11.2rem !important
}

.m-n9 {
  margin: -12.6rem !important
}

.mt-n9,
.my-n9 {
  margin-top: -12.6rem !important
}

.mr-n9,
.mx-n9 {
  margin-right: -12.6rem !important
}

.mb-n9,
.my-n9 {
  margin-bottom: -12.6rem !important
}

.ml-n9,
.mx-n9 {
  margin-left: -12.6rem !important
}

.m-n10 {
  margin: -14rem !important
}

.mt-n10,
.my-n10 {
  margin-top: -14rem !important
}

.mr-n10,
.mx-n10 {
  margin-right: -14rem !important
}

.mb-n10,
.my-n10 {
  margin-bottom: -14rem !important
}

.ml-n10,
.mx-n10 {
  margin-left: -14rem !important
}

.m-auto {
  margin: auto !important
}

.mt-auto,
.my-auto {
  margin-top: auto !important
}

.mr-auto,
.mx-auto {
  margin-right: auto !important
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important
}

.ml-auto,
.mx-auto {
  margin-left: auto !important
}

@media (min-width: 480px) {
  .m-sm-0 {
    margin: 0 !important
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important
  }

  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important
  }

  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important
  }

  .m-sm-1\/12 {
    margin: .1166666667rem !important
  }

  .mt-sm-1\/12,
  .my-sm-1\/12 {
    margin-top: .1166666667rem !important
  }

  .mr-sm-1\/12,
  .mx-sm-1\/12 {
    margin-right: .1166666667rem !important
  }

  .mb-sm-1\/12,
  .my-sm-1\/12 {
    margin-bottom: .1166666667rem !important
  }

  .ml-sm-1\/12,
  .mx-sm-1\/12 {
    margin-left: .1166666667rem !important
  }

  .m-sm-0\.1 {
    margin: .14rem !important
  }

  .mt-sm-0\.1,
  .my-sm-0\.1 {
    margin-top: .14rem !important
  }

  .mr-sm-0\.1,
  .mx-sm-0\.1 {
    margin-right: .14rem !important
  }

  .mb-sm-0\.1,
  .my-sm-0\.1 {
    margin-bottom: .14rem !important
  }

  .ml-sm-0\.1,
  .mx-sm-0\.1 {
    margin-left: .14rem !important
  }

  .m-sm-0\.125 {
    margin: .175rem !important
  }

  .mt-sm-0\.125,
  .my-sm-0\.125 {
    margin-top: .175rem !important
  }

  .mr-sm-0\.125,
  .mx-sm-0\.125 {
    margin-right: .175rem !important
  }

  .mb-sm-0\.125,
  .my-sm-0\.125 {
    margin-bottom: .175rem !important
  }

  .ml-sm-0\.125,
  .mx-sm-0\.125 {
    margin-left: .175rem !important
  }

  .m-sm-1\/6 {
    margin: .2333333333rem !important
  }

  .mt-sm-1\/6,
  .my-sm-1\/6 {
    margin-top: .2333333333rem !important
  }

  .mr-sm-1\/6,
  .mx-sm-1\/6 {
    margin-right: .2333333333rem !important
  }

  .mb-sm-1\/6,
  .my-sm-1\/6 {
    margin-bottom: .2333333333rem !important
  }

  .ml-sm-1\/6,
  .mx-sm-1\/6 {
    margin-left: .2333333333rem !important
  }

  .m-sm-0\.2 {
    margin: .28rem !important
  }

  .mt-sm-0\.2,
  .my-sm-0\.2 {
    margin-top: .28rem !important
  }

  .mr-sm-0\.2,
  .mx-sm-0\.2 {
    margin-right: .28rem !important
  }

  .mb-sm-0\.2,
  .my-sm-0\.2 {
    margin-bottom: .28rem !important
  }

  .ml-sm-0\.2,
  .mx-sm-0\.2 {
    margin-left: .28rem !important
  }

  .m-sm-0\.25 {
    margin: .35rem !important
  }

  .mt-sm-0\.25,
  .my-sm-0\.25 {
    margin-top: .35rem !important
  }

  .mr-sm-0\.25,
  .mx-sm-0\.25 {
    margin-right: .35rem !important
  }

  .mb-sm-0\.25,
  .my-sm-0\.25 {
    margin-bottom: .35rem !important
  }

  .ml-sm-0\.25,
  .mx-sm-0\.25 {
    margin-left: .35rem !important
  }

  .m-sm-1\/3 {
    margin: .4666666667rem !important
  }

  .mt-sm-1\/3,
  .my-sm-1\/3 {
    margin-top: .4666666667rem !important
  }

  .mr-sm-1\/3,
  .mx-sm-1\/3 {
    margin-right: .4666666667rem !important
  }

  .mb-sm-1\/3,
  .my-sm-1\/3 {
    margin-bottom: .4666666667rem !important
  }

  .ml-sm-1\/3,
  .mx-sm-1\/3 {
    margin-left: .4666666667rem !important
  }

  .m-sm-5\/12 {
    margin: .5833333333rem !important
  }

  .mt-sm-5\/12,
  .my-sm-5\/12 {
    margin-top: .5833333333rem !important
  }

  .mr-sm-5\/12,
  .mx-sm-5\/12 {
    margin-right: .5833333333rem !important
  }

  .mb-sm-5\/12,
  .my-sm-5\/12 {
    margin-bottom: .5833333333rem !important
  }

  .ml-sm-5\/12,
  .mx-sm-5\/12 {
    margin-left: .5833333333rem !important
  }

  .m-sm-0\.5 {
    margin: .7rem !important
  }

  .mt-sm-0\.5,
  .my-sm-0\.5 {
    margin-top: .7rem !important
  }

  .mr-sm-0\.5,
  .mx-sm-0\.5 {
    margin-right: .7rem !important
  }

  .mb-sm-0\.5,
  .my-sm-0\.5 {
    margin-bottom: .7rem !important
  }

  .ml-sm-0\.5,
  .mx-sm-0\.5 {
    margin-left: .7rem !important
  }

  .m-sm-7\/12 {
    margin: .8166666667rem !important
  }

  .mt-sm-7\/12,
  .my-sm-7\/12 {
    margin-top: .8166666667rem !important
  }

  .mr-sm-7\/12,
  .mx-sm-7\/12 {
    margin-right: .8166666667rem !important
  }

  .mb-sm-7\/12,
  .my-sm-7\/12 {
    margin-bottom: .8166666667rem !important
  }

  .ml-sm-7\/12,
  .mx-sm-7\/12 {
    margin-left: .8166666667rem !important
  }

  .m-sm-2\/3 {
    margin: .9333333333rem !important
  }

  .mt-sm-2\/3,
  .my-sm-2\/3 {
    margin-top: .9333333333rem !important
  }

  .mr-sm-2\/3,
  .mx-sm-2\/3 {
    margin-right: .9333333333rem !important
  }

  .mb-sm-2\/3,
  .my-sm-2\/3 {
    margin-bottom: .9333333333rem !important
  }

  .ml-sm-2\/3,
  .mx-sm-2\/3 {
    margin-left: .9333333333rem !important
  }

  .m-sm-0\.75 {
    margin: 1.05rem !important
  }

  .mt-sm-0\.75,
  .my-sm-0\.75 {
    margin-top: 1.05rem !important
  }

  .mr-sm-0\.75,
  .mx-sm-0\.75 {
    margin-right: 1.05rem !important
  }

  .mb-sm-0\.75,
  .my-sm-0\.75 {
    margin-bottom: 1.05rem !important
  }

  .ml-sm-0\.75,
  .mx-sm-0\.75 {
    margin-left: 1.05rem !important
  }

  .m-sm-10\/12 {
    margin: 1.1666666667rem !important
  }

  .mt-sm-10\/12,
  .my-sm-10\/12 {
    margin-top: 1.1666666667rem !important
  }

  .mr-sm-10\/12,
  .mx-sm-10\/12 {
    margin-right: 1.1666666667rem !important
  }

  .mb-sm-10\/12,
  .my-sm-10\/12 {
    margin-bottom: 1.1666666667rem !important
  }

  .ml-sm-10\/12,
  .mx-sm-10\/12 {
    margin-left: 1.1666666667rem !important
  }

  .m-sm-11\/12 {
    margin: 1.2833333333rem !important
  }

  .mt-sm-11\/12,
  .my-sm-11\/12 {
    margin-top: 1.2833333333rem !important
  }

  .mr-sm-11\/12,
  .mx-sm-11\/12 {
    margin-right: 1.2833333333rem !important
  }

  .mb-sm-11\/12,
  .my-sm-11\/12 {
    margin-bottom: 1.2833333333rem !important
  }

  .ml-sm-11\/12,
  .mx-sm-11\/12 {
    margin-left: 1.2833333333rem !important
  }

  .m-sm-1 {
    margin: 1.4rem !important
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1.4rem !important
  }

  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1.4rem !important
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1.4rem !important
  }

  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1.4rem !important
  }

  .m-sm-1\.25 {
    margin: 1.75rem !important
  }

  .mt-sm-1\.25,
  .my-sm-1\.25 {
    margin-top: 1.75rem !important
  }

  .mr-sm-1\.25,
  .mx-sm-1\.25 {
    margin-right: 1.75rem !important
  }

  .mb-sm-1\.25,
  .my-sm-1\.25 {
    margin-bottom: 1.75rem !important
  }

  .ml-sm-1\.25,
  .mx-sm-1\.25 {
    margin-left: 1.75rem !important
  }

  .m-sm-1\.5 {
    margin: 2.1rem !important
  }

  .mt-sm-1\.5,
  .my-sm-1\.5 {
    margin-top: 2.1rem !important
  }

  .mr-sm-1\.5,
  .mx-sm-1\.5 {
    margin-right: 2.1rem !important
  }

  .mb-sm-1\.5,
  .my-sm-1\.5 {
    margin-bottom: 2.1rem !important
  }

  .ml-sm-1\.5,
  .mx-sm-1\.5 {
    margin-left: 2.1rem !important
  }

  .m-sm-1\.75 {
    margin: 2.45rem !important
  }

  .mt-sm-1\.75,
  .my-sm-1\.75 {
    margin-top: 2.45rem !important
  }

  .mr-sm-1\.75,
  .mx-sm-1\.75 {
    margin-right: 2.45rem !important
  }

  .mb-sm-1\.75,
  .my-sm-1\.75 {
    margin-bottom: 2.45rem !important
  }

  .ml-sm-1\.75,
  .mx-sm-1\.75 {
    margin-left: 2.45rem !important
  }

  .m-sm-2 {
    margin: 2.8rem !important
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-top: 2.8rem !important
  }

  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 2.8rem !important
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 2.8rem !important
  }

  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 2.8rem !important
  }

  .m-sm-2\.25 {
    margin: 3.15rem !important
  }

  .mt-sm-2\.25,
  .my-sm-2\.25 {
    margin-top: 3.15rem !important
  }

  .mr-sm-2\.25,
  .mx-sm-2\.25 {
    margin-right: 3.15rem !important
  }

  .mb-sm-2\.25,
  .my-sm-2\.25 {
    margin-bottom: 3.15rem !important
  }

  .ml-sm-2\.25,
  .mx-sm-2\.25 {
    margin-left: 3.15rem !important
  }

  .m-sm-2\.5 {
    margin: 3.5rem !important
  }

  .mt-sm-2\.5,
  .my-sm-2\.5 {
    margin-top: 3.5rem !important
  }

  .mr-sm-2\.5,
  .mx-sm-2\.5 {
    margin-right: 3.5rem !important
  }

  .mb-sm-2\.5,
  .my-sm-2\.5 {
    margin-bottom: 3.5rem !important
  }

  .ml-sm-2\.5,
  .mx-sm-2\.5 {
    margin-left: 3.5rem !important
  }

  .m-sm-2\.75 {
    margin: 3.85rem !important
  }

  .mt-sm-2\.75,
  .my-sm-2\.75 {
    margin-top: 3.85rem !important
  }

  .mr-sm-2\.75,
  .mx-sm-2\.75 {
    margin-right: 3.85rem !important
  }

  .mb-sm-2\.75,
  .my-sm-2\.75 {
    margin-bottom: 3.85rem !important
  }

  .ml-sm-2\.75,
  .mx-sm-2\.75 {
    margin-left: 3.85rem !important
  }

  .m-sm-3 {
    margin: 4.2rem !important
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-top: 4.2rem !important
  }

  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 4.2rem !important
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 4.2rem !important
  }

  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 4.2rem !important
  }

  .m-sm-3\.5 {
    margin: 4.9rem !important
  }

  .mt-sm-3\.5,
  .my-sm-3\.5 {
    margin-top: 4.9rem !important
  }

  .mr-sm-3\.5,
  .mx-sm-3\.5 {
    margin-right: 4.9rem !important
  }

  .mb-sm-3\.5,
  .my-sm-3\.5 {
    margin-bottom: 4.9rem !important
  }

  .ml-sm-3\.5,
  .mx-sm-3\.5 {
    margin-left: 4.9rem !important
  }

  .m-sm-4 {
    margin: 5.6rem !important
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-top: 5.6rem !important
  }

  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 5.6rem !important
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 5.6rem !important
  }

  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 5.6rem !important
  }

  .m-sm-5 {
    margin: 7rem !important
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-top: 7rem !important
  }

  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 7rem !important
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 7rem !important
  }

  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 7rem !important
  }

  .m-sm-6 {
    margin: 8.4rem !important
  }

  .mt-sm-6,
  .my-sm-6 {
    margin-top: 8.4rem !important
  }

  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 8.4rem !important
  }

  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 8.4rem !important
  }

  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 8.4rem !important
  }

  .m-sm-7 {
    margin: 9.8rem !important
  }

  .mt-sm-7,
  .my-sm-7 {
    margin-top: 9.8rem !important
  }

  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 9.8rem !important
  }

  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 9.8rem !important
  }

  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 9.8rem !important
  }

  .m-sm-8 {
    margin: 11.2rem !important
  }

  .mt-sm-8,
  .my-sm-8 {
    margin-top: 11.2rem !important
  }

  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 11.2rem !important
  }

  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 11.2rem !important
  }

  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 11.2rem !important
  }

  .m-sm-9 {
    margin: 12.6rem !important
  }

  .mt-sm-9,
  .my-sm-9 {
    margin-top: 12.6rem !important
  }

  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 12.6rem !important
  }

  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 12.6rem !important
  }

  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 12.6rem !important
  }

  .m-sm-10 {
    margin: 14rem !important
  }

  .mt-sm-10,
  .my-sm-10 {
    margin-top: 14rem !important
  }

  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 14rem !important
  }

  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 14rem !important
  }

  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 14rem !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important
  }

  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important
  }

  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important
  }

  .p-sm-1\/12 {
    padding: .1166666667rem !important
  }

  .pt-sm-1\/12,
  .py-sm-1\/12 {
    padding-top: .1166666667rem !important
  }

  .pr-sm-1\/12,
  .px-sm-1\/12 {
    padding-right: .1166666667rem !important
  }

  .pb-sm-1\/12,
  .py-sm-1\/12 {
    padding-bottom: .1166666667rem !important
  }

  .pl-sm-1\/12,
  .px-sm-1\/12 {
    padding-left: .1166666667rem !important
  }

  .p-sm-0\.1 {
    padding: .14rem !important
  }

  .pt-sm-0\.1,
  .py-sm-0\.1 {
    padding-top: .14rem !important
  }

  .pr-sm-0\.1,
  .px-sm-0\.1 {
    padding-right: .14rem !important
  }

  .pb-sm-0\.1,
  .py-sm-0\.1 {
    padding-bottom: .14rem !important
  }

  .pl-sm-0\.1,
  .px-sm-0\.1 {
    padding-left: .14rem !important
  }

  .p-sm-0\.125 {
    padding: .175rem !important
  }

  .pt-sm-0\.125,
  .py-sm-0\.125 {
    padding-top: .175rem !important
  }

  .pr-sm-0\.125,
  .px-sm-0\.125 {
    padding-right: .175rem !important
  }

  .pb-sm-0\.125,
  .py-sm-0\.125 {
    padding-bottom: .175rem !important
  }

  .pl-sm-0\.125,
  .px-sm-0\.125 {
    padding-left: .175rem !important
  }

  .p-sm-1\/6 {
    padding: .2333333333rem !important
  }

  .pt-sm-1\/6,
  .py-sm-1\/6 {
    padding-top: .2333333333rem !important
  }

  .pr-sm-1\/6,
  .px-sm-1\/6 {
    padding-right: .2333333333rem !important
  }

  .pb-sm-1\/6,
  .py-sm-1\/6 {
    padding-bottom: .2333333333rem !important
  }

  .pl-sm-1\/6,
  .px-sm-1\/6 {
    padding-left: .2333333333rem !important
  }

  .p-sm-0\.2 {
    padding: .28rem !important
  }

  .pt-sm-0\.2,
  .py-sm-0\.2 {
    padding-top: .28rem !important
  }

  .pr-sm-0\.2,
  .px-sm-0\.2 {
    padding-right: .28rem !important
  }

  .pb-sm-0\.2,
  .py-sm-0\.2 {
    padding-bottom: .28rem !important
  }

  .pl-sm-0\.2,
  .px-sm-0\.2 {
    padding-left: .28rem !important
  }

  .p-sm-0\.25 {
    padding: .35rem !important
  }

  .pt-sm-0\.25,
  .py-sm-0\.25 {
    padding-top: .35rem !important
  }

  .pr-sm-0\.25,
  .px-sm-0\.25 {
    padding-right: .35rem !important
  }

  .pb-sm-0\.25,
  .py-sm-0\.25 {
    padding-bottom: .35rem !important
  }

  .pl-sm-0\.25,
  .px-sm-0\.25 {
    padding-left: .35rem !important
  }

  .p-sm-1\/3 {
    padding: .4666666667rem !important
  }

  .pt-sm-1\/3,
  .py-sm-1\/3 {
    padding-top: .4666666667rem !important
  }

  .pr-sm-1\/3,
  .px-sm-1\/3 {
    padding-right: .4666666667rem !important
  }

  .pb-sm-1\/3,
  .py-sm-1\/3 {
    padding-bottom: .4666666667rem !important
  }

  .pl-sm-1\/3,
  .px-sm-1\/3 {
    padding-left: .4666666667rem !important
  }

  .p-sm-5\/12 {
    padding: .5833333333rem !important
  }

  .pt-sm-5\/12,
  .py-sm-5\/12 {
    padding-top: .5833333333rem !important
  }

  .pr-sm-5\/12,
  .px-sm-5\/12 {
    padding-right: .5833333333rem !important
  }

  .pb-sm-5\/12,
  .py-sm-5\/12 {
    padding-bottom: .5833333333rem !important
  }

  .pl-sm-5\/12,
  .px-sm-5\/12 {
    padding-left: .5833333333rem !important
  }

  .p-sm-0\.5 {
    padding: .7rem !important
  }

  .pt-sm-0\.5,
  .py-sm-0\.5 {
    padding-top: .7rem !important
  }

  .pr-sm-0\.5,
  .px-sm-0\.5 {
    padding-right: .7rem !important
  }

  .pb-sm-0\.5,
  .py-sm-0\.5 {
    padding-bottom: .7rem !important
  }

  .pl-sm-0\.5,
  .px-sm-0\.5 {
    padding-left: .7rem !important
  }

  .p-sm-7\/12 {
    padding: .8166666667rem !important
  }

  .pt-sm-7\/12,
  .py-sm-7\/12 {
    padding-top: .8166666667rem !important
  }

  .pr-sm-7\/12,
  .px-sm-7\/12 {
    padding-right: .8166666667rem !important
  }

  .pb-sm-7\/12,
  .py-sm-7\/12 {
    padding-bottom: .8166666667rem !important
  }

  .pl-sm-7\/12,
  .px-sm-7\/12 {
    padding-left: .8166666667rem !important
  }

  .p-sm-2\/3 {
    padding: .9333333333rem !important
  }

  .pt-sm-2\/3,
  .py-sm-2\/3 {
    padding-top: .9333333333rem !important
  }

  .pr-sm-2\/3,
  .px-sm-2\/3 {
    padding-right: .9333333333rem !important
  }

  .pb-sm-2\/3,
  .py-sm-2\/3 {
    padding-bottom: .9333333333rem !important
  }

  .pl-sm-2\/3,
  .px-sm-2\/3 {
    padding-left: .9333333333rem !important
  }

  .p-sm-0\.75 {
    padding: 1.05rem !important
  }

  .pt-sm-0\.75,
  .py-sm-0\.75 {
    padding-top: 1.05rem !important
  }

  .pr-sm-0\.75,
  .px-sm-0\.75 {
    padding-right: 1.05rem !important
  }

  .pb-sm-0\.75,
  .py-sm-0\.75 {
    padding-bottom: 1.05rem !important
  }

  .pl-sm-0\.75,
  .px-sm-0\.75 {
    padding-left: 1.05rem !important
  }

  .p-sm-10\/12 {
    padding: 1.1666666667rem !important
  }

  .pt-sm-10\/12,
  .py-sm-10\/12 {
    padding-top: 1.1666666667rem !important
  }

  .pr-sm-10\/12,
  .px-sm-10\/12 {
    padding-right: 1.1666666667rem !important
  }

  .pb-sm-10\/12,
  .py-sm-10\/12 {
    padding-bottom: 1.1666666667rem !important
  }

  .pl-sm-10\/12,
  .px-sm-10\/12 {
    padding-left: 1.1666666667rem !important
  }

  .p-sm-11\/12 {
    padding: 1.2833333333rem !important
  }

  .pt-sm-11\/12,
  .py-sm-11\/12 {
    padding-top: 1.2833333333rem !important
  }

  .pr-sm-11\/12,
  .px-sm-11\/12 {
    padding-right: 1.2833333333rem !important
  }

  .pb-sm-11\/12,
  .py-sm-11\/12 {
    padding-bottom: 1.2833333333rem !important
  }

  .pl-sm-11\/12,
  .px-sm-11\/12 {
    padding-left: 1.2833333333rem !important
  }

  .p-sm-1 {
    padding: 1.4rem !important
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1.4rem !important
  }

  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1.4rem !important
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1.4rem !important
  }

  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1.4rem !important
  }

  .p-sm-1\.25 {
    padding: 1.75rem !important
  }

  .pt-sm-1\.25,
  .py-sm-1\.25 {
    padding-top: 1.75rem !important
  }

  .pr-sm-1\.25,
  .px-sm-1\.25 {
    padding-right: 1.75rem !important
  }

  .pb-sm-1\.25,
  .py-sm-1\.25 {
    padding-bottom: 1.75rem !important
  }

  .pl-sm-1\.25,
  .px-sm-1\.25 {
    padding-left: 1.75rem !important
  }

  .p-sm-1\.5 {
    padding: 2.1rem !important
  }

  .pt-sm-1\.5,
  .py-sm-1\.5 {
    padding-top: 2.1rem !important
  }

  .pr-sm-1\.5,
  .px-sm-1\.5 {
    padding-right: 2.1rem !important
  }

  .pb-sm-1\.5,
  .py-sm-1\.5 {
    padding-bottom: 2.1rem !important
  }

  .pl-sm-1\.5,
  .px-sm-1\.5 {
    padding-left: 2.1rem !important
  }

  .p-sm-1\.75 {
    padding: 2.45rem !important
  }

  .pt-sm-1\.75,
  .py-sm-1\.75 {
    padding-top: 2.45rem !important
  }

  .pr-sm-1\.75,
  .px-sm-1\.75 {
    padding-right: 2.45rem !important
  }

  .pb-sm-1\.75,
  .py-sm-1\.75 {
    padding-bottom: 2.45rem !important
  }

  .pl-sm-1\.75,
  .px-sm-1\.75 {
    padding-left: 2.45rem !important
  }

  .p-sm-2 {
    padding: 2.8rem !important
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-top: 2.8rem !important
  }

  .pr-sm-2,
  .px-sm-2 {
    padding-right: 2.8rem !important
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 2.8rem !important
  }

  .pl-sm-2,
  .px-sm-2 {
    padding-left: 2.8rem !important
  }

  .p-sm-2\.25 {
    padding: 3.15rem !important
  }

  .pt-sm-2\.25,
  .py-sm-2\.25 {
    padding-top: 3.15rem !important
  }

  .pr-sm-2\.25,
  .px-sm-2\.25 {
    padding-right: 3.15rem !important
  }

  .pb-sm-2\.25,
  .py-sm-2\.25 {
    padding-bottom: 3.15rem !important
  }

  .pl-sm-2\.25,
  .px-sm-2\.25 {
    padding-left: 3.15rem !important
  }

  .p-sm-2\.5 {
    padding: 3.5rem !important
  }

  .pt-sm-2\.5,
  .py-sm-2\.5 {
    padding-top: 3.5rem !important
  }

  .pr-sm-2\.5,
  .px-sm-2\.5 {
    padding-right: 3.5rem !important
  }

  .pb-sm-2\.5,
  .py-sm-2\.5 {
    padding-bottom: 3.5rem !important
  }

  .pl-sm-2\.5,
  .px-sm-2\.5 {
    padding-left: 3.5rem !important
  }

  .p-sm-2\.75 {
    padding: 3.85rem !important
  }

  .pt-sm-2\.75,
  .py-sm-2\.75 {
    padding-top: 3.85rem !important
  }

  .pr-sm-2\.75,
  .px-sm-2\.75 {
    padding-right: 3.85rem !important
  }

  .pb-sm-2\.75,
  .py-sm-2\.75 {
    padding-bottom: 3.85rem !important
  }

  .pl-sm-2\.75,
  .px-sm-2\.75 {
    padding-left: 3.85rem !important
  }

  .p-sm-3 {
    padding: 4.2rem !important
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-top: 4.2rem !important
  }

  .pr-sm-3,
  .px-sm-3 {
    padding-right: 4.2rem !important
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 4.2rem !important
  }

  .pl-sm-3,
  .px-sm-3 {
    padding-left: 4.2rem !important
  }

  .p-sm-3\.5 {
    padding: 4.9rem !important
  }

  .pt-sm-3\.5,
  .py-sm-3\.5 {
    padding-top: 4.9rem !important
  }

  .pr-sm-3\.5,
  .px-sm-3\.5 {
    padding-right: 4.9rem !important
  }

  .pb-sm-3\.5,
  .py-sm-3\.5 {
    padding-bottom: 4.9rem !important
  }

  .pl-sm-3\.5,
  .px-sm-3\.5 {
    padding-left: 4.9rem !important
  }

  .p-sm-4 {
    padding: 5.6rem !important
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-top: 5.6rem !important
  }

  .pr-sm-4,
  .px-sm-4 {
    padding-right: 5.6rem !important
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 5.6rem !important
  }

  .pl-sm-4,
  .px-sm-4 {
    padding-left: 5.6rem !important
  }

  .p-sm-5 {
    padding: 7rem !important
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-top: 7rem !important
  }

  .pr-sm-5,
  .px-sm-5 {
    padding-right: 7rem !important
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 7rem !important
  }

  .pl-sm-5,
  .px-sm-5 {
    padding-left: 7rem !important
  }

  .p-sm-6 {
    padding: 8.4rem !important
  }

  .pt-sm-6,
  .py-sm-6 {
    padding-top: 8.4rem !important
  }

  .pr-sm-6,
  .px-sm-6 {
    padding-right: 8.4rem !important
  }

  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 8.4rem !important
  }

  .pl-sm-6,
  .px-sm-6 {
    padding-left: 8.4rem !important
  }

  .p-sm-7 {
    padding: 9.8rem !important
  }

  .pt-sm-7,
  .py-sm-7 {
    padding-top: 9.8rem !important
  }

  .pr-sm-7,
  .px-sm-7 {
    padding-right: 9.8rem !important
  }

  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 9.8rem !important
  }

  .pl-sm-7,
  .px-sm-7 {
    padding-left: 9.8rem !important
  }

  .p-sm-8 {
    padding: 11.2rem !important
  }

  .pt-sm-8,
  .py-sm-8 {
    padding-top: 11.2rem !important
  }

  .pr-sm-8,
  .px-sm-8 {
    padding-right: 11.2rem !important
  }

  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 11.2rem !important
  }

  .pl-sm-8,
  .px-sm-8 {
    padding-left: 11.2rem !important
  }

  .p-sm-9 {
    padding: 12.6rem !important
  }

  .pt-sm-9,
  .py-sm-9 {
    padding-top: 12.6rem !important
  }

  .pr-sm-9,
  .px-sm-9 {
    padding-right: 12.6rem !important
  }

  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 12.6rem !important
  }

  .pl-sm-9,
  .px-sm-9 {
    padding-left: 12.6rem !important
  }

  .p-sm-10 {
    padding: 14rem !important
  }

  .pt-sm-10,
  .py-sm-10 {
    padding-top: 14rem !important
  }

  .pr-sm-10,
  .px-sm-10 {
    padding-right: 14rem !important
  }

  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 14rem !important
  }

  .pl-sm-10,
  .px-sm-10 {
    padding-left: 14rem !important
  }

  .m-sm-n1\/12 {
    margin: -.1166666667rem !important
  }

  .mt-sm-n1\/12,
  .my-sm-n1\/12 {
    margin-top: -.1166666667rem !important
  }

  .mr-sm-n1\/12,
  .mx-sm-n1\/12 {
    margin-right: -.1166666667rem !important
  }

  .mb-sm-n1\/12,
  .my-sm-n1\/12 {
    margin-bottom: -.1166666667rem !important
  }

  .ml-sm-n1\/12,
  .mx-sm-n1\/12 {
    margin-left: -.1166666667rem !important
  }

  .m-sm-n0\.1 {
    margin: -.14rem !important
  }

  .mt-sm-n0\.1,
  .my-sm-n0\.1 {
    margin-top: -.14rem !important
  }

  .mr-sm-n0\.1,
  .mx-sm-n0\.1 {
    margin-right: -.14rem !important
  }

  .mb-sm-n0\.1,
  .my-sm-n0\.1 {
    margin-bottom: -.14rem !important
  }

  .ml-sm-n0\.1,
  .mx-sm-n0\.1 {
    margin-left: -.14rem !important
  }

  .m-sm-n0\.125 {
    margin: -.175rem !important
  }

  .mt-sm-n0\.125,
  .my-sm-n0\.125 {
    margin-top: -.175rem !important
  }

  .mr-sm-n0\.125,
  .mx-sm-n0\.125 {
    margin-right: -.175rem !important
  }

  .mb-sm-n0\.125,
  .my-sm-n0\.125 {
    margin-bottom: -.175rem !important
  }

  .ml-sm-n0\.125,
  .mx-sm-n0\.125 {
    margin-left: -.175rem !important
  }

  .m-sm-n1\/6 {
    margin: -.2333333333rem !important
  }

  .mt-sm-n1\/6,
  .my-sm-n1\/6 {
    margin-top: -.2333333333rem !important
  }

  .mr-sm-n1\/6,
  .mx-sm-n1\/6 {
    margin-right: -.2333333333rem !important
  }

  .mb-sm-n1\/6,
  .my-sm-n1\/6 {
    margin-bottom: -.2333333333rem !important
  }

  .ml-sm-n1\/6,
  .mx-sm-n1\/6 {
    margin-left: -.2333333333rem !important
  }

  .m-sm-n0\.2 {
    margin: -.28rem !important
  }

  .mt-sm-n0\.2,
  .my-sm-n0\.2 {
    margin-top: -.28rem !important
  }

  .mr-sm-n0\.2,
  .mx-sm-n0\.2 {
    margin-right: -.28rem !important
  }

  .mb-sm-n0\.2,
  .my-sm-n0\.2 {
    margin-bottom: -.28rem !important
  }

  .ml-sm-n0\.2,
  .mx-sm-n0\.2 {
    margin-left: -.28rem !important
  }

  .m-sm-n0\.25 {
    margin: -.35rem !important
  }

  .mt-sm-n0\.25,
  .my-sm-n0\.25 {
    margin-top: -.35rem !important
  }

  .mr-sm-n0\.25,
  .mx-sm-n0\.25 {
    margin-right: -.35rem !important
  }

  .mb-sm-n0\.25,
  .my-sm-n0\.25 {
    margin-bottom: -.35rem !important
  }

  .ml-sm-n0\.25,
  .mx-sm-n0\.25 {
    margin-left: -.35rem !important
  }

  .m-sm-n1\/3 {
    margin: -.4666666667rem !important
  }

  .mt-sm-n1\/3,
  .my-sm-n1\/3 {
    margin-top: -.4666666667rem !important
  }

  .mr-sm-n1\/3,
  .mx-sm-n1\/3 {
    margin-right: -.4666666667rem !important
  }

  .mb-sm-n1\/3,
  .my-sm-n1\/3 {
    margin-bottom: -.4666666667rem !important
  }

  .ml-sm-n1\/3,
  .mx-sm-n1\/3 {
    margin-left: -.4666666667rem !important
  }

  .m-sm-n5\/12 {
    margin: -.5833333333rem !important
  }

  .mt-sm-n5\/12,
  .my-sm-n5\/12 {
    margin-top: -.5833333333rem !important
  }

  .mr-sm-n5\/12,
  .mx-sm-n5\/12 {
    margin-right: -.5833333333rem !important
  }

  .mb-sm-n5\/12,
  .my-sm-n5\/12 {
    margin-bottom: -.5833333333rem !important
  }

  .ml-sm-n5\/12,
  .mx-sm-n5\/12 {
    margin-left: -.5833333333rem !important
  }

  .m-sm-n0\.5 {
    margin: -.7rem !important
  }

  .mt-sm-n0\.5,
  .my-sm-n0\.5 {
    margin-top: -.7rem !important
  }

  .mr-sm-n0\.5,
  .mx-sm-n0\.5 {
    margin-right: -.7rem !important
  }

  .mb-sm-n0\.5,
  .my-sm-n0\.5 {
    margin-bottom: -.7rem !important
  }

  .ml-sm-n0\.5,
  .mx-sm-n0\.5 {
    margin-left: -.7rem !important
  }

  .m-sm-n7\/12 {
    margin: -.8166666667rem !important
  }

  .mt-sm-n7\/12,
  .my-sm-n7\/12 {
    margin-top: -.8166666667rem !important
  }

  .mr-sm-n7\/12,
  .mx-sm-n7\/12 {
    margin-right: -.8166666667rem !important
  }

  .mb-sm-n7\/12,
  .my-sm-n7\/12 {
    margin-bottom: -.8166666667rem !important
  }

  .ml-sm-n7\/12,
  .mx-sm-n7\/12 {
    margin-left: -.8166666667rem !important
  }

  .m-sm-n2\/3 {
    margin: -.9333333333rem !important
  }

  .mt-sm-n2\/3,
  .my-sm-n2\/3 {
    margin-top: -.9333333333rem !important
  }

  .mr-sm-n2\/3,
  .mx-sm-n2\/3 {
    margin-right: -.9333333333rem !important
  }

  .mb-sm-n2\/3,
  .my-sm-n2\/3 {
    margin-bottom: -.9333333333rem !important
  }

  .ml-sm-n2\/3,
  .mx-sm-n2\/3 {
    margin-left: -.9333333333rem !important
  }

  .m-sm-n0\.75 {
    margin: -1.05rem !important
  }

  .mt-sm-n0\.75,
  .my-sm-n0\.75 {
    margin-top: -1.05rem !important
  }

  .mr-sm-n0\.75,
  .mx-sm-n0\.75 {
    margin-right: -1.05rem !important
  }

  .mb-sm-n0\.75,
  .my-sm-n0\.75 {
    margin-bottom: -1.05rem !important
  }

  .ml-sm-n0\.75,
  .mx-sm-n0\.75 {
    margin-left: -1.05rem !important
  }

  .m-sm-n10\/12 {
    margin: -1.1666666667rem !important
  }

  .mt-sm-n10\/12,
  .my-sm-n10\/12 {
    margin-top: -1.1666666667rem !important
  }

  .mr-sm-n10\/12,
  .mx-sm-n10\/12 {
    margin-right: -1.1666666667rem !important
  }

  .mb-sm-n10\/12,
  .my-sm-n10\/12 {
    margin-bottom: -1.1666666667rem !important
  }

  .ml-sm-n10\/12,
  .mx-sm-n10\/12 {
    margin-left: -1.1666666667rem !important
  }

  .m-sm-n11\/12 {
    margin: -1.2833333333rem !important
  }

  .mt-sm-n11\/12,
  .my-sm-n11\/12 {
    margin-top: -1.2833333333rem !important
  }

  .mr-sm-n11\/12,
  .mx-sm-n11\/12 {
    margin-right: -1.2833333333rem !important
  }

  .mb-sm-n11\/12,
  .my-sm-n11\/12 {
    margin-bottom: -1.2833333333rem !important
  }

  .ml-sm-n11\/12,
  .mx-sm-n11\/12 {
    margin-left: -1.2833333333rem !important
  }

  .m-sm-n1 {
    margin: -1.4rem !important
  }

  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -1.4rem !important
  }

  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -1.4rem !important
  }

  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -1.4rem !important
  }

  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -1.4rem !important
  }

  .m-sm-n1\.25 {
    margin: -1.75rem !important
  }

  .mt-sm-n1\.25,
  .my-sm-n1\.25 {
    margin-top: -1.75rem !important
  }

  .mr-sm-n1\.25,
  .mx-sm-n1\.25 {
    margin-right: -1.75rem !important
  }

  .mb-sm-n1\.25,
  .my-sm-n1\.25 {
    margin-bottom: -1.75rem !important
  }

  .ml-sm-n1\.25,
  .mx-sm-n1\.25 {
    margin-left: -1.75rem !important
  }

  .m-sm-n1\.5 {
    margin: -2.1rem !important
  }

  .mt-sm-n1\.5,
  .my-sm-n1\.5 {
    margin-top: -2.1rem !important
  }

  .mr-sm-n1\.5,
  .mx-sm-n1\.5 {
    margin-right: -2.1rem !important
  }

  .mb-sm-n1\.5,
  .my-sm-n1\.5 {
    margin-bottom: -2.1rem !important
  }

  .ml-sm-n1\.5,
  .mx-sm-n1\.5 {
    margin-left: -2.1rem !important
  }

  .m-sm-n1\.75 {
    margin: -2.45rem !important
  }

  .mt-sm-n1\.75,
  .my-sm-n1\.75 {
    margin-top: -2.45rem !important
  }

  .mr-sm-n1\.75,
  .mx-sm-n1\.75 {
    margin-right: -2.45rem !important
  }

  .mb-sm-n1\.75,
  .my-sm-n1\.75 {
    margin-bottom: -2.45rem !important
  }

  .ml-sm-n1\.75,
  .mx-sm-n1\.75 {
    margin-left: -2.45rem !important
  }

  .m-sm-n2 {
    margin: -2.8rem !important
  }

  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -2.8rem !important
  }

  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -2.8rem !important
  }

  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -2.8rem !important
  }

  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -2.8rem !important
  }

  .m-sm-n2\.25 {
    margin: -3.15rem !important
  }

  .mt-sm-n2\.25,
  .my-sm-n2\.25 {
    margin-top: -3.15rem !important
  }

  .mr-sm-n2\.25,
  .mx-sm-n2\.25 {
    margin-right: -3.15rem !important
  }

  .mb-sm-n2\.25,
  .my-sm-n2\.25 {
    margin-bottom: -3.15rem !important
  }

  .ml-sm-n2\.25,
  .mx-sm-n2\.25 {
    margin-left: -3.15rem !important
  }

  .m-sm-n2\.5 {
    margin: -3.5rem !important
  }

  .mt-sm-n2\.5,
  .my-sm-n2\.5 {
    margin-top: -3.5rem !important
  }

  .mr-sm-n2\.5,
  .mx-sm-n2\.5 {
    margin-right: -3.5rem !important
  }

  .mb-sm-n2\.5,
  .my-sm-n2\.5 {
    margin-bottom: -3.5rem !important
  }

  .ml-sm-n2\.5,
  .mx-sm-n2\.5 {
    margin-left: -3.5rem !important
  }

  .m-sm-n2\.75 {
    margin: -3.85rem !important
  }

  .mt-sm-n2\.75,
  .my-sm-n2\.75 {
    margin-top: -3.85rem !important
  }

  .mr-sm-n2\.75,
  .mx-sm-n2\.75 {
    margin-right: -3.85rem !important
  }

  .mb-sm-n2\.75,
  .my-sm-n2\.75 {
    margin-bottom: -3.85rem !important
  }

  .ml-sm-n2\.75,
  .mx-sm-n2\.75 {
    margin-left: -3.85rem !important
  }

  .m-sm-n3 {
    margin: -4.2rem !important
  }

  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -4.2rem !important
  }

  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -4.2rem !important
  }

  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -4.2rem !important
  }

  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -4.2rem !important
  }

  .m-sm-n3\.5 {
    margin: -4.9rem !important
  }

  .mt-sm-n3\.5,
  .my-sm-n3\.5 {
    margin-top: -4.9rem !important
  }

  .mr-sm-n3\.5,
  .mx-sm-n3\.5 {
    margin-right: -4.9rem !important
  }

  .mb-sm-n3\.5,
  .my-sm-n3\.5 {
    margin-bottom: -4.9rem !important
  }

  .ml-sm-n3\.5,
  .mx-sm-n3\.5 {
    margin-left: -4.9rem !important
  }

  .m-sm-n4 {
    margin: -5.6rem !important
  }

  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -5.6rem !important
  }

  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -5.6rem !important
  }

  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -5.6rem !important
  }

  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -5.6rem !important
  }

  .m-sm-n5 {
    margin: -7rem !important
  }

  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -7rem !important
  }

  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -7rem !important
  }

  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -7rem !important
  }

  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -7rem !important
  }

  .m-sm-n6 {
    margin: -8.4rem !important
  }

  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -8.4rem !important
  }

  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -8.4rem !important
  }

  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -8.4rem !important
  }

  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -8.4rem !important
  }

  .m-sm-n7 {
    margin: -9.8rem !important
  }

  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -9.8rem !important
  }

  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -9.8rem !important
  }

  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -9.8rem !important
  }

  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -9.8rem !important
  }

  .m-sm-n8 {
    margin: -11.2rem !important
  }

  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -11.2rem !important
  }

  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -11.2rem !important
  }

  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -11.2rem !important
  }

  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -11.2rem !important
  }

  .m-sm-n9 {
    margin: -12.6rem !important
  }

  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -12.6rem !important
  }

  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -12.6rem !important
  }

  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -12.6rem !important
  }

  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -12.6rem !important
  }

  .m-sm-n10 {
    margin: -14rem !important
  }

  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -14rem !important
  }

  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -14rem !important
  }

  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -14rem !important
  }

  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -14rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important
  }

  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important
  }

  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important
  }
}

@media (min-width: 720px) {
  .m-md-0 {
    margin: 0 !important
  }

  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important
  }

  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important
  }

  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important
  }

  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important
  }

  .m-md-1\/12 {
    margin: .1166666667rem !important
  }

  .mt-md-1\/12,
  .my-md-1\/12 {
    margin-top: .1166666667rem !important
  }

  .mr-md-1\/12,
  .mx-md-1\/12 {
    margin-right: .1166666667rem !important
  }

  .mb-md-1\/12,
  .my-md-1\/12 {
    margin-bottom: .1166666667rem !important
  }

  .ml-md-1\/12,
  .mx-md-1\/12 {
    margin-left: .1166666667rem !important
  }

  .m-md-0\.1 {
    margin: .14rem !important
  }

  .mt-md-0\.1,
  .my-md-0\.1 {
    margin-top: .14rem !important
  }

  .mr-md-0\.1,
  .mx-md-0\.1 {
    margin-right: .14rem !important
  }

  .mb-md-0\.1,
  .my-md-0\.1 {
    margin-bottom: .14rem !important
  }

  .ml-md-0\.1,
  .mx-md-0\.1 {
    margin-left: .14rem !important
  }

  .m-md-0\.125 {
    margin: .175rem !important
  }

  .mt-md-0\.125,
  .my-md-0\.125 {
    margin-top: .175rem !important
  }

  .mr-md-0\.125,
  .mx-md-0\.125 {
    margin-right: .175rem !important
  }

  .mb-md-0\.125,
  .my-md-0\.125 {
    margin-bottom: .175rem !important
  }

  .ml-md-0\.125,
  .mx-md-0\.125 {
    margin-left: .175rem !important
  }

  .m-md-1\/6 {
    margin: .2333333333rem !important
  }

  .mt-md-1\/6,
  .my-md-1\/6 {
    margin-top: .2333333333rem !important
  }

  .mr-md-1\/6,
  .mx-md-1\/6 {
    margin-right: .2333333333rem !important
  }

  .mb-md-1\/6,
  .my-md-1\/6 {
    margin-bottom: .2333333333rem !important
  }

  .ml-md-1\/6,
  .mx-md-1\/6 {
    margin-left: .2333333333rem !important
  }

  .m-md-0\.2 {
    margin: .28rem !important
  }

  .mt-md-0\.2,
  .my-md-0\.2 {
    margin-top: .28rem !important
  }

  .mr-md-0\.2,
  .mx-md-0\.2 {
    margin-right: .28rem !important
  }

  .mb-md-0\.2,
  .my-md-0\.2 {
    margin-bottom: .28rem !important
  }

  .ml-md-0\.2,
  .mx-md-0\.2 {
    margin-left: .28rem !important
  }

  .m-md-0\.25 {
    margin: .35rem !important
  }

  .mt-md-0\.25,
  .my-md-0\.25 {
    margin-top: .35rem !important
  }

  .mr-md-0\.25,
  .mx-md-0\.25 {
    margin-right: .35rem !important
  }

  .mb-md-0\.25,
  .my-md-0\.25 {
    margin-bottom: .35rem !important
  }

  .ml-md-0\.25,
  .mx-md-0\.25 {
    margin-left: .35rem !important
  }

  .m-md-1\/3 {
    margin: .4666666667rem !important
  }

  .mt-md-1\/3,
  .my-md-1\/3 {
    margin-top: .4666666667rem !important
  }

  .mr-md-1\/3,
  .mx-md-1\/3 {
    margin-right: .4666666667rem !important
  }

  .mb-md-1\/3,
  .my-md-1\/3 {
    margin-bottom: .4666666667rem !important
  }

  .ml-md-1\/3,
  .mx-md-1\/3 {
    margin-left: .4666666667rem !important
  }

  .m-md-5\/12 {
    margin: .5833333333rem !important
  }

  .mt-md-5\/12,
  .my-md-5\/12 {
    margin-top: .5833333333rem !important
  }

  .mr-md-5\/12,
  .mx-md-5\/12 {
    margin-right: .5833333333rem !important
  }

  .mb-md-5\/12,
  .my-md-5\/12 {
    margin-bottom: .5833333333rem !important
  }

  .ml-md-5\/12,
  .mx-md-5\/12 {
    margin-left: .5833333333rem !important
  }

  .m-md-0\.5 {
    margin: .7rem !important
  }

  .mt-md-0\.5,
  .my-md-0\.5 {
    margin-top: .7rem !important
  }

  .mr-md-0\.5,
  .mx-md-0\.5 {
    margin-right: .7rem !important
  }

  .mb-md-0\.5,
  .my-md-0\.5 {
    margin-bottom: .7rem !important
  }

  .ml-md-0\.5,
  .mx-md-0\.5 {
    margin-left: .7rem !important
  }

  .m-md-7\/12 {
    margin: .8166666667rem !important
  }

  .mt-md-7\/12,
  .my-md-7\/12 {
    margin-top: .8166666667rem !important
  }

  .mr-md-7\/12,
  .mx-md-7\/12 {
    margin-right: .8166666667rem !important
  }

  .mb-md-7\/12,
  .my-md-7\/12 {
    margin-bottom: .8166666667rem !important
  }

  .ml-md-7\/12,
  .mx-md-7\/12 {
    margin-left: .8166666667rem !important
  }

  .m-md-2\/3 {
    margin: .9333333333rem !important
  }

  .mt-md-2\/3,
  .my-md-2\/3 {
    margin-top: .9333333333rem !important
  }

  .mr-md-2\/3,
  .mx-md-2\/3 {
    margin-right: .9333333333rem !important
  }

  .mb-md-2\/3,
  .my-md-2\/3 {
    margin-bottom: .9333333333rem !important
  }

  .ml-md-2\/3,
  .mx-md-2\/3 {
    margin-left: .9333333333rem !important
  }

  .m-md-0\.75 {
    margin: 1.05rem !important
  }

  .mt-md-0\.75,
  .my-md-0\.75 {
    margin-top: 1.05rem !important
  }

  .mr-md-0\.75,
  .mx-md-0\.75 {
    margin-right: 1.05rem !important
  }

  .mb-md-0\.75,
  .my-md-0\.75 {
    margin-bottom: 1.05rem !important
  }

  .ml-md-0\.75,
  .mx-md-0\.75 {
    margin-left: 1.05rem !important
  }

  .m-md-10\/12 {
    margin: 1.1666666667rem !important
  }

  .mt-md-10\/12,
  .my-md-10\/12 {
    margin-top: 1.1666666667rem !important
  }

  .mr-md-10\/12,
  .mx-md-10\/12 {
    margin-right: 1.1666666667rem !important
  }

  .mb-md-10\/12,
  .my-md-10\/12 {
    margin-bottom: 1.1666666667rem !important
  }

  .ml-md-10\/12,
  .mx-md-10\/12 {
    margin-left: 1.1666666667rem !important
  }

  .m-md-11\/12 {
    margin: 1.2833333333rem !important
  }

  .mt-md-11\/12,
  .my-md-11\/12 {
    margin-top: 1.2833333333rem !important
  }

  .mr-md-11\/12,
  .mx-md-11\/12 {
    margin-right: 1.2833333333rem !important
  }

  .mb-md-11\/12,
  .my-md-11\/12 {
    margin-bottom: 1.2833333333rem !important
  }

  .ml-md-11\/12,
  .mx-md-11\/12 {
    margin-left: 1.2833333333rem !important
  }

  .m-md-1 {
    margin: 1.4rem !important
  }

  .mt-md-1,
  .my-md-1 {
    margin-top: 1.4rem !important
  }

  .mr-md-1,
  .mx-md-1 {
    margin-right: 1.4rem !important
  }

  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1.4rem !important
  }

  .ml-md-1,
  .mx-md-1 {
    margin-left: 1.4rem !important
  }

  .m-md-1\.25 {
    margin: 1.75rem !important
  }

  .mt-md-1\.25,
  .my-md-1\.25 {
    margin-top: 1.75rem !important
  }

  .mr-md-1\.25,
  .mx-md-1\.25 {
    margin-right: 1.75rem !important
  }

  .mb-md-1\.25,
  .my-md-1\.25 {
    margin-bottom: 1.75rem !important
  }

  .ml-md-1\.25,
  .mx-md-1\.25 {
    margin-left: 1.75rem !important
  }

  .m-md-1\.5 {
    margin: 2.1rem !important
  }

  .mt-md-1\.5,
  .my-md-1\.5 {
    margin-top: 2.1rem !important
  }

  .mr-md-1\.5,
  .mx-md-1\.5 {
    margin-right: 2.1rem !important
  }

  .mb-md-1\.5,
  .my-md-1\.5 {
    margin-bottom: 2.1rem !important
  }

  .ml-md-1\.5,
  .mx-md-1\.5 {
    margin-left: 2.1rem !important
  }

  .m-md-1\.75 {
    margin: 2.45rem !important
  }

  .mt-md-1\.75,
  .my-md-1\.75 {
    margin-top: 2.45rem !important
  }

  .mr-md-1\.75,
  .mx-md-1\.75 {
    margin-right: 2.45rem !important
  }

  .mb-md-1\.75,
  .my-md-1\.75 {
    margin-bottom: 2.45rem !important
  }

  .ml-md-1\.75,
  .mx-md-1\.75 {
    margin-left: 2.45rem !important
  }

  .m-md-2 {
    margin: 2.8rem !important
  }

  .mt-md-2,
  .my-md-2 {
    margin-top: 2.8rem !important
  }

  .mr-md-2,
  .mx-md-2 {
    margin-right: 2.8rem !important
  }

  .mb-md-2,
  .my-md-2 {
    margin-bottom: 2.8rem !important
  }

  .ml-md-2,
  .mx-md-2 {
    margin-left: 2.8rem !important
  }

  .m-md-2\.25 {
    margin: 3.15rem !important
  }

  .mt-md-2\.25,
  .my-md-2\.25 {
    margin-top: 3.15rem !important
  }

  .mr-md-2\.25,
  .mx-md-2\.25 {
    margin-right: 3.15rem !important
  }

  .mb-md-2\.25,
  .my-md-2\.25 {
    margin-bottom: 3.15rem !important
  }

  .ml-md-2\.25,
  .mx-md-2\.25 {
    margin-left: 3.15rem !important
  }

  .m-md-2\.5 {
    margin: 3.5rem !important
  }

  .mt-md-2\.5,
  .my-md-2\.5 {
    margin-top: 3.5rem !important
  }

  .mr-md-2\.5,
  .mx-md-2\.5 {
    margin-right: 3.5rem !important
  }

  .mb-md-2\.5,
  .my-md-2\.5 {
    margin-bottom: 3.5rem !important
  }

  .ml-md-2\.5,
  .mx-md-2\.5 {
    margin-left: 3.5rem !important
  }

  .m-md-2\.75 {
    margin: 3.85rem !important
  }

  .mt-md-2\.75,
  .my-md-2\.75 {
    margin-top: 3.85rem !important
  }

  .mr-md-2\.75,
  .mx-md-2\.75 {
    margin-right: 3.85rem !important
  }

  .mb-md-2\.75,
  .my-md-2\.75 {
    margin-bottom: 3.85rem !important
  }

  .ml-md-2\.75,
  .mx-md-2\.75 {
    margin-left: 3.85rem !important
  }

  .m-md-3 {
    margin: 4.2rem !important
  }

  .mt-md-3,
  .my-md-3 {
    margin-top: 4.2rem !important
  }

  .mr-md-3,
  .mx-md-3 {
    margin-right: 4.2rem !important
  }

  .mb-md-3,
  .my-md-3 {
    margin-bottom: 4.2rem !important
  }

  .ml-md-3,
  .mx-md-3 {
    margin-left: 4.2rem !important
  }

  .m-md-3\.5 {
    margin: 4.9rem !important
  }

  .mt-md-3\.5,
  .my-md-3\.5 {
    margin-top: 4.9rem !important
  }

  .mr-md-3\.5,
  .mx-md-3\.5 {
    margin-right: 4.9rem !important
  }

  .mb-md-3\.5,
  .my-md-3\.5 {
    margin-bottom: 4.9rem !important
  }

  .ml-md-3\.5,
  .mx-md-3\.5 {
    margin-left: 4.9rem !important
  }

  .m-md-4 {
    margin: 5.6rem !important
  }

  .mt-md-4,
  .my-md-4 {
    margin-top: 5.6rem !important
  }

  .mr-md-4,
  .mx-md-4 {
    margin-right: 5.6rem !important
  }

  .mb-md-4,
  .my-md-4 {
    margin-bottom: 5.6rem !important
  }

  .ml-md-4,
  .mx-md-4 {
    margin-left: 5.6rem !important
  }

  .m-md-5 {
    margin: 7rem !important
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 7rem !important
  }

  .mr-md-5,
  .mx-md-5 {
    margin-right: 7rem !important
  }

  .mb-md-5,
  .my-md-5 {
    margin-bottom: 7rem !important
  }

  .ml-md-5,
  .mx-md-5 {
    margin-left: 7rem !important
  }

  .m-md-6 {
    margin: 8.4rem !important
  }

  .mt-md-6,
  .my-md-6 {
    margin-top: 8.4rem !important
  }

  .mr-md-6,
  .mx-md-6 {
    margin-right: 8.4rem !important
  }

  .mb-md-6,
  .my-md-6 {
    margin-bottom: 8.4rem !important
  }

  .ml-md-6,
  .mx-md-6 {
    margin-left: 8.4rem !important
  }

  .m-md-7 {
    margin: 9.8rem !important
  }

  .mt-md-7,
  .my-md-7 {
    margin-top: 9.8rem !important
  }

  .mr-md-7,
  .mx-md-7 {
    margin-right: 9.8rem !important
  }

  .mb-md-7,
  .my-md-7 {
    margin-bottom: 9.8rem !important
  }

  .ml-md-7,
  .mx-md-7 {
    margin-left: 9.8rem !important
  }

  .m-md-8 {
    margin: 11.2rem !important
  }

  .mt-md-8,
  .my-md-8 {
    margin-top: 11.2rem !important
  }

  .mr-md-8,
  .mx-md-8 {
    margin-right: 11.2rem !important
  }

  .mb-md-8,
  .my-md-8 {
    margin-bottom: 11.2rem !important
  }

  .ml-md-8,
  .mx-md-8 {
    margin-left: 11.2rem !important
  }

  .m-md-9 {
    margin: 12.6rem !important
  }

  .mt-md-9,
  .my-md-9 {
    margin-top: 12.6rem !important
  }

  .mr-md-9,
  .mx-md-9 {
    margin-right: 12.6rem !important
  }

  .mb-md-9,
  .my-md-9 {
    margin-bottom: 12.6rem !important
  }

  .ml-md-9,
  .mx-md-9 {
    margin-left: 12.6rem !important
  }

  .m-md-10 {
    margin: 14rem !important
  }

  .mt-md-10,
  .my-md-10 {
    margin-top: 14rem !important
  }

  .mr-md-10,
  .mx-md-10 {
    margin-right: 14rem !important
  }

  .mb-md-10,
  .my-md-10 {
    margin-bottom: 14rem !important
  }

  .ml-md-10,
  .mx-md-10 {
    margin-left: 14rem !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important
  }

  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important
  }

  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important
  }

  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important
  }

  .p-md-1\/12 {
    padding: .1166666667rem !important
  }

  .pt-md-1\/12,
  .py-md-1\/12 {
    padding-top: .1166666667rem !important
  }

  .pr-md-1\/12,
  .px-md-1\/12 {
    padding-right: .1166666667rem !important
  }

  .pb-md-1\/12,
  .py-md-1\/12 {
    padding-bottom: .1166666667rem !important
  }

  .pl-md-1\/12,
  .px-md-1\/12 {
    padding-left: .1166666667rem !important
  }

  .p-md-0\.1 {
    padding: .14rem !important
  }

  .pt-md-0\.1,
  .py-md-0\.1 {
    padding-top: .14rem !important
  }

  .pr-md-0\.1,
  .px-md-0\.1 {
    padding-right: .14rem !important
  }

  .pb-md-0\.1,
  .py-md-0\.1 {
    padding-bottom: .14rem !important
  }

  .pl-md-0\.1,
  .px-md-0\.1 {
    padding-left: .14rem !important
  }

  .p-md-0\.125 {
    padding: .175rem !important
  }

  .pt-md-0\.125,
  .py-md-0\.125 {
    padding-top: .175rem !important
  }

  .pr-md-0\.125,
  .px-md-0\.125 {
    padding-right: .175rem !important
  }

  .pb-md-0\.125,
  .py-md-0\.125 {
    padding-bottom: .175rem !important
  }

  .pl-md-0\.125,
  .px-md-0\.125 {
    padding-left: .175rem !important
  }

  .p-md-1\/6 {
    padding: .2333333333rem !important
  }

  .pt-md-1\/6,
  .py-md-1\/6 {
    padding-top: .2333333333rem !important
  }

  .pr-md-1\/6,
  .px-md-1\/6 {
    padding-right: .2333333333rem !important
  }

  .pb-md-1\/6,
  .py-md-1\/6 {
    padding-bottom: .2333333333rem !important
  }

  .pl-md-1\/6,
  .px-md-1\/6 {
    padding-left: .2333333333rem !important
  }

  .p-md-0\.2 {
    padding: .28rem !important
  }

  .pt-md-0\.2,
  .py-md-0\.2 {
    padding-top: .28rem !important
  }

  .pr-md-0\.2,
  .px-md-0\.2 {
    padding-right: .28rem !important
  }

  .pb-md-0\.2,
  .py-md-0\.2 {
    padding-bottom: .28rem !important
  }

  .pl-md-0\.2,
  .px-md-0\.2 {
    padding-left: .28rem !important
  }

  .p-md-0\.25 {
    padding: .35rem !important
  }

  .pt-md-0\.25,
  .py-md-0\.25 {
    padding-top: .35rem !important
  }

  .pr-md-0\.25,
  .px-md-0\.25 {
    padding-right: .35rem !important
  }

  .pb-md-0\.25,
  .py-md-0\.25 {
    padding-bottom: .35rem !important
  }

  .pl-md-0\.25,
  .px-md-0\.25 {
    padding-left: .35rem !important
  }

  .p-md-1\/3 {
    padding: .4666666667rem !important
  }

  .pt-md-1\/3,
  .py-md-1\/3 {
    padding-top: .4666666667rem !important
  }

  .pr-md-1\/3,
  .px-md-1\/3 {
    padding-right: .4666666667rem !important
  }

  .pb-md-1\/3,
  .py-md-1\/3 {
    padding-bottom: .4666666667rem !important
  }

  .pl-md-1\/3,
  .px-md-1\/3 {
    padding-left: .4666666667rem !important
  }

  .p-md-5\/12 {
    padding: .5833333333rem !important
  }

  .pt-md-5\/12,
  .py-md-5\/12 {
    padding-top: .5833333333rem !important
  }

  .pr-md-5\/12,
  .px-md-5\/12 {
    padding-right: .5833333333rem !important
  }

  .pb-md-5\/12,
  .py-md-5\/12 {
    padding-bottom: .5833333333rem !important
  }

  .pl-md-5\/12,
  .px-md-5\/12 {
    padding-left: .5833333333rem !important
  }

  .p-md-0\.5 {
    padding: .7rem !important
  }

  .pt-md-0\.5,
  .py-md-0\.5 {
    padding-top: .7rem !important
  }

  .pr-md-0\.5,
  .px-md-0\.5 {
    padding-right: .7rem !important
  }

  .pb-md-0\.5,
  .py-md-0\.5 {
    padding-bottom: .7rem !important
  }

  .pl-md-0\.5,
  .px-md-0\.5 {
    padding-left: .7rem !important
  }

  .p-md-7\/12 {
    padding: .8166666667rem !important
  }

  .pt-md-7\/12,
  .py-md-7\/12 {
    padding-top: .8166666667rem !important
  }

  .pr-md-7\/12,
  .px-md-7\/12 {
    padding-right: .8166666667rem !important
  }

  .pb-md-7\/12,
  .py-md-7\/12 {
    padding-bottom: .8166666667rem !important
  }

  .pl-md-7\/12,
  .px-md-7\/12 {
    padding-left: .8166666667rem !important
  }

  .p-md-2\/3 {
    padding: .9333333333rem !important
  }

  .pt-md-2\/3,
  .py-md-2\/3 {
    padding-top: .9333333333rem !important
  }

  .pr-md-2\/3,
  .px-md-2\/3 {
    padding-right: .9333333333rem !important
  }

  .pb-md-2\/3,
  .py-md-2\/3 {
    padding-bottom: .9333333333rem !important
  }

  .pl-md-2\/3,
  .px-md-2\/3 {
    padding-left: .9333333333rem !important
  }

  .p-md-0\.75 {
    padding: 1.05rem !important
  }

  .pt-md-0\.75,
  .py-md-0\.75 {
    padding-top: 1.05rem !important
  }

  .pr-md-0\.75,
  .px-md-0\.75 {
    padding-right: 1.05rem !important
  }

  .pb-md-0\.75,
  .py-md-0\.75 {
    padding-bottom: 1.05rem !important
  }

  .pl-md-0\.75,
  .px-md-0\.75 {
    padding-left: 1.05rem !important
  }

  .p-md-10\/12 {
    padding: 1.1666666667rem !important
  }

  .pt-md-10\/12,
  .py-md-10\/12 {
    padding-top: 1.1666666667rem !important
  }

  .pr-md-10\/12,
  .px-md-10\/12 {
    padding-right: 1.1666666667rem !important
  }

  .pb-md-10\/12,
  .py-md-10\/12 {
    padding-bottom: 1.1666666667rem !important
  }

  .pl-md-10\/12,
  .px-md-10\/12 {
    padding-left: 1.1666666667rem !important
  }

  .p-md-11\/12 {
    padding: 1.2833333333rem !important
  }

  .pt-md-11\/12,
  .py-md-11\/12 {
    padding-top: 1.2833333333rem !important
  }

  .pr-md-11\/12,
  .px-md-11\/12 {
    padding-right: 1.2833333333rem !important
  }

  .pb-md-11\/12,
  .py-md-11\/12 {
    padding-bottom: 1.2833333333rem !important
  }

  .pl-md-11\/12,
  .px-md-11\/12 {
    padding-left: 1.2833333333rem !important
  }

  .p-md-1 {
    padding: 1.4rem !important
  }

  .pt-md-1,
  .py-md-1 {
    padding-top: 1.4rem !important
  }

  .pr-md-1,
  .px-md-1 {
    padding-right: 1.4rem !important
  }

  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1.4rem !important
  }

  .pl-md-1,
  .px-md-1 {
    padding-left: 1.4rem !important
  }

  .p-md-1\.25 {
    padding: 1.75rem !important
  }

  .pt-md-1\.25,
  .py-md-1\.25 {
    padding-top: 1.75rem !important
  }

  .pr-md-1\.25,
  .px-md-1\.25 {
    padding-right: 1.75rem !important
  }

  .pb-md-1\.25,
  .py-md-1\.25 {
    padding-bottom: 1.75rem !important
  }

  .pl-md-1\.25,
  .px-md-1\.25 {
    padding-left: 1.75rem !important
  }

  .p-md-1\.5 {
    padding: 2.1rem !important
  }

  .pt-md-1\.5,
  .py-md-1\.5 {
    padding-top: 2.1rem !important
  }

  .pr-md-1\.5,
  .px-md-1\.5 {
    padding-right: 2.1rem !important
  }

  .pb-md-1\.5,
  .py-md-1\.5 {
    padding-bottom: 2.1rem !important
  }

  .pl-md-1\.5,
  .px-md-1\.5 {
    padding-left: 2.1rem !important
  }

  .p-md-1\.75 {
    padding: 2.45rem !important
  }

  .pt-md-1\.75,
  .py-md-1\.75 {
    padding-top: 2.45rem !important
  }

  .pr-md-1\.75,
  .px-md-1\.75 {
    padding-right: 2.45rem !important
  }

  .pb-md-1\.75,
  .py-md-1\.75 {
    padding-bottom: 2.45rem !important
  }

  .pl-md-1\.75,
  .px-md-1\.75 {
    padding-left: 2.45rem !important
  }

  .p-md-2 {
    padding: 2.8rem !important
  }

  .pt-md-2,
  .py-md-2 {
    padding-top: 2.8rem !important
  }

  .pr-md-2,
  .px-md-2 {
    padding-right: 2.8rem !important
  }

  .pb-md-2,
  .py-md-2 {
    padding-bottom: 2.8rem !important
  }

  .pl-md-2,
  .px-md-2 {
    padding-left: 2.8rem !important
  }

  .p-md-2\.25 {
    padding: 3.15rem !important
  }

  .pt-md-2\.25,
  .py-md-2\.25 {
    padding-top: 3.15rem !important
  }

  .pr-md-2\.25,
  .px-md-2\.25 {
    padding-right: 3.15rem !important
  }

  .pb-md-2\.25,
  .py-md-2\.25 {
    padding-bottom: 3.15rem !important
  }

  .pl-md-2\.25,
  .px-md-2\.25 {
    padding-left: 3.15rem !important
  }

  .p-md-2\.5 {
    padding: 3.5rem !important
  }

  .pt-md-2\.5,
  .py-md-2\.5 {
    padding-top: 3.5rem !important
  }

  .pr-md-2\.5,
  .px-md-2\.5 {
    padding-right: 3.5rem !important
  }

  .pb-md-2\.5,
  .py-md-2\.5 {
    padding-bottom: 3.5rem !important
  }

  .pl-md-2\.5,
  .px-md-2\.5 {
    padding-left: 3.5rem !important
  }

  .p-md-2\.75 {
    padding: 3.85rem !important
  }

  .pt-md-2\.75,
  .py-md-2\.75 {
    padding-top: 3.85rem !important
  }

  .pr-md-2\.75,
  .px-md-2\.75 {
    padding-right: 3.85rem !important
  }

  .pb-md-2\.75,
  .py-md-2\.75 {
    padding-bottom: 3.85rem !important
  }

  .pl-md-2\.75,
  .px-md-2\.75 {
    padding-left: 3.85rem !important
  }

  .p-md-3 {
    padding: 4.2rem !important
  }

  .pt-md-3,
  .py-md-3 {
    padding-top: 4.2rem !important
  }

  .pr-md-3,
  .px-md-3 {
    padding-right: 4.2rem !important
  }

  .pb-md-3,
  .py-md-3 {
    padding-bottom: 4.2rem !important
  }

  .pl-md-3,
  .px-md-3 {
    padding-left: 4.2rem !important
  }

  .p-md-3\.5 {
    padding: 4.9rem !important
  }

  .pt-md-3\.5,
  .py-md-3\.5 {
    padding-top: 4.9rem !important
  }

  .pr-md-3\.5,
  .px-md-3\.5 {
    padding-right: 4.9rem !important
  }

  .pb-md-3\.5,
  .py-md-3\.5 {
    padding-bottom: 4.9rem !important
  }

  .pl-md-3\.5,
  .px-md-3\.5 {
    padding-left: 4.9rem !important
  }

  .p-md-4 {
    padding: 5.6rem !important
  }

  .pt-md-4,
  .py-md-4 {
    padding-top: 5.6rem !important
  }

  .pr-md-4,
  .px-md-4 {
    padding-right: 5.6rem !important
  }

  .pb-md-4,
  .py-md-4 {
    padding-bottom: 5.6rem !important
  }

  .pl-md-4,
  .px-md-4 {
    padding-left: 5.6rem !important
  }

  .p-md-5 {
    padding: 7rem !important
  }

  .pt-md-5,
  .py-md-5 {
    padding-top: 7rem !important
  }

  .pr-md-5,
  .px-md-5 {
    padding-right: 7rem !important
  }

  .pb-md-5,
  .py-md-5 {
    padding-bottom: 7rem !important
  }

  .pl-md-5,
  .px-md-5 {
    padding-left: 7rem !important
  }

  .p-md-6 {
    padding: 8.4rem !important
  }

  .pt-md-6,
  .py-md-6 {
    padding-top: 8.4rem !important
  }

  .pr-md-6,
  .px-md-6 {
    padding-right: 8.4rem !important
  }

  .pb-md-6,
  .py-md-6 {
    padding-bottom: 8.4rem !important
  }

  .pl-md-6,
  .px-md-6 {
    padding-left: 8.4rem !important
  }

  .p-md-7 {
    padding: 9.8rem !important
  }

  .pt-md-7,
  .py-md-7 {
    padding-top: 9.8rem !important
  }

  .pr-md-7,
  .px-md-7 {
    padding-right: 9.8rem !important
  }

  .pb-md-7,
  .py-md-7 {
    padding-bottom: 9.8rem !important
  }

  .pl-md-7,
  .px-md-7 {
    padding-left: 9.8rem !important
  }

  .p-md-8 {
    padding: 11.2rem !important
  }

  .pt-md-8,
  .py-md-8 {
    padding-top: 11.2rem !important
  }

  .pr-md-8,
  .px-md-8 {
    padding-right: 11.2rem !important
  }

  .pb-md-8,
  .py-md-8 {
    padding-bottom: 11.2rem !important
  }

  .pl-md-8,
  .px-md-8 {
    padding-left: 11.2rem !important
  }

  .p-md-9 {
    padding: 12.6rem !important
  }

  .pt-md-9,
  .py-md-9 {
    padding-top: 12.6rem !important
  }

  .pr-md-9,
  .px-md-9 {
    padding-right: 12.6rem !important
  }

  .pb-md-9,
  .py-md-9 {
    padding-bottom: 12.6rem !important
  }

  .pl-md-9,
  .px-md-9 {
    padding-left: 12.6rem !important
  }

  .p-md-10 {
    padding: 14rem !important
  }

  .pt-md-10,
  .py-md-10 {
    padding-top: 14rem !important
  }

  .pr-md-10,
  .px-md-10 {
    padding-right: 14rem !important
  }

  .pb-md-10,
  .py-md-10 {
    padding-bottom: 14rem !important
  }

  .pl-md-10,
  .px-md-10 {
    padding-left: 14rem !important
  }

  .m-md-n1\/12 {
    margin: -.1166666667rem !important
  }

  .mt-md-n1\/12,
  .my-md-n1\/12 {
    margin-top: -.1166666667rem !important
  }

  .mr-md-n1\/12,
  .mx-md-n1\/12 {
    margin-right: -.1166666667rem !important
  }

  .mb-md-n1\/12,
  .my-md-n1\/12 {
    margin-bottom: -.1166666667rem !important
  }

  .ml-md-n1\/12,
  .mx-md-n1\/12 {
    margin-left: -.1166666667rem !important
  }

  .m-md-n0\.1 {
    margin: -.14rem !important
  }

  .mt-md-n0\.1,
  .my-md-n0\.1 {
    margin-top: -.14rem !important
  }

  .mr-md-n0\.1,
  .mx-md-n0\.1 {
    margin-right: -.14rem !important
  }

  .mb-md-n0\.1,
  .my-md-n0\.1 {
    margin-bottom: -.14rem !important
  }

  .ml-md-n0\.1,
  .mx-md-n0\.1 {
    margin-left: -.14rem !important
  }

  .m-md-n0\.125 {
    margin: -.175rem !important
  }

  .mt-md-n0\.125,
  .my-md-n0\.125 {
    margin-top: -.175rem !important
  }

  .mr-md-n0\.125,
  .mx-md-n0\.125 {
    margin-right: -.175rem !important
  }

  .mb-md-n0\.125,
  .my-md-n0\.125 {
    margin-bottom: -.175rem !important
  }

  .ml-md-n0\.125,
  .mx-md-n0\.125 {
    margin-left: -.175rem !important
  }

  .m-md-n1\/6 {
    margin: -.2333333333rem !important
  }

  .mt-md-n1\/6,
  .my-md-n1\/6 {
    margin-top: -.2333333333rem !important
  }

  .mr-md-n1\/6,
  .mx-md-n1\/6 {
    margin-right: -.2333333333rem !important
  }

  .mb-md-n1\/6,
  .my-md-n1\/6 {
    margin-bottom: -.2333333333rem !important
  }

  .ml-md-n1\/6,
  .mx-md-n1\/6 {
    margin-left: -.2333333333rem !important
  }

  .m-md-n0\.2 {
    margin: -.28rem !important
  }

  .mt-md-n0\.2,
  .my-md-n0\.2 {
    margin-top: -.28rem !important
  }

  .mr-md-n0\.2,
  .mx-md-n0\.2 {
    margin-right: -.28rem !important
  }

  .mb-md-n0\.2,
  .my-md-n0\.2 {
    margin-bottom: -.28rem !important
  }

  .ml-md-n0\.2,
  .mx-md-n0\.2 {
    margin-left: -.28rem !important
  }

  .m-md-n0\.25 {
    margin: -.35rem !important
  }

  .mt-md-n0\.25,
  .my-md-n0\.25 {
    margin-top: -.35rem !important
  }

  .mr-md-n0\.25,
  .mx-md-n0\.25 {
    margin-right: -.35rem !important
  }

  .mb-md-n0\.25,
  .my-md-n0\.25 {
    margin-bottom: -.35rem !important
  }

  .ml-md-n0\.25,
  .mx-md-n0\.25 {
    margin-left: -.35rem !important
  }

  .m-md-n1\/3 {
    margin: -.4666666667rem !important
  }

  .mt-md-n1\/3,
  .my-md-n1\/3 {
    margin-top: -.4666666667rem !important
  }

  .mr-md-n1\/3,
  .mx-md-n1\/3 {
    margin-right: -.4666666667rem !important
  }

  .mb-md-n1\/3,
  .my-md-n1\/3 {
    margin-bottom: -.4666666667rem !important
  }

  .ml-md-n1\/3,
  .mx-md-n1\/3 {
    margin-left: -.4666666667rem !important
  }

  .m-md-n5\/12 {
    margin: -.5833333333rem !important
  }

  .mt-md-n5\/12,
  .my-md-n5\/12 {
    margin-top: -.5833333333rem !important
  }

  .mr-md-n5\/12,
  .mx-md-n5\/12 {
    margin-right: -.5833333333rem !important
  }

  .mb-md-n5\/12,
  .my-md-n5\/12 {
    margin-bottom: -.5833333333rem !important
  }

  .ml-md-n5\/12,
  .mx-md-n5\/12 {
    margin-left: -.5833333333rem !important
  }

  .m-md-n0\.5 {
    margin: -.7rem !important
  }

  .mt-md-n0\.5,
  .my-md-n0\.5 {
    margin-top: -.7rem !important
  }

  .mr-md-n0\.5,
  .mx-md-n0\.5 {
    margin-right: -.7rem !important
  }

  .mb-md-n0\.5,
  .my-md-n0\.5 {
    margin-bottom: -.7rem !important
  }

  .ml-md-n0\.5,
  .mx-md-n0\.5 {
    margin-left: -.7rem !important
  }

  .m-md-n7\/12 {
    margin: -.8166666667rem !important
  }

  .mt-md-n7\/12,
  .my-md-n7\/12 {
    margin-top: -.8166666667rem !important
  }

  .mr-md-n7\/12,
  .mx-md-n7\/12 {
    margin-right: -.8166666667rem !important
  }

  .mb-md-n7\/12,
  .my-md-n7\/12 {
    margin-bottom: -.8166666667rem !important
  }

  .ml-md-n7\/12,
  .mx-md-n7\/12 {
    margin-left: -.8166666667rem !important
  }

  .m-md-n2\/3 {
    margin: -.9333333333rem !important
  }

  .mt-md-n2\/3,
  .my-md-n2\/3 {
    margin-top: -.9333333333rem !important
  }

  .mr-md-n2\/3,
  .mx-md-n2\/3 {
    margin-right: -.9333333333rem !important
  }

  .mb-md-n2\/3,
  .my-md-n2\/3 {
    margin-bottom: -.9333333333rem !important
  }

  .ml-md-n2\/3,
  .mx-md-n2\/3 {
    margin-left: -.9333333333rem !important
  }

  .m-md-n0\.75 {
    margin: -1.05rem !important
  }

  .mt-md-n0\.75,
  .my-md-n0\.75 {
    margin-top: -1.05rem !important
  }

  .mr-md-n0\.75,
  .mx-md-n0\.75 {
    margin-right: -1.05rem !important
  }

  .mb-md-n0\.75,
  .my-md-n0\.75 {
    margin-bottom: -1.05rem !important
  }

  .ml-md-n0\.75,
  .mx-md-n0\.75 {
    margin-left: -1.05rem !important
  }

  .m-md-n10\/12 {
    margin: -1.1666666667rem !important
  }

  .mt-md-n10\/12,
  .my-md-n10\/12 {
    margin-top: -1.1666666667rem !important
  }

  .mr-md-n10\/12,
  .mx-md-n10\/12 {
    margin-right: -1.1666666667rem !important
  }

  .mb-md-n10\/12,
  .my-md-n10\/12 {
    margin-bottom: -1.1666666667rem !important
  }

  .ml-md-n10\/12,
  .mx-md-n10\/12 {
    margin-left: -1.1666666667rem !important
  }

  .m-md-n11\/12 {
    margin: -1.2833333333rem !important
  }

  .mt-md-n11\/12,
  .my-md-n11\/12 {
    margin-top: -1.2833333333rem !important
  }

  .mr-md-n11\/12,
  .mx-md-n11\/12 {
    margin-right: -1.2833333333rem !important
  }

  .mb-md-n11\/12,
  .my-md-n11\/12 {
    margin-bottom: -1.2833333333rem !important
  }

  .ml-md-n11\/12,
  .mx-md-n11\/12 {
    margin-left: -1.2833333333rem !important
  }

  .m-md-n1 {
    margin: -1.4rem !important
  }

  .mt-md-n1,
  .my-md-n1 {
    margin-top: -1.4rem !important
  }

  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -1.4rem !important
  }

  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -1.4rem !important
  }

  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -1.4rem !important
  }

  .m-md-n1\.25 {
    margin: -1.75rem !important
  }

  .mt-md-n1\.25,
  .my-md-n1\.25 {
    margin-top: -1.75rem !important
  }

  .mr-md-n1\.25,
  .mx-md-n1\.25 {
    margin-right: -1.75rem !important
  }

  .mb-md-n1\.25,
  .my-md-n1\.25 {
    margin-bottom: -1.75rem !important
  }

  .ml-md-n1\.25,
  .mx-md-n1\.25 {
    margin-left: -1.75rem !important
  }

  .m-md-n1\.5 {
    margin: -2.1rem !important
  }

  .mt-md-n1\.5,
  .my-md-n1\.5 {
    margin-top: -2.1rem !important
  }

  .mr-md-n1\.5,
  .mx-md-n1\.5 {
    margin-right: -2.1rem !important
  }

  .mb-md-n1\.5,
  .my-md-n1\.5 {
    margin-bottom: -2.1rem !important
  }

  .ml-md-n1\.5,
  .mx-md-n1\.5 {
    margin-left: -2.1rem !important
  }

  .m-md-n1\.75 {
    margin: -2.45rem !important
  }

  .mt-md-n1\.75,
  .my-md-n1\.75 {
    margin-top: -2.45rem !important
  }

  .mr-md-n1\.75,
  .mx-md-n1\.75 {
    margin-right: -2.45rem !important
  }

  .mb-md-n1\.75,
  .my-md-n1\.75 {
    margin-bottom: -2.45rem !important
  }

  .ml-md-n1\.75,
  .mx-md-n1\.75 {
    margin-left: -2.45rem !important
  }

  .m-md-n2 {
    margin: -2.8rem !important
  }

  .mt-md-n2,
  .my-md-n2 {
    margin-top: -2.8rem !important
  }

  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -2.8rem !important
  }

  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -2.8rem !important
  }

  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -2.8rem !important
  }

  .m-md-n2\.25 {
    margin: -3.15rem !important
  }

  .mt-md-n2\.25,
  .my-md-n2\.25 {
    margin-top: -3.15rem !important
  }

  .mr-md-n2\.25,
  .mx-md-n2\.25 {
    margin-right: -3.15rem !important
  }

  .mb-md-n2\.25,
  .my-md-n2\.25 {
    margin-bottom: -3.15rem !important
  }

  .ml-md-n2\.25,
  .mx-md-n2\.25 {
    margin-left: -3.15rem !important
  }

  .m-md-n2\.5 {
    margin: -3.5rem !important
  }

  .mt-md-n2\.5,
  .my-md-n2\.5 {
    margin-top: -3.5rem !important
  }

  .mr-md-n2\.5,
  .mx-md-n2\.5 {
    margin-right: -3.5rem !important
  }

  .mb-md-n2\.5,
  .my-md-n2\.5 {
    margin-bottom: -3.5rem !important
  }

  .ml-md-n2\.5,
  .mx-md-n2\.5 {
    margin-left: -3.5rem !important
  }

  .m-md-n2\.75 {
    margin: -3.85rem !important
  }

  .mt-md-n2\.75,
  .my-md-n2\.75 {
    margin-top: -3.85rem !important
  }

  .mr-md-n2\.75,
  .mx-md-n2\.75 {
    margin-right: -3.85rem !important
  }

  .mb-md-n2\.75,
  .my-md-n2\.75 {
    margin-bottom: -3.85rem !important
  }

  .ml-md-n2\.75,
  .mx-md-n2\.75 {
    margin-left: -3.85rem !important
  }

  .m-md-n3 {
    margin: -4.2rem !important
  }

  .mt-md-n3,
  .my-md-n3 {
    margin-top: -4.2rem !important
  }

  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -4.2rem !important
  }

  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -4.2rem !important
  }

  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -4.2rem !important
  }

  .m-md-n3\.5 {
    margin: -4.9rem !important
  }

  .mt-md-n3\.5,
  .my-md-n3\.5 {
    margin-top: -4.9rem !important
  }

  .mr-md-n3\.5,
  .mx-md-n3\.5 {
    margin-right: -4.9rem !important
  }

  .mb-md-n3\.5,
  .my-md-n3\.5 {
    margin-bottom: -4.9rem !important
  }

  .ml-md-n3\.5,
  .mx-md-n3\.5 {
    margin-left: -4.9rem !important
  }

  .m-md-n4 {
    margin: -5.6rem !important
  }

  .mt-md-n4,
  .my-md-n4 {
    margin-top: -5.6rem !important
  }

  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -5.6rem !important
  }

  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -5.6rem !important
  }

  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -5.6rem !important
  }

  .m-md-n5 {
    margin: -7rem !important
  }

  .mt-md-n5,
  .my-md-n5 {
    margin-top: -7rem !important
  }

  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -7rem !important
  }

  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -7rem !important
  }

  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -7rem !important
  }

  .m-md-n6 {
    margin: -8.4rem !important
  }

  .mt-md-n6,
  .my-md-n6 {
    margin-top: -8.4rem !important
  }

  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -8.4rem !important
  }

  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -8.4rem !important
  }

  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -8.4rem !important
  }

  .m-md-n7 {
    margin: -9.8rem !important
  }

  .mt-md-n7,
  .my-md-n7 {
    margin-top: -9.8rem !important
  }

  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -9.8rem !important
  }

  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -9.8rem !important
  }

  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -9.8rem !important
  }

  .m-md-n8 {
    margin: -11.2rem !important
  }

  .mt-md-n8,
  .my-md-n8 {
    margin-top: -11.2rem !important
  }

  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -11.2rem !important
  }

  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -11.2rem !important
  }

  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -11.2rem !important
  }

  .m-md-n9 {
    margin: -12.6rem !important
  }

  .mt-md-n9,
  .my-md-n9 {
    margin-top: -12.6rem !important
  }

  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -12.6rem !important
  }

  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -12.6rem !important
  }

  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -12.6rem !important
  }

  .m-md-n10 {
    margin: -14rem !important
  }

  .mt-md-n10,
  .my-md-n10 {
    margin-top: -14rem !important
  }

  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -14rem !important
  }

  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -14rem !important
  }

  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -14rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important
  }

  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important
  }

  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important
  }

  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important
  }
}

@media (min-width: 960px) {
  .m-lg-0 {
    margin: 0 !important
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important
  }

  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important
  }

  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important
  }

  .m-lg-1\/12 {
    margin: .1166666667rem !important
  }

  .mt-lg-1\/12,
  .my-lg-1\/12 {
    margin-top: .1166666667rem !important
  }

  .mr-lg-1\/12,
  .mx-lg-1\/12 {
    margin-right: .1166666667rem !important
  }

  .mb-lg-1\/12,
  .my-lg-1\/12 {
    margin-bottom: .1166666667rem !important
  }

  .ml-lg-1\/12,
  .mx-lg-1\/12 {
    margin-left: .1166666667rem !important
  }

  .m-lg-0\.1 {
    margin: .14rem !important
  }

  .mt-lg-0\.1,
  .my-lg-0\.1 {
    margin-top: .14rem !important
  }

  .mr-lg-0\.1,
  .mx-lg-0\.1 {
    margin-right: .14rem !important
  }

  .mb-lg-0\.1,
  .my-lg-0\.1 {
    margin-bottom: .14rem !important
  }

  .ml-lg-0\.1,
  .mx-lg-0\.1 {
    margin-left: .14rem !important
  }

  .m-lg-0\.125 {
    margin: .175rem !important
  }

  .mt-lg-0\.125,
  .my-lg-0\.125 {
    margin-top: .175rem !important
  }

  .mr-lg-0\.125,
  .mx-lg-0\.125 {
    margin-right: .175rem !important
  }

  .mb-lg-0\.125,
  .my-lg-0\.125 {
    margin-bottom: .175rem !important
  }

  .ml-lg-0\.125,
  .mx-lg-0\.125 {
    margin-left: .175rem !important
  }

  .m-lg-1\/6 {
    margin: .2333333333rem !important
  }

  .mt-lg-1\/6,
  .my-lg-1\/6 {
    margin-top: .2333333333rem !important
  }

  .mr-lg-1\/6,
  .mx-lg-1\/6 {
    margin-right: .2333333333rem !important
  }

  .mb-lg-1\/6,
  .my-lg-1\/6 {
    margin-bottom: .2333333333rem !important
  }

  .ml-lg-1\/6,
  .mx-lg-1\/6 {
    margin-left: .2333333333rem !important
  }

  .m-lg-0\.2 {
    margin: .28rem !important
  }

  .mt-lg-0\.2,
  .my-lg-0\.2 {
    margin-top: .28rem !important
  }

  .mr-lg-0\.2,
  .mx-lg-0\.2 {
    margin-right: .28rem !important
  }

  .mb-lg-0\.2,
  .my-lg-0\.2 {
    margin-bottom: .28rem !important
  }

  .ml-lg-0\.2,
  .mx-lg-0\.2 {
    margin-left: .28rem !important
  }

  .m-lg-0\.25 {
    margin: .35rem !important
  }

  .mt-lg-0\.25,
  .my-lg-0\.25 {
    margin-top: .35rem !important
  }

  .mr-lg-0\.25,
  .mx-lg-0\.25 {
    margin-right: .35rem !important
  }

  .mb-lg-0\.25,
  .my-lg-0\.25 {
    margin-bottom: .35rem !important
  }

  .ml-lg-0\.25,
  .mx-lg-0\.25 {
    margin-left: .35rem !important
  }

  .m-lg-1\/3 {
    margin: .4666666667rem !important
  }

  .mt-lg-1\/3,
  .my-lg-1\/3 {
    margin-top: .4666666667rem !important
  }

  .mr-lg-1\/3,
  .mx-lg-1\/3 {
    margin-right: .4666666667rem !important
  }

  .mb-lg-1\/3,
  .my-lg-1\/3 {
    margin-bottom: .4666666667rem !important
  }

  .ml-lg-1\/3,
  .mx-lg-1\/3 {
    margin-left: .4666666667rem !important
  }

  .m-lg-5\/12 {
    margin: .5833333333rem !important
  }

  .mt-lg-5\/12,
  .my-lg-5\/12 {
    margin-top: .5833333333rem !important
  }

  .mr-lg-5\/12,
  .mx-lg-5\/12 {
    margin-right: .5833333333rem !important
  }

  .mb-lg-5\/12,
  .my-lg-5\/12 {
    margin-bottom: .5833333333rem !important
  }

  .ml-lg-5\/12,
  .mx-lg-5\/12 {
    margin-left: .5833333333rem !important
  }

  .m-lg-0\.5 {
    margin: .7rem !important
  }

  .mt-lg-0\.5,
  .my-lg-0\.5 {
    margin-top: .7rem !important
  }

  .mr-lg-0\.5,
  .mx-lg-0\.5 {
    margin-right: .7rem !important
  }

  .mb-lg-0\.5,
  .my-lg-0\.5 {
    margin-bottom: .7rem !important
  }

  .ml-lg-0\.5,
  .mx-lg-0\.5 {
    margin-left: .7rem !important
  }

  .m-lg-7\/12 {
    margin: .8166666667rem !important
  }

  .mt-lg-7\/12,
  .my-lg-7\/12 {
    margin-top: .8166666667rem !important
  }

  .mr-lg-7\/12,
  .mx-lg-7\/12 {
    margin-right: .8166666667rem !important
  }

  .mb-lg-7\/12,
  .my-lg-7\/12 {
    margin-bottom: .8166666667rem !important
  }

  .ml-lg-7\/12,
  .mx-lg-7\/12 {
    margin-left: .8166666667rem !important
  }

  .m-lg-2\/3 {
    margin: .9333333333rem !important
  }

  .mt-lg-2\/3,
  .my-lg-2\/3 {
    margin-top: .9333333333rem !important
  }

  .mr-lg-2\/3,
  .mx-lg-2\/3 {
    margin-right: .9333333333rem !important
  }

  .mb-lg-2\/3,
  .my-lg-2\/3 {
    margin-bottom: .9333333333rem !important
  }

  .ml-lg-2\/3,
  .mx-lg-2\/3 {
    margin-left: .9333333333rem !important
  }

  .m-lg-0\.75 {
    margin: 1.05rem !important
  }

  .mt-lg-0\.75,
  .my-lg-0\.75 {
    margin-top: 1.05rem !important
  }

  .mr-lg-0\.75,
  .mx-lg-0\.75 {
    margin-right: 1.05rem !important
  }

  .mb-lg-0\.75,
  .my-lg-0\.75 {
    margin-bottom: 1.05rem !important
  }

  .ml-lg-0\.75,
  .mx-lg-0\.75 {
    margin-left: 1.05rem !important
  }

  .m-lg-10\/12 {
    margin: 1.1666666667rem !important
  }

  .mt-lg-10\/12,
  .my-lg-10\/12 {
    margin-top: 1.1666666667rem !important
  }

  .mr-lg-10\/12,
  .mx-lg-10\/12 {
    margin-right: 1.1666666667rem !important
  }

  .mb-lg-10\/12,
  .my-lg-10\/12 {
    margin-bottom: 1.1666666667rem !important
  }

  .ml-lg-10\/12,
  .mx-lg-10\/12 {
    margin-left: 1.1666666667rem !important
  }

  .m-lg-11\/12 {
    margin: 1.2833333333rem !important
  }

  .mt-lg-11\/12,
  .my-lg-11\/12 {
    margin-top: 1.2833333333rem !important
  }

  .mr-lg-11\/12,
  .mx-lg-11\/12 {
    margin-right: 1.2833333333rem !important
  }

  .mb-lg-11\/12,
  .my-lg-11\/12 {
    margin-bottom: 1.2833333333rem !important
  }

  .ml-lg-11\/12,
  .mx-lg-11\/12 {
    margin-left: 1.2833333333rem !important
  }

  .m-lg-1 {
    margin: 1.4rem !important
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1.4rem !important
  }

  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1.4rem !important
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1.4rem !important
  }

  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1.4rem !important
  }

  .m-lg-1\.25 {
    margin: 1.75rem !important
  }

  .mt-lg-1\.25,
  .my-lg-1\.25 {
    margin-top: 1.75rem !important
  }

  .mr-lg-1\.25,
  .mx-lg-1\.25 {
    margin-right: 1.75rem !important
  }

  .mb-lg-1\.25,
  .my-lg-1\.25 {
    margin-bottom: 1.75rem !important
  }

  .ml-lg-1\.25,
  .mx-lg-1\.25 {
    margin-left: 1.75rem !important
  }

  .m-lg-1\.5 {
    margin: 2.1rem !important
  }

  .mt-lg-1\.5,
  .my-lg-1\.5 {
    margin-top: 2.1rem !important
  }

  .mr-lg-1\.5,
  .mx-lg-1\.5 {
    margin-right: 2.1rem !important
  }

  .mb-lg-1\.5,
  .my-lg-1\.5 {
    margin-bottom: 2.1rem !important
  }

  .ml-lg-1\.5,
  .mx-lg-1\.5 {
    margin-left: 2.1rem !important
  }

  .m-lg-1\.75 {
    margin: 2.45rem !important
  }

  .mt-lg-1\.75,
  .my-lg-1\.75 {
    margin-top: 2.45rem !important
  }

  .mr-lg-1\.75,
  .mx-lg-1\.75 {
    margin-right: 2.45rem !important
  }

  .mb-lg-1\.75,
  .my-lg-1\.75 {
    margin-bottom: 2.45rem !important
  }

  .ml-lg-1\.75,
  .mx-lg-1\.75 {
    margin-left: 2.45rem !important
  }

  .m-lg-2 {
    margin: 2.8rem !important
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-top: 2.8rem !important
  }

  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 2.8rem !important
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 2.8rem !important
  }

  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 2.8rem !important
  }

  .m-lg-2\.25 {
    margin: 3.15rem !important
  }

  .mt-lg-2\.25,
  .my-lg-2\.25 {
    margin-top: 3.15rem !important
  }

  .mr-lg-2\.25,
  .mx-lg-2\.25 {
    margin-right: 3.15rem !important
  }

  .mb-lg-2\.25,
  .my-lg-2\.25 {
    margin-bottom: 3.15rem !important
  }

  .ml-lg-2\.25,
  .mx-lg-2\.25 {
    margin-left: 3.15rem !important
  }

  .m-lg-2\.5 {
    margin: 3.5rem !important
  }

  .mt-lg-2\.5,
  .my-lg-2\.5 {
    margin-top: 3.5rem !important
  }

  .mr-lg-2\.5,
  .mx-lg-2\.5 {
    margin-right: 3.5rem !important
  }

  .mb-lg-2\.5,
  .my-lg-2\.5 {
    margin-bottom: 3.5rem !important
  }

  .ml-lg-2\.5,
  .mx-lg-2\.5 {
    margin-left: 3.5rem !important
  }

  .m-lg-2\.75 {
    margin: 3.85rem !important
  }

  .mt-lg-2\.75,
  .my-lg-2\.75 {
    margin-top: 3.85rem !important
  }

  .mr-lg-2\.75,
  .mx-lg-2\.75 {
    margin-right: 3.85rem !important
  }

  .mb-lg-2\.75,
  .my-lg-2\.75 {
    margin-bottom: 3.85rem !important
  }

  .ml-lg-2\.75,
  .mx-lg-2\.75 {
    margin-left: 3.85rem !important
  }

  .m-lg-3 {
    margin: 4.2rem !important
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-top: 4.2rem !important
  }

  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 4.2rem !important
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 4.2rem !important
  }

  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 4.2rem !important
  }

  .m-lg-3\.5 {
    margin: 4.9rem !important
  }

  .mt-lg-3\.5,
  .my-lg-3\.5 {
    margin-top: 4.9rem !important
  }

  .mr-lg-3\.5,
  .mx-lg-3\.5 {
    margin-right: 4.9rem !important
  }

  .mb-lg-3\.5,
  .my-lg-3\.5 {
    margin-bottom: 4.9rem !important
  }

  .ml-lg-3\.5,
  .mx-lg-3\.5 {
    margin-left: 4.9rem !important
  }

  .m-lg-4 {
    margin: 5.6rem !important
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-top: 5.6rem !important
  }

  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 5.6rem !important
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 5.6rem !important
  }

  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 5.6rem !important
  }

  .m-lg-5 {
    margin: 7rem !important
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-top: 7rem !important
  }

  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 7rem !important
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 7rem !important
  }

  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 7rem !important
  }

  .m-lg-6 {
    margin: 8.4rem !important
  }

  .mt-lg-6,
  .my-lg-6 {
    margin-top: 8.4rem !important
  }

  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 8.4rem !important
  }

  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 8.4rem !important
  }

  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 8.4rem !important
  }

  .m-lg-7 {
    margin: 9.8rem !important
  }

  .mt-lg-7,
  .my-lg-7 {
    margin-top: 9.8rem !important
  }

  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 9.8rem !important
  }

  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 9.8rem !important
  }

  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 9.8rem !important
  }

  .m-lg-8 {
    margin: 11.2rem !important
  }

  .mt-lg-8,
  .my-lg-8 {
    margin-top: 11.2rem !important
  }

  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 11.2rem !important
  }

  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 11.2rem !important
  }

  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 11.2rem !important
  }

  .m-lg-9 {
    margin: 12.6rem !important
  }

  .mt-lg-9,
  .my-lg-9 {
    margin-top: 12.6rem !important
  }

  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 12.6rem !important
  }

  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 12.6rem !important
  }

  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 12.6rem !important
  }

  .m-lg-10 {
    margin: 14rem !important
  }

  .mt-lg-10,
  .my-lg-10 {
    margin-top: 14rem !important
  }

  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 14rem !important
  }

  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 14rem !important
  }

  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 14rem !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important
  }

  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important
  }

  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important
  }

  .p-lg-1\/12 {
    padding: .1166666667rem !important
  }

  .pt-lg-1\/12,
  .py-lg-1\/12 {
    padding-top: .1166666667rem !important
  }

  .pr-lg-1\/12,
  .px-lg-1\/12 {
    padding-right: .1166666667rem !important
  }

  .pb-lg-1\/12,
  .py-lg-1\/12 {
    padding-bottom: .1166666667rem !important
  }

  .pl-lg-1\/12,
  .px-lg-1\/12 {
    padding-left: .1166666667rem !important
  }

  .p-lg-0\.1 {
    padding: .14rem !important
  }

  .pt-lg-0\.1,
  .py-lg-0\.1 {
    padding-top: .14rem !important
  }

  .pr-lg-0\.1,
  .px-lg-0\.1 {
    padding-right: .14rem !important
  }

  .pb-lg-0\.1,
  .py-lg-0\.1 {
    padding-bottom: .14rem !important
  }

  .pl-lg-0\.1,
  .px-lg-0\.1 {
    padding-left: .14rem !important
  }

  .p-lg-0\.125 {
    padding: .175rem !important
  }

  .pt-lg-0\.125,
  .py-lg-0\.125 {
    padding-top: .175rem !important
  }

  .pr-lg-0\.125,
  .px-lg-0\.125 {
    padding-right: .175rem !important
  }

  .pb-lg-0\.125,
  .py-lg-0\.125 {
    padding-bottom: .175rem !important
  }

  .pl-lg-0\.125,
  .px-lg-0\.125 {
    padding-left: .175rem !important
  }

  .p-lg-1\/6 {
    padding: .2333333333rem !important
  }

  .pt-lg-1\/6,
  .py-lg-1\/6 {
    padding-top: .2333333333rem !important
  }

  .pr-lg-1\/6,
  .px-lg-1\/6 {
    padding-right: .2333333333rem !important
  }

  .pb-lg-1\/6,
  .py-lg-1\/6 {
    padding-bottom: .2333333333rem !important
  }

  .pl-lg-1\/6,
  .px-lg-1\/6 {
    padding-left: .2333333333rem !important
  }

  .p-lg-0\.2 {
    padding: .28rem !important
  }

  .pt-lg-0\.2,
  .py-lg-0\.2 {
    padding-top: .28rem !important
  }

  .pr-lg-0\.2,
  .px-lg-0\.2 {
    padding-right: .28rem !important
  }

  .pb-lg-0\.2,
  .py-lg-0\.2 {
    padding-bottom: .28rem !important
  }

  .pl-lg-0\.2,
  .px-lg-0\.2 {
    padding-left: .28rem !important
  }

  .p-lg-0\.25 {
    padding: .35rem !important
  }

  .pt-lg-0\.25,
  .py-lg-0\.25 {
    padding-top: .35rem !important
  }

  .pr-lg-0\.25,
  .px-lg-0\.25 {
    padding-right: .35rem !important
  }

  .pb-lg-0\.25,
  .py-lg-0\.25 {
    padding-bottom: .35rem !important
  }

  .pl-lg-0\.25,
  .px-lg-0\.25 {
    padding-left: .35rem !important
  }

  .p-lg-1\/3 {
    padding: .4666666667rem !important
  }

  .pt-lg-1\/3,
  .py-lg-1\/3 {
    padding-top: .4666666667rem !important
  }

  .pr-lg-1\/3,
  .px-lg-1\/3 {
    padding-right: .4666666667rem !important
  }

  .pb-lg-1\/3,
  .py-lg-1\/3 {
    padding-bottom: .4666666667rem !important
  }

  .pl-lg-1\/3,
  .px-lg-1\/3 {
    padding-left: .4666666667rem !important
  }

  .p-lg-5\/12 {
    padding: .5833333333rem !important
  }

  .pt-lg-5\/12,
  .py-lg-5\/12 {
    padding-top: .5833333333rem !important
  }

  .pr-lg-5\/12,
  .px-lg-5\/12 {
    padding-right: .5833333333rem !important
  }

  .pb-lg-5\/12,
  .py-lg-5\/12 {
    padding-bottom: .5833333333rem !important
  }

  .pl-lg-5\/12,
  .px-lg-5\/12 {
    padding-left: .5833333333rem !important
  }

  .p-lg-0\.5 {
    padding: .7rem !important
  }

  .pt-lg-0\.5,
  .py-lg-0\.5 {
    padding-top: .7rem !important
  }

  .pr-lg-0\.5,
  .px-lg-0\.5 {
    padding-right: .7rem !important
  }

  .pb-lg-0\.5,
  .py-lg-0\.5 {
    padding-bottom: .7rem !important
  }

  .pl-lg-0\.5,
  .px-lg-0\.5 {
    padding-left: .7rem !important
  }

  .p-lg-7\/12 {
    padding: .8166666667rem !important
  }

  .pt-lg-7\/12,
  .py-lg-7\/12 {
    padding-top: .8166666667rem !important
  }

  .pr-lg-7\/12,
  .px-lg-7\/12 {
    padding-right: .8166666667rem !important
  }

  .pb-lg-7\/12,
  .py-lg-7\/12 {
    padding-bottom: .8166666667rem !important
  }

  .pl-lg-7\/12,
  .px-lg-7\/12 {
    padding-left: .8166666667rem !important
  }

  .p-lg-2\/3 {
    padding: .9333333333rem !important
  }

  .pt-lg-2\/3,
  .py-lg-2\/3 {
    padding-top: .9333333333rem !important
  }

  .pr-lg-2\/3,
  .px-lg-2\/3 {
    padding-right: .9333333333rem !important
  }

  .pb-lg-2\/3,
  .py-lg-2\/3 {
    padding-bottom: .9333333333rem !important
  }

  .pl-lg-2\/3,
  .px-lg-2\/3 {
    padding-left: .9333333333rem !important
  }

  .p-lg-0\.75 {
    padding: 1.05rem !important
  }

  .pt-lg-0\.75,
  .py-lg-0\.75 {
    padding-top: 1.05rem !important
  }

  .pr-lg-0\.75,
  .px-lg-0\.75 {
    padding-right: 1.05rem !important
  }

  .pb-lg-0\.75,
  .py-lg-0\.75 {
    padding-bottom: 1.05rem !important
  }

  .pl-lg-0\.75,
  .px-lg-0\.75 {
    padding-left: 1.05rem !important
  }

  .p-lg-10\/12 {
    padding: 1.1666666667rem !important
  }

  .pt-lg-10\/12,
  .py-lg-10\/12 {
    padding-top: 1.1666666667rem !important
  }

  .pr-lg-10\/12,
  .px-lg-10\/12 {
    padding-right: 1.1666666667rem !important
  }

  .pb-lg-10\/12,
  .py-lg-10\/12 {
    padding-bottom: 1.1666666667rem !important
  }

  .pl-lg-10\/12,
  .px-lg-10\/12 {
    padding-left: 1.1666666667rem !important
  }

  .p-lg-11\/12 {
    padding: 1.2833333333rem !important
  }

  .pt-lg-11\/12,
  .py-lg-11\/12 {
    padding-top: 1.2833333333rem !important
  }

  .pr-lg-11\/12,
  .px-lg-11\/12 {
    padding-right: 1.2833333333rem !important
  }

  .pb-lg-11\/12,
  .py-lg-11\/12 {
    padding-bottom: 1.2833333333rem !important
  }

  .pl-lg-11\/12,
  .px-lg-11\/12 {
    padding-left: 1.2833333333rem !important
  }

  .p-lg-1 {
    padding: 1.4rem !important
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1.4rem !important
  }

  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1.4rem !important
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1.4rem !important
  }

  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1.4rem !important
  }

  .p-lg-1\.25 {
    padding: 1.75rem !important
  }

  .pt-lg-1\.25,
  .py-lg-1\.25 {
    padding-top: 1.75rem !important
  }

  .pr-lg-1\.25,
  .px-lg-1\.25 {
    padding-right: 1.75rem !important
  }

  .pb-lg-1\.25,
  .py-lg-1\.25 {
    padding-bottom: 1.75rem !important
  }

  .pl-lg-1\.25,
  .px-lg-1\.25 {
    padding-left: 1.75rem !important
  }

  .p-lg-1\.5 {
    padding: 2.1rem !important
  }

  .pt-lg-1\.5,
  .py-lg-1\.5 {
    padding-top: 2.1rem !important
  }

  .pr-lg-1\.5,
  .px-lg-1\.5 {
    padding-right: 2.1rem !important
  }

  .pb-lg-1\.5,
  .py-lg-1\.5 {
    padding-bottom: 2.1rem !important
  }

  .pl-lg-1\.5,
  .px-lg-1\.5 {
    padding-left: 2.1rem !important
  }

  .p-lg-1\.75 {
    padding: 2.45rem !important
  }

  .pt-lg-1\.75,
  .py-lg-1\.75 {
    padding-top: 2.45rem !important
  }

  .pr-lg-1\.75,
  .px-lg-1\.75 {
    padding-right: 2.45rem !important
  }

  .pb-lg-1\.75,
  .py-lg-1\.75 {
    padding-bottom: 2.45rem !important
  }

  .pl-lg-1\.75,
  .px-lg-1\.75 {
    padding-left: 2.45rem !important
  }

  .p-lg-2 {
    padding: 2.8rem !important
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-top: 2.8rem !important
  }

  .pr-lg-2,
  .px-lg-2 {
    padding-right: 2.8rem !important
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 2.8rem !important
  }

  .pl-lg-2,
  .px-lg-2 {
    padding-left: 2.8rem !important
  }

  .p-lg-2\.25 {
    padding: 3.15rem !important
  }

  .pt-lg-2\.25,
  .py-lg-2\.25 {
    padding-top: 3.15rem !important
  }

  .pr-lg-2\.25,
  .px-lg-2\.25 {
    padding-right: 3.15rem !important
  }

  .pb-lg-2\.25,
  .py-lg-2\.25 {
    padding-bottom: 3.15rem !important
  }

  .pl-lg-2\.25,
  .px-lg-2\.25 {
    padding-left: 3.15rem !important
  }

  .p-lg-2\.5 {
    padding: 3.5rem !important
  }

  .pt-lg-2\.5,
  .py-lg-2\.5 {
    padding-top: 3.5rem !important
  }

  .pr-lg-2\.5,
  .px-lg-2\.5 {
    padding-right: 3.5rem !important
  }

  .pb-lg-2\.5,
  .py-lg-2\.5 {
    padding-bottom: 3.5rem !important
  }

  .pl-lg-2\.5,
  .px-lg-2\.5 {
    padding-left: 3.5rem !important
  }

  .p-lg-2\.75 {
    padding: 3.85rem !important
  }

  .pt-lg-2\.75,
  .py-lg-2\.75 {
    padding-top: 3.85rem !important
  }

  .pr-lg-2\.75,
  .px-lg-2\.75 {
    padding-right: 3.85rem !important
  }

  .pb-lg-2\.75,
  .py-lg-2\.75 {
    padding-bottom: 3.85rem !important
  }

  .pl-lg-2\.75,
  .px-lg-2\.75 {
    padding-left: 3.85rem !important
  }

  .p-lg-3 {
    padding: 4.2rem !important
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-top: 4.2rem !important
  }

  .pr-lg-3,
  .px-lg-3 {
    padding-right: 4.2rem !important
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 4.2rem !important
  }

  .pl-lg-3,
  .px-lg-3 {
    padding-left: 4.2rem !important
  }

  .p-lg-3\.5 {
    padding: 4.9rem !important
  }

  .pt-lg-3\.5,
  .py-lg-3\.5 {
    padding-top: 4.9rem !important
  }

  .pr-lg-3\.5,
  .px-lg-3\.5 {
    padding-right: 4.9rem !important
  }

  .pb-lg-3\.5,
  .py-lg-3\.5 {
    padding-bottom: 4.9rem !important
  }

  .pl-lg-3\.5,
  .px-lg-3\.5 {
    padding-left: 4.9rem !important
  }

  .p-lg-4 {
    padding: 5.6rem !important
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-top: 5.6rem !important
  }

  .pr-lg-4,
  .px-lg-4 {
    padding-right: 5.6rem !important
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 5.6rem !important
  }

  .pl-lg-4,
  .px-lg-4 {
    padding-left: 5.6rem !important
  }

  .p-lg-5 {
    padding: 7rem !important
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-top: 7rem !important
  }

  .pr-lg-5,
  .px-lg-5 {
    padding-right: 7rem !important
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 7rem !important
  }

  .pl-lg-5,
  .px-lg-5 {
    padding-left: 7rem !important
  }

  .p-lg-6 {
    padding: 8.4rem !important
  }

  .pt-lg-6,
  .py-lg-6 {
    padding-top: 8.4rem !important
  }

  .pr-lg-6,
  .px-lg-6 {
    padding-right: 8.4rem !important
  }

  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 8.4rem !important
  }

  .pl-lg-6,
  .px-lg-6 {
    padding-left: 8.4rem !important
  }

  .p-lg-7 {
    padding: 9.8rem !important
  }

  .pt-lg-7,
  .py-lg-7 {
    padding-top: 9.8rem !important
  }

  .pr-lg-7,
  .px-lg-7 {
    padding-right: 9.8rem !important
  }

  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 9.8rem !important
  }

  .pl-lg-7,
  .px-lg-7 {
    padding-left: 9.8rem !important
  }

  .p-lg-8 {
    padding: 11.2rem !important
  }

  .pt-lg-8,
  .py-lg-8 {
    padding-top: 11.2rem !important
  }

  .pr-lg-8,
  .px-lg-8 {
    padding-right: 11.2rem !important
  }

  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 11.2rem !important
  }

  .pl-lg-8,
  .px-lg-8 {
    padding-left: 11.2rem !important
  }

  .p-lg-9 {
    padding: 12.6rem !important
  }

  .pt-lg-9,
  .py-lg-9 {
    padding-top: 12.6rem !important
  }

  .pr-lg-9,
  .px-lg-9 {
    padding-right: 12.6rem !important
  }

  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 12.6rem !important
  }

  .pl-lg-9,
  .px-lg-9 {
    padding-left: 12.6rem !important
  }

  .p-lg-10 {
    padding: 14rem !important
  }

  .pt-lg-10,
  .py-lg-10 {
    padding-top: 14rem !important
  }

  .pr-lg-10,
  .px-lg-10 {
    padding-right: 14rem !important
  }

  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 14rem !important
  }

  .pl-lg-10,
  .px-lg-10 {
    padding-left: 14rem !important
  }

  .m-lg-n1\/12 {
    margin: -.1166666667rem !important
  }

  .mt-lg-n1\/12,
  .my-lg-n1\/12 {
    margin-top: -.1166666667rem !important
  }

  .mr-lg-n1\/12,
  .mx-lg-n1\/12 {
    margin-right: -.1166666667rem !important
  }

  .mb-lg-n1\/12,
  .my-lg-n1\/12 {
    margin-bottom: -.1166666667rem !important
  }

  .ml-lg-n1\/12,
  .mx-lg-n1\/12 {
    margin-left: -.1166666667rem !important
  }

  .m-lg-n0\.1 {
    margin: -.14rem !important
  }

  .mt-lg-n0\.1,
  .my-lg-n0\.1 {
    margin-top: -.14rem !important
  }

  .mr-lg-n0\.1,
  .mx-lg-n0\.1 {
    margin-right: -.14rem !important
  }

  .mb-lg-n0\.1,
  .my-lg-n0\.1 {
    margin-bottom: -.14rem !important
  }

  .ml-lg-n0\.1,
  .mx-lg-n0\.1 {
    margin-left: -.14rem !important
  }

  .m-lg-n0\.125 {
    margin: -.175rem !important
  }

  .mt-lg-n0\.125,
  .my-lg-n0\.125 {
    margin-top: -.175rem !important
  }

  .mr-lg-n0\.125,
  .mx-lg-n0\.125 {
    margin-right: -.175rem !important
  }

  .mb-lg-n0\.125,
  .my-lg-n0\.125 {
    margin-bottom: -.175rem !important
  }

  .ml-lg-n0\.125,
  .mx-lg-n0\.125 {
    margin-left: -.175rem !important
  }

  .m-lg-n1\/6 {
    margin: -.2333333333rem !important
  }

  .mt-lg-n1\/6,
  .my-lg-n1\/6 {
    margin-top: -.2333333333rem !important
  }

  .mr-lg-n1\/6,
  .mx-lg-n1\/6 {
    margin-right: -.2333333333rem !important
  }

  .mb-lg-n1\/6,
  .my-lg-n1\/6 {
    margin-bottom: -.2333333333rem !important
  }

  .ml-lg-n1\/6,
  .mx-lg-n1\/6 {
    margin-left: -.2333333333rem !important
  }

  .m-lg-n0\.2 {
    margin: -.28rem !important
  }

  .mt-lg-n0\.2,
  .my-lg-n0\.2 {
    margin-top: -.28rem !important
  }

  .mr-lg-n0\.2,
  .mx-lg-n0\.2 {
    margin-right: -.28rem !important
  }

  .mb-lg-n0\.2,
  .my-lg-n0\.2 {
    margin-bottom: -.28rem !important
  }

  .ml-lg-n0\.2,
  .mx-lg-n0\.2 {
    margin-left: -.28rem !important
  }

  .m-lg-n0\.25 {
    margin: -.35rem !important
  }

  .mt-lg-n0\.25,
  .my-lg-n0\.25 {
    margin-top: -.35rem !important
  }

  .mr-lg-n0\.25,
  .mx-lg-n0\.25 {
    margin-right: -.35rem !important
  }

  .mb-lg-n0\.25,
  .my-lg-n0\.25 {
    margin-bottom: -.35rem !important
  }

  .ml-lg-n0\.25,
  .mx-lg-n0\.25 {
    margin-left: -.35rem !important
  }

  .m-lg-n1\/3 {
    margin: -.4666666667rem !important
  }

  .mt-lg-n1\/3,
  .my-lg-n1\/3 {
    margin-top: -.4666666667rem !important
  }

  .mr-lg-n1\/3,
  .mx-lg-n1\/3 {
    margin-right: -.4666666667rem !important
  }

  .mb-lg-n1\/3,
  .my-lg-n1\/3 {
    margin-bottom: -.4666666667rem !important
  }

  .ml-lg-n1\/3,
  .mx-lg-n1\/3 {
    margin-left: -.4666666667rem !important
  }

  .m-lg-n5\/12 {
    margin: -.5833333333rem !important
  }

  .mt-lg-n5\/12,
  .my-lg-n5\/12 {
    margin-top: -.5833333333rem !important
  }

  .mr-lg-n5\/12,
  .mx-lg-n5\/12 {
    margin-right: -.5833333333rem !important
  }

  .mb-lg-n5\/12,
  .my-lg-n5\/12 {
    margin-bottom: -.5833333333rem !important
  }

  .ml-lg-n5\/12,
  .mx-lg-n5\/12 {
    margin-left: -.5833333333rem !important
  }

  .m-lg-n0\.5 {
    margin: -.7rem !important
  }

  .mt-lg-n0\.5,
  .my-lg-n0\.5 {
    margin-top: -.7rem !important
  }

  .mr-lg-n0\.5,
  .mx-lg-n0\.5 {
    margin-right: -.7rem !important
  }

  .mb-lg-n0\.5,
  .my-lg-n0\.5 {
    margin-bottom: -.7rem !important
  }

  .ml-lg-n0\.5,
  .mx-lg-n0\.5 {
    margin-left: -.7rem !important
  }

  .m-lg-n7\/12 {
    margin: -.8166666667rem !important
  }

  .mt-lg-n7\/12,
  .my-lg-n7\/12 {
    margin-top: -.8166666667rem !important
  }

  .mr-lg-n7\/12,
  .mx-lg-n7\/12 {
    margin-right: -.8166666667rem !important
  }

  .mb-lg-n7\/12,
  .my-lg-n7\/12 {
    margin-bottom: -.8166666667rem !important
  }

  .ml-lg-n7\/12,
  .mx-lg-n7\/12 {
    margin-left: -.8166666667rem !important
  }

  .m-lg-n2\/3 {
    margin: -.9333333333rem !important
  }

  .mt-lg-n2\/3,
  .my-lg-n2\/3 {
    margin-top: -.9333333333rem !important
  }

  .mr-lg-n2\/3,
  .mx-lg-n2\/3 {
    margin-right: -.9333333333rem !important
  }

  .mb-lg-n2\/3,
  .my-lg-n2\/3 {
    margin-bottom: -.9333333333rem !important
  }

  .ml-lg-n2\/3,
  .mx-lg-n2\/3 {
    margin-left: -.9333333333rem !important
  }

  .m-lg-n0\.75 {
    margin: -1.05rem !important
  }

  .mt-lg-n0\.75,
  .my-lg-n0\.75 {
    margin-top: -1.05rem !important
  }

  .mr-lg-n0\.75,
  .mx-lg-n0\.75 {
    margin-right: -1.05rem !important
  }

  .mb-lg-n0\.75,
  .my-lg-n0\.75 {
    margin-bottom: -1.05rem !important
  }

  .ml-lg-n0\.75,
  .mx-lg-n0\.75 {
    margin-left: -1.05rem !important
  }

  .m-lg-n10\/12 {
    margin: -1.1666666667rem !important
  }

  .mt-lg-n10\/12,
  .my-lg-n10\/12 {
    margin-top: -1.1666666667rem !important
  }

  .mr-lg-n10\/12,
  .mx-lg-n10\/12 {
    margin-right: -1.1666666667rem !important
  }

  .mb-lg-n10\/12,
  .my-lg-n10\/12 {
    margin-bottom: -1.1666666667rem !important
  }

  .ml-lg-n10\/12,
  .mx-lg-n10\/12 {
    margin-left: -1.1666666667rem !important
  }

  .m-lg-n11\/12 {
    margin: -1.2833333333rem !important
  }

  .mt-lg-n11\/12,
  .my-lg-n11\/12 {
    margin-top: -1.2833333333rem !important
  }

  .mr-lg-n11\/12,
  .mx-lg-n11\/12 {
    margin-right: -1.2833333333rem !important
  }

  .mb-lg-n11\/12,
  .my-lg-n11\/12 {
    margin-bottom: -1.2833333333rem !important
  }

  .ml-lg-n11\/12,
  .mx-lg-n11\/12 {
    margin-left: -1.2833333333rem !important
  }

  .m-lg-n1 {
    margin: -1.4rem !important
  }

  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -1.4rem !important
  }

  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -1.4rem !important
  }

  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -1.4rem !important
  }

  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -1.4rem !important
  }

  .m-lg-n1\.25 {
    margin: -1.75rem !important
  }

  .mt-lg-n1\.25,
  .my-lg-n1\.25 {
    margin-top: -1.75rem !important
  }

  .mr-lg-n1\.25,
  .mx-lg-n1\.25 {
    margin-right: -1.75rem !important
  }

  .mb-lg-n1\.25,
  .my-lg-n1\.25 {
    margin-bottom: -1.75rem !important
  }

  .ml-lg-n1\.25,
  .mx-lg-n1\.25 {
    margin-left: -1.75rem !important
  }

  .m-lg-n1\.5 {
    margin: -2.1rem !important
  }

  .mt-lg-n1\.5,
  .my-lg-n1\.5 {
    margin-top: -2.1rem !important
  }

  .mr-lg-n1\.5,
  .mx-lg-n1\.5 {
    margin-right: -2.1rem !important
  }

  .mb-lg-n1\.5,
  .my-lg-n1\.5 {
    margin-bottom: -2.1rem !important
  }

  .ml-lg-n1\.5,
  .mx-lg-n1\.5 {
    margin-left: -2.1rem !important
  }

  .m-lg-n1\.75 {
    margin: -2.45rem !important
  }

  .mt-lg-n1\.75,
  .my-lg-n1\.75 {
    margin-top: -2.45rem !important
  }

  .mr-lg-n1\.75,
  .mx-lg-n1\.75 {
    margin-right: -2.45rem !important
  }

  .mb-lg-n1\.75,
  .my-lg-n1\.75 {
    margin-bottom: -2.45rem !important
  }

  .ml-lg-n1\.75,
  .mx-lg-n1\.75 {
    margin-left: -2.45rem !important
  }

  .m-lg-n2 {
    margin: -2.8rem !important
  }

  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -2.8rem !important
  }

  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -2.8rem !important
  }

  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -2.8rem !important
  }

  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -2.8rem !important
  }

  .m-lg-n2\.25 {
    margin: -3.15rem !important
  }

  .mt-lg-n2\.25,
  .my-lg-n2\.25 {
    margin-top: -3.15rem !important
  }

  .mr-lg-n2\.25,
  .mx-lg-n2\.25 {
    margin-right: -3.15rem !important
  }

  .mb-lg-n2\.25,
  .my-lg-n2\.25 {
    margin-bottom: -3.15rem !important
  }

  .ml-lg-n2\.25,
  .mx-lg-n2\.25 {
    margin-left: -3.15rem !important
  }

  .m-lg-n2\.5 {
    margin: -3.5rem !important
  }

  .mt-lg-n2\.5,
  .my-lg-n2\.5 {
    margin-top: -3.5rem !important
  }

  .mr-lg-n2\.5,
  .mx-lg-n2\.5 {
    margin-right: -3.5rem !important
  }

  .mb-lg-n2\.5,
  .my-lg-n2\.5 {
    margin-bottom: -3.5rem !important
  }

  .ml-lg-n2\.5,
  .mx-lg-n2\.5 {
    margin-left: -3.5rem !important
  }

  .m-lg-n2\.75 {
    margin: -3.85rem !important
  }

  .mt-lg-n2\.75,
  .my-lg-n2\.75 {
    margin-top: -3.85rem !important
  }

  .mr-lg-n2\.75,
  .mx-lg-n2\.75 {
    margin-right: -3.85rem !important
  }

  .mb-lg-n2\.75,
  .my-lg-n2\.75 {
    margin-bottom: -3.85rem !important
  }

  .ml-lg-n2\.75,
  .mx-lg-n2\.75 {
    margin-left: -3.85rem !important
  }

  .m-lg-n3 {
    margin: -4.2rem !important
  }

  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -4.2rem !important
  }

  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -4.2rem !important
  }

  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -4.2rem !important
  }

  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -4.2rem !important
  }

  .m-lg-n3\.5 {
    margin: -4.9rem !important
  }

  .mt-lg-n3\.5,
  .my-lg-n3\.5 {
    margin-top: -4.9rem !important
  }

  .mr-lg-n3\.5,
  .mx-lg-n3\.5 {
    margin-right: -4.9rem !important
  }

  .mb-lg-n3\.5,
  .my-lg-n3\.5 {
    margin-bottom: -4.9rem !important
  }

  .ml-lg-n3\.5,
  .mx-lg-n3\.5 {
    margin-left: -4.9rem !important
  }

  .m-lg-n4 {
    margin: -5.6rem !important
  }

  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -5.6rem !important
  }

  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -5.6rem !important
  }

  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -5.6rem !important
  }

  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -5.6rem !important
  }

  .m-lg-n5 {
    margin: -7rem !important
  }

  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -7rem !important
  }

  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -7rem !important
  }

  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -7rem !important
  }

  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -7rem !important
  }

  .m-lg-n6 {
    margin: -8.4rem !important
  }

  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -8.4rem !important
  }

  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -8.4rem !important
  }

  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -8.4rem !important
  }

  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -8.4rem !important
  }

  .m-lg-n7 {
    margin: -9.8rem !important
  }

  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -9.8rem !important
  }

  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -9.8rem !important
  }

  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -9.8rem !important
  }

  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -9.8rem !important
  }

  .m-lg-n8 {
    margin: -11.2rem !important
  }

  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -11.2rem !important
  }

  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -11.2rem !important
  }

  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -11.2rem !important
  }

  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -11.2rem !important
  }

  .m-lg-n9 {
    margin: -12.6rem !important
  }

  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -12.6rem !important
  }

  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -12.6rem !important
  }

  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -12.6rem !important
  }

  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -12.6rem !important
  }

  .m-lg-n10 {
    margin: -14rem !important
  }

  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -14rem !important
  }

  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -14rem !important
  }

  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -14rem !important
  }

  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -14rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important
  }

  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important
  }

  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important
  }
}

@media (min-width: 1280px) {
  .m-xl-0 {
    margin: 0 !important
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important
  }

  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important
  }

  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important
  }

  .m-xl-1\/12 {
    margin: .1166666667rem !important
  }

  .mt-xl-1\/12,
  .my-xl-1\/12 {
    margin-top: .1166666667rem !important
  }

  .mr-xl-1\/12,
  .mx-xl-1\/12 {
    margin-right: .1166666667rem !important
  }

  .mb-xl-1\/12,
  .my-xl-1\/12 {
    margin-bottom: .1166666667rem !important
  }

  .ml-xl-1\/12,
  .mx-xl-1\/12 {
    margin-left: .1166666667rem !important
  }

  .m-xl-0\.1 {
    margin: .14rem !important
  }

  .mt-xl-0\.1,
  .my-xl-0\.1 {
    margin-top: .14rem !important
  }

  .mr-xl-0\.1,
  .mx-xl-0\.1 {
    margin-right: .14rem !important
  }

  .mb-xl-0\.1,
  .my-xl-0\.1 {
    margin-bottom: .14rem !important
  }

  .ml-xl-0\.1,
  .mx-xl-0\.1 {
    margin-left: .14rem !important
  }

  .m-xl-0\.125 {
    margin: .175rem !important
  }

  .mt-xl-0\.125,
  .my-xl-0\.125 {
    margin-top: .175rem !important
  }

  .mr-xl-0\.125,
  .mx-xl-0\.125 {
    margin-right: .175rem !important
  }

  .mb-xl-0\.125,
  .my-xl-0\.125 {
    margin-bottom: .175rem !important
  }

  .ml-xl-0\.125,
  .mx-xl-0\.125 {
    margin-left: .175rem !important
  }

  .m-xl-1\/6 {
    margin: .2333333333rem !important
  }

  .mt-xl-1\/6,
  .my-xl-1\/6 {
    margin-top: .2333333333rem !important
  }

  .mr-xl-1\/6,
  .mx-xl-1\/6 {
    margin-right: .2333333333rem !important
  }

  .mb-xl-1\/6,
  .my-xl-1\/6 {
    margin-bottom: .2333333333rem !important
  }

  .ml-xl-1\/6,
  .mx-xl-1\/6 {
    margin-left: .2333333333rem !important
  }

  .m-xl-0\.2 {
    margin: .28rem !important
  }

  .mt-xl-0\.2,
  .my-xl-0\.2 {
    margin-top: .28rem !important
  }

  .mr-xl-0\.2,
  .mx-xl-0\.2 {
    margin-right: .28rem !important
  }

  .mb-xl-0\.2,
  .my-xl-0\.2 {
    margin-bottom: .28rem !important
  }

  .ml-xl-0\.2,
  .mx-xl-0\.2 {
    margin-left: .28rem !important
  }

  .m-xl-0\.25 {
    margin: .35rem !important
  }

  .mt-xl-0\.25,
  .my-xl-0\.25 {
    margin-top: .35rem !important
  }

  .mr-xl-0\.25,
  .mx-xl-0\.25 {
    margin-right: .35rem !important
  }

  .mb-xl-0\.25,
  .my-xl-0\.25 {
    margin-bottom: .35rem !important
  }

  .ml-xl-0\.25,
  .mx-xl-0\.25 {
    margin-left: .35rem !important
  }

  .m-xl-1\/3 {
    margin: .4666666667rem !important
  }

  .mt-xl-1\/3,
  .my-xl-1\/3 {
    margin-top: .4666666667rem !important
  }

  .mr-xl-1\/3,
  .mx-xl-1\/3 {
    margin-right: .4666666667rem !important
  }

  .mb-xl-1\/3,
  .my-xl-1\/3 {
    margin-bottom: .4666666667rem !important
  }

  .ml-xl-1\/3,
  .mx-xl-1\/3 {
    margin-left: .4666666667rem !important
  }

  .m-xl-5\/12 {
    margin: .5833333333rem !important
  }

  .mt-xl-5\/12,
  .my-xl-5\/12 {
    margin-top: .5833333333rem !important
  }

  .mr-xl-5\/12,
  .mx-xl-5\/12 {
    margin-right: .5833333333rem !important
  }

  .mb-xl-5\/12,
  .my-xl-5\/12 {
    margin-bottom: .5833333333rem !important
  }

  .ml-xl-5\/12,
  .mx-xl-5\/12 {
    margin-left: .5833333333rem !important
  }

  .m-xl-0\.5 {
    margin: .7rem !important
  }

  .mt-xl-0\.5,
  .my-xl-0\.5 {
    margin-top: .7rem !important
  }

  .mr-xl-0\.5,
  .mx-xl-0\.5 {
    margin-right: .7rem !important
  }

  .mb-xl-0\.5,
  .my-xl-0\.5 {
    margin-bottom: .7rem !important
  }

  .ml-xl-0\.5,
  .mx-xl-0\.5 {
    margin-left: .7rem !important
  }

  .m-xl-7\/12 {
    margin: .8166666667rem !important
  }

  .mt-xl-7\/12,
  .my-xl-7\/12 {
    margin-top: .8166666667rem !important
  }

  .mr-xl-7\/12,
  .mx-xl-7\/12 {
    margin-right: .8166666667rem !important
  }

  .mb-xl-7\/12,
  .my-xl-7\/12 {
    margin-bottom: .8166666667rem !important
  }

  .ml-xl-7\/12,
  .mx-xl-7\/12 {
    margin-left: .8166666667rem !important
  }

  .m-xl-2\/3 {
    margin: .9333333333rem !important
  }

  .mt-xl-2\/3,
  .my-xl-2\/3 {
    margin-top: .9333333333rem !important
  }

  .mr-xl-2\/3,
  .mx-xl-2\/3 {
    margin-right: .9333333333rem !important
  }

  .mb-xl-2\/3,
  .my-xl-2\/3 {
    margin-bottom: .9333333333rem !important
  }

  .ml-xl-2\/3,
  .mx-xl-2\/3 {
    margin-left: .9333333333rem !important
  }

  .m-xl-0\.75 {
    margin: 1.05rem !important
  }

  .mt-xl-0\.75,
  .my-xl-0\.75 {
    margin-top: 1.05rem !important
  }

  .mr-xl-0\.75,
  .mx-xl-0\.75 {
    margin-right: 1.05rem !important
  }

  .mb-xl-0\.75,
  .my-xl-0\.75 {
    margin-bottom: 1.05rem !important
  }

  .ml-xl-0\.75,
  .mx-xl-0\.75 {
    margin-left: 1.05rem !important
  }

  .m-xl-10\/12 {
    margin: 1.1666666667rem !important
  }

  .mt-xl-10\/12,
  .my-xl-10\/12 {
    margin-top: 1.1666666667rem !important
  }

  .mr-xl-10\/12,
  .mx-xl-10\/12 {
    margin-right: 1.1666666667rem !important
  }

  .mb-xl-10\/12,
  .my-xl-10\/12 {
    margin-bottom: 1.1666666667rem !important
  }

  .ml-xl-10\/12,
  .mx-xl-10\/12 {
    margin-left: 1.1666666667rem !important
  }

  .m-xl-11\/12 {
    margin: 1.2833333333rem !important
  }

  .mt-xl-11\/12,
  .my-xl-11\/12 {
    margin-top: 1.2833333333rem !important
  }

  .mr-xl-11\/12,
  .mx-xl-11\/12 {
    margin-right: 1.2833333333rem !important
  }

  .mb-xl-11\/12,
  .my-xl-11\/12 {
    margin-bottom: 1.2833333333rem !important
  }

  .ml-xl-11\/12,
  .mx-xl-11\/12 {
    margin-left: 1.2833333333rem !important
  }

  .m-xl-1 {
    margin: 1.4rem !important
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-top: 1.4rem !important
  }

  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 1.4rem !important
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 1.4rem !important
  }

  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 1.4rem !important
  }

  .m-xl-1\.25 {
    margin: 1.75rem !important
  }

  .mt-xl-1\.25,
  .my-xl-1\.25 {
    margin-top: 1.75rem !important
  }

  .mr-xl-1\.25,
  .mx-xl-1\.25 {
    margin-right: 1.75rem !important
  }

  .mb-xl-1\.25,
  .my-xl-1\.25 {
    margin-bottom: 1.75rem !important
  }

  .ml-xl-1\.25,
  .mx-xl-1\.25 {
    margin-left: 1.75rem !important
  }

  .m-xl-1\.5 {
    margin: 2.1rem !important
  }

  .mt-xl-1\.5,
  .my-xl-1\.5 {
    margin-top: 2.1rem !important
  }

  .mr-xl-1\.5,
  .mx-xl-1\.5 {
    margin-right: 2.1rem !important
  }

  .mb-xl-1\.5,
  .my-xl-1\.5 {
    margin-bottom: 2.1rem !important
  }

  .ml-xl-1\.5,
  .mx-xl-1\.5 {
    margin-left: 2.1rem !important
  }

  .m-xl-1\.75 {
    margin: 2.45rem !important
  }

  .mt-xl-1\.75,
  .my-xl-1\.75 {
    margin-top: 2.45rem !important
  }

  .mr-xl-1\.75,
  .mx-xl-1\.75 {
    margin-right: 2.45rem !important
  }

  .mb-xl-1\.75,
  .my-xl-1\.75 {
    margin-bottom: 2.45rem !important
  }

  .ml-xl-1\.75,
  .mx-xl-1\.75 {
    margin-left: 2.45rem !important
  }

  .m-xl-2 {
    margin: 2.8rem !important
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-top: 2.8rem !important
  }

  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 2.8rem !important
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 2.8rem !important
  }

  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 2.8rem !important
  }

  .m-xl-2\.25 {
    margin: 3.15rem !important
  }

  .mt-xl-2\.25,
  .my-xl-2\.25 {
    margin-top: 3.15rem !important
  }

  .mr-xl-2\.25,
  .mx-xl-2\.25 {
    margin-right: 3.15rem !important
  }

  .mb-xl-2\.25,
  .my-xl-2\.25 {
    margin-bottom: 3.15rem !important
  }

  .ml-xl-2\.25,
  .mx-xl-2\.25 {
    margin-left: 3.15rem !important
  }

  .m-xl-2\.5 {
    margin: 3.5rem !important
  }

  .mt-xl-2\.5,
  .my-xl-2\.5 {
    margin-top: 3.5rem !important
  }

  .mr-xl-2\.5,
  .mx-xl-2\.5 {
    margin-right: 3.5rem !important
  }

  .mb-xl-2\.5,
  .my-xl-2\.5 {
    margin-bottom: 3.5rem !important
  }

  .ml-xl-2\.5,
  .mx-xl-2\.5 {
    margin-left: 3.5rem !important
  }

  .m-xl-2\.75 {
    margin: 3.85rem !important
  }

  .mt-xl-2\.75,
  .my-xl-2\.75 {
    margin-top: 3.85rem !important
  }

  .mr-xl-2\.75,
  .mx-xl-2\.75 {
    margin-right: 3.85rem !important
  }

  .mb-xl-2\.75,
  .my-xl-2\.75 {
    margin-bottom: 3.85rem !important
  }

  .ml-xl-2\.75,
  .mx-xl-2\.75 {
    margin-left: 3.85rem !important
  }

  .m-xl-3 {
    margin: 4.2rem !important
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-top: 4.2rem !important
  }

  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 4.2rem !important
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 4.2rem !important
  }

  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 4.2rem !important
  }

  .m-xl-3\.5 {
    margin: 4.9rem !important
  }

  .mt-xl-3\.5,
  .my-xl-3\.5 {
    margin-top: 4.9rem !important
  }

  .mr-xl-3\.5,
  .mx-xl-3\.5 {
    margin-right: 4.9rem !important
  }

  .mb-xl-3\.5,
  .my-xl-3\.5 {
    margin-bottom: 4.9rem !important
  }

  .ml-xl-3\.5,
  .mx-xl-3\.5 {
    margin-left: 4.9rem !important
  }

  .m-xl-4 {
    margin: 5.6rem !important
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-top: 5.6rem !important
  }

  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 5.6rem !important
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 5.6rem !important
  }

  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 5.6rem !important
  }

  .m-xl-5 {
    margin: 7rem !important
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-top: 7rem !important
  }

  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 7rem !important
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 7rem !important
  }

  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 7rem !important
  }

  .m-xl-6 {
    margin: 8.4rem !important
  }

  .mt-xl-6,
  .my-xl-6 {
    margin-top: 8.4rem !important
  }

  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 8.4rem !important
  }

  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 8.4rem !important
  }

  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 8.4rem !important
  }

  .m-xl-7 {
    margin: 9.8rem !important
  }

  .mt-xl-7,
  .my-xl-7 {
    margin-top: 9.8rem !important
  }

  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 9.8rem !important
  }

  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 9.8rem !important
  }

  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 9.8rem !important
  }

  .m-xl-8 {
    margin: 11.2rem !important
  }

  .mt-xl-8,
  .my-xl-8 {
    margin-top: 11.2rem !important
  }

  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 11.2rem !important
  }

  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 11.2rem !important
  }

  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 11.2rem !important
  }

  .m-xl-9 {
    margin: 12.6rem !important
  }

  .mt-xl-9,
  .my-xl-9 {
    margin-top: 12.6rem !important
  }

  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 12.6rem !important
  }

  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 12.6rem !important
  }

  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 12.6rem !important
  }

  .m-xl-10 {
    margin: 14rem !important
  }

  .mt-xl-10,
  .my-xl-10 {
    margin-top: 14rem !important
  }

  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 14rem !important
  }

  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 14rem !important
  }

  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 14rem !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important
  }

  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important
  }

  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important
  }

  .p-xl-1\/12 {
    padding: .1166666667rem !important
  }

  .pt-xl-1\/12,
  .py-xl-1\/12 {
    padding-top: .1166666667rem !important
  }

  .pr-xl-1\/12,
  .px-xl-1\/12 {
    padding-right: .1166666667rem !important
  }

  .pb-xl-1\/12,
  .py-xl-1\/12 {
    padding-bottom: .1166666667rem !important
  }

  .pl-xl-1\/12,
  .px-xl-1\/12 {
    padding-left: .1166666667rem !important
  }

  .p-xl-0\.1 {
    padding: .14rem !important
  }

  .pt-xl-0\.1,
  .py-xl-0\.1 {
    padding-top: .14rem !important
  }

  .pr-xl-0\.1,
  .px-xl-0\.1 {
    padding-right: .14rem !important
  }

  .pb-xl-0\.1,
  .py-xl-0\.1 {
    padding-bottom: .14rem !important
  }

  .pl-xl-0\.1,
  .px-xl-0\.1 {
    padding-left: .14rem !important
  }

  .p-xl-0\.125 {
    padding: .175rem !important
  }

  .pt-xl-0\.125,
  .py-xl-0\.125 {
    padding-top: .175rem !important
  }

  .pr-xl-0\.125,
  .px-xl-0\.125 {
    padding-right: .175rem !important
  }

  .pb-xl-0\.125,
  .py-xl-0\.125 {
    padding-bottom: .175rem !important
  }

  .pl-xl-0\.125,
  .px-xl-0\.125 {
    padding-left: .175rem !important
  }

  .p-xl-1\/6 {
    padding: .2333333333rem !important
  }

  .pt-xl-1\/6,
  .py-xl-1\/6 {
    padding-top: .2333333333rem !important
  }

  .pr-xl-1\/6,
  .px-xl-1\/6 {
    padding-right: .2333333333rem !important
  }

  .pb-xl-1\/6,
  .py-xl-1\/6 {
    padding-bottom: .2333333333rem !important
  }

  .pl-xl-1\/6,
  .px-xl-1\/6 {
    padding-left: .2333333333rem !important
  }

  .p-xl-0\.2 {
    padding: .28rem !important
  }

  .pt-xl-0\.2,
  .py-xl-0\.2 {
    padding-top: .28rem !important
  }

  .pr-xl-0\.2,
  .px-xl-0\.2 {
    padding-right: .28rem !important
  }

  .pb-xl-0\.2,
  .py-xl-0\.2 {
    padding-bottom: .28rem !important
  }

  .pl-xl-0\.2,
  .px-xl-0\.2 {
    padding-left: .28rem !important
  }

  .p-xl-0\.25 {
    padding: .35rem !important
  }

  .pt-xl-0\.25,
  .py-xl-0\.25 {
    padding-top: .35rem !important
  }

  .pr-xl-0\.25,
  .px-xl-0\.25 {
    padding-right: .35rem !important
  }

  .pb-xl-0\.25,
  .py-xl-0\.25 {
    padding-bottom: .35rem !important
  }

  .pl-xl-0\.25,
  .px-xl-0\.25 {
    padding-left: .35rem !important
  }

  .p-xl-1\/3 {
    padding: .4666666667rem !important
  }

  .pt-xl-1\/3,
  .py-xl-1\/3 {
    padding-top: .4666666667rem !important
  }

  .pr-xl-1\/3,
  .px-xl-1\/3 {
    padding-right: .4666666667rem !important
  }

  .pb-xl-1\/3,
  .py-xl-1\/3 {
    padding-bottom: .4666666667rem !important
  }

  .pl-xl-1\/3,
  .px-xl-1\/3 {
    padding-left: .4666666667rem !important
  }

  .p-xl-5\/12 {
    padding: .5833333333rem !important
  }

  .pt-xl-5\/12,
  .py-xl-5\/12 {
    padding-top: .5833333333rem !important
  }

  .pr-xl-5\/12,
  .px-xl-5\/12 {
    padding-right: .5833333333rem !important
  }

  .pb-xl-5\/12,
  .py-xl-5\/12 {
    padding-bottom: .5833333333rem !important
  }

  .pl-xl-5\/12,
  .px-xl-5\/12 {
    padding-left: .5833333333rem !important
  }

  .p-xl-0\.5 {
    padding: .7rem !important
  }

  .pt-xl-0\.5,
  .py-xl-0\.5 {
    padding-top: .7rem !important
  }

  .pr-xl-0\.5,
  .px-xl-0\.5 {
    padding-right: .7rem !important
  }

  .pb-xl-0\.5,
  .py-xl-0\.5 {
    padding-bottom: .7rem !important
  }

  .pl-xl-0\.5,
  .px-xl-0\.5 {
    padding-left: .7rem !important
  }

  .p-xl-7\/12 {
    padding: .8166666667rem !important
  }

  .pt-xl-7\/12,
  .py-xl-7\/12 {
    padding-top: .8166666667rem !important
  }

  .pr-xl-7\/12,
  .px-xl-7\/12 {
    padding-right: .8166666667rem !important
  }

  .pb-xl-7\/12,
  .py-xl-7\/12 {
    padding-bottom: .8166666667rem !important
  }

  .pl-xl-7\/12,
  .px-xl-7\/12 {
    padding-left: .8166666667rem !important
  }

  .p-xl-2\/3 {
    padding: .9333333333rem !important
  }

  .pt-xl-2\/3,
  .py-xl-2\/3 {
    padding-top: .9333333333rem !important
  }

  .pr-xl-2\/3,
  .px-xl-2\/3 {
    padding-right: .9333333333rem !important
  }

  .pb-xl-2\/3,
  .py-xl-2\/3 {
    padding-bottom: .9333333333rem !important
  }

  .pl-xl-2\/3,
  .px-xl-2\/3 {
    padding-left: .9333333333rem !important
  }

  .p-xl-0\.75 {
    padding: 1.05rem !important
  }

  .pt-xl-0\.75,
  .py-xl-0\.75 {
    padding-top: 1.05rem !important
  }

  .pr-xl-0\.75,
  .px-xl-0\.75 {
    padding-right: 1.05rem !important
  }

  .pb-xl-0\.75,
  .py-xl-0\.75 {
    padding-bottom: 1.05rem !important
  }

  .pl-xl-0\.75,
  .px-xl-0\.75 {
    padding-left: 1.05rem !important
  }

  .p-xl-10\/12 {
    padding: 1.1666666667rem !important
  }

  .pt-xl-10\/12,
  .py-xl-10\/12 {
    padding-top: 1.1666666667rem !important
  }

  .pr-xl-10\/12,
  .px-xl-10\/12 {
    padding-right: 1.1666666667rem !important
  }

  .pb-xl-10\/12,
  .py-xl-10\/12 {
    padding-bottom: 1.1666666667rem !important
  }

  .pl-xl-10\/12,
  .px-xl-10\/12 {
    padding-left: 1.1666666667rem !important
  }

  .p-xl-11\/12 {
    padding: 1.2833333333rem !important
  }

  .pt-xl-11\/12,
  .py-xl-11\/12 {
    padding-top: 1.2833333333rem !important
  }

  .pr-xl-11\/12,
  .px-xl-11\/12 {
    padding-right: 1.2833333333rem !important
  }

  .pb-xl-11\/12,
  .py-xl-11\/12 {
    padding-bottom: 1.2833333333rem !important
  }

  .pl-xl-11\/12,
  .px-xl-11\/12 {
    padding-left: 1.2833333333rem !important
  }

  .p-xl-1 {
    padding: 1.4rem !important
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-top: 1.4rem !important
  }

  .pr-xl-1,
  .px-xl-1 {
    padding-right: 1.4rem !important
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 1.4rem !important
  }

  .pl-xl-1,
  .px-xl-1 {
    padding-left: 1.4rem !important
  }

  .p-xl-1\.25 {
    padding: 1.75rem !important
  }

  .pt-xl-1\.25,
  .py-xl-1\.25 {
    padding-top: 1.75rem !important
  }

  .pr-xl-1\.25,
  .px-xl-1\.25 {
    padding-right: 1.75rem !important
  }

  .pb-xl-1\.25,
  .py-xl-1\.25 {
    padding-bottom: 1.75rem !important
  }

  .pl-xl-1\.25,
  .px-xl-1\.25 {
    padding-left: 1.75rem !important
  }

  .p-xl-1\.5 {
    padding: 2.1rem !important
  }

  .pt-xl-1\.5,
  .py-xl-1\.5 {
    padding-top: 2.1rem !important
  }

  .pr-xl-1\.5,
  .px-xl-1\.5 {
    padding-right: 2.1rem !important
  }

  .pb-xl-1\.5,
  .py-xl-1\.5 {
    padding-bottom: 2.1rem !important
  }

  .pl-xl-1\.5,
  .px-xl-1\.5 {
    padding-left: 2.1rem !important
  }

  .p-xl-1\.75 {
    padding: 2.45rem !important
  }

  .pt-xl-1\.75,
  .py-xl-1\.75 {
    padding-top: 2.45rem !important
  }

  .pr-xl-1\.75,
  .px-xl-1\.75 {
    padding-right: 2.45rem !important
  }

  .pb-xl-1\.75,
  .py-xl-1\.75 {
    padding-bottom: 2.45rem !important
  }

  .pl-xl-1\.75,
  .px-xl-1\.75 {
    padding-left: 2.45rem !important
  }

  .p-xl-2 {
    padding: 2.8rem !important
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-top: 2.8rem !important
  }

  .pr-xl-2,
  .px-xl-2 {
    padding-right: 2.8rem !important
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 2.8rem !important
  }

  .pl-xl-2,
  .px-xl-2 {
    padding-left: 2.8rem !important
  }

  .p-xl-2\.25 {
    padding: 3.15rem !important
  }

  .pt-xl-2\.25,
  .py-xl-2\.25 {
    padding-top: 3.15rem !important
  }

  .pr-xl-2\.25,
  .px-xl-2\.25 {
    padding-right: 3.15rem !important
  }

  .pb-xl-2\.25,
  .py-xl-2\.25 {
    padding-bottom: 3.15rem !important
  }

  .pl-xl-2\.25,
  .px-xl-2\.25 {
    padding-left: 3.15rem !important
  }

  .p-xl-2\.5 {
    padding: 3.5rem !important
  }

  .pt-xl-2\.5,
  .py-xl-2\.5 {
    padding-top: 3.5rem !important
  }

  .pr-xl-2\.5,
  .px-xl-2\.5 {
    padding-right: 3.5rem !important
  }

  .pb-xl-2\.5,
  .py-xl-2\.5 {
    padding-bottom: 3.5rem !important
  }

  .pl-xl-2\.5,
  .px-xl-2\.5 {
    padding-left: 3.5rem !important
  }

  .p-xl-2\.75 {
    padding: 3.85rem !important
  }

  .pt-xl-2\.75,
  .py-xl-2\.75 {
    padding-top: 3.85rem !important
  }

  .pr-xl-2\.75,
  .px-xl-2\.75 {
    padding-right: 3.85rem !important
  }

  .pb-xl-2\.75,
  .py-xl-2\.75 {
    padding-bottom: 3.85rem !important
  }

  .pl-xl-2\.75,
  .px-xl-2\.75 {
    padding-left: 3.85rem !important
  }

  .p-xl-3 {
    padding: 4.2rem !important
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-top: 4.2rem !important
  }

  .pr-xl-3,
  .px-xl-3 {
    padding-right: 4.2rem !important
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 4.2rem !important
  }

  .pl-xl-3,
  .px-xl-3 {
    padding-left: 4.2rem !important
  }

  .p-xl-3\.5 {
    padding: 4.9rem !important
  }

  .pt-xl-3\.5,
  .py-xl-3\.5 {
    padding-top: 4.9rem !important
  }

  .pr-xl-3\.5,
  .px-xl-3\.5 {
    padding-right: 4.9rem !important
  }

  .pb-xl-3\.5,
  .py-xl-3\.5 {
    padding-bottom: 4.9rem !important
  }

  .pl-xl-3\.5,
  .px-xl-3\.5 {
    padding-left: 4.9rem !important
  }

  .p-xl-4 {
    padding: 5.6rem !important
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-top: 5.6rem !important
  }

  .pr-xl-4,
  .px-xl-4 {
    padding-right: 5.6rem !important
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 5.6rem !important
  }

  .pl-xl-4,
  .px-xl-4 {
    padding-left: 5.6rem !important
  }

  .p-xl-5 {
    padding: 7rem !important
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-top: 7rem !important
  }

  .pr-xl-5,
  .px-xl-5 {
    padding-right: 7rem !important
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 7rem !important
  }

  .pl-xl-5,
  .px-xl-5 {
    padding-left: 7rem !important
  }

  .p-xl-6 {
    padding: 8.4rem !important
  }

  .pt-xl-6,
  .py-xl-6 {
    padding-top: 8.4rem !important
  }

  .pr-xl-6,
  .px-xl-6 {
    padding-right: 8.4rem !important
  }

  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 8.4rem !important
  }

  .pl-xl-6,
  .px-xl-6 {
    padding-left: 8.4rem !important
  }

  .p-xl-7 {
    padding: 9.8rem !important
  }

  .pt-xl-7,
  .py-xl-7 {
    padding-top: 9.8rem !important
  }

  .pr-xl-7,
  .px-xl-7 {
    padding-right: 9.8rem !important
  }

  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 9.8rem !important
  }

  .pl-xl-7,
  .px-xl-7 {
    padding-left: 9.8rem !important
  }

  .p-xl-8 {
    padding: 11.2rem !important
  }

  .pt-xl-8,
  .py-xl-8 {
    padding-top: 11.2rem !important
  }

  .pr-xl-8,
  .px-xl-8 {
    padding-right: 11.2rem !important
  }

  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 11.2rem !important
  }

  .pl-xl-8,
  .px-xl-8 {
    padding-left: 11.2rem !important
  }

  .p-xl-9 {
    padding: 12.6rem !important
  }

  .pt-xl-9,
  .py-xl-9 {
    padding-top: 12.6rem !important
  }

  .pr-xl-9,
  .px-xl-9 {
    padding-right: 12.6rem !important
  }

  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 12.6rem !important
  }

  .pl-xl-9,
  .px-xl-9 {
    padding-left: 12.6rem !important
  }

  .p-xl-10 {
    padding: 14rem !important
  }

  .pt-xl-10,
  .py-xl-10 {
    padding-top: 14rem !important
  }

  .pr-xl-10,
  .px-xl-10 {
    padding-right: 14rem !important
  }

  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 14rem !important
  }

  .pl-xl-10,
  .px-xl-10 {
    padding-left: 14rem !important
  }

  .m-xl-n1\/12 {
    margin: -.1166666667rem !important
  }

  .mt-xl-n1\/12,
  .my-xl-n1\/12 {
    margin-top: -.1166666667rem !important
  }

  .mr-xl-n1\/12,
  .mx-xl-n1\/12 {
    margin-right: -.1166666667rem !important
  }

  .mb-xl-n1\/12,
  .my-xl-n1\/12 {
    margin-bottom: -.1166666667rem !important
  }

  .ml-xl-n1\/12,
  .mx-xl-n1\/12 {
    margin-left: -.1166666667rem !important
  }

  .m-xl-n0\.1 {
    margin: -.14rem !important
  }

  .mt-xl-n0\.1,
  .my-xl-n0\.1 {
    margin-top: -.14rem !important
  }

  .mr-xl-n0\.1,
  .mx-xl-n0\.1 {
    margin-right: -.14rem !important
  }

  .mb-xl-n0\.1,
  .my-xl-n0\.1 {
    margin-bottom: -.14rem !important
  }

  .ml-xl-n0\.1,
  .mx-xl-n0\.1 {
    margin-left: -.14rem !important
  }

  .m-xl-n0\.125 {
    margin: -.175rem !important
  }

  .mt-xl-n0\.125,
  .my-xl-n0\.125 {
    margin-top: -.175rem !important
  }

  .mr-xl-n0\.125,
  .mx-xl-n0\.125 {
    margin-right: -.175rem !important
  }

  .mb-xl-n0\.125,
  .my-xl-n0\.125 {
    margin-bottom: -.175rem !important
  }

  .ml-xl-n0\.125,
  .mx-xl-n0\.125 {
    margin-left: -.175rem !important
  }

  .m-xl-n1\/6 {
    margin: -.2333333333rem !important
  }

  .mt-xl-n1\/6,
  .my-xl-n1\/6 {
    margin-top: -.2333333333rem !important
  }

  .mr-xl-n1\/6,
  .mx-xl-n1\/6 {
    margin-right: -.2333333333rem !important
  }

  .mb-xl-n1\/6,
  .my-xl-n1\/6 {
    margin-bottom: -.2333333333rem !important
  }

  .ml-xl-n1\/6,
  .mx-xl-n1\/6 {
    margin-left: -.2333333333rem !important
  }

  .m-xl-n0\.2 {
    margin: -.28rem !important
  }

  .mt-xl-n0\.2,
  .my-xl-n0\.2 {
    margin-top: -.28rem !important
  }

  .mr-xl-n0\.2,
  .mx-xl-n0\.2 {
    margin-right: -.28rem !important
  }

  .mb-xl-n0\.2,
  .my-xl-n0\.2 {
    margin-bottom: -.28rem !important
  }

  .ml-xl-n0\.2,
  .mx-xl-n0\.2 {
    margin-left: -.28rem !important
  }

  .m-xl-n0\.25 {
    margin: -.35rem !important
  }

  .mt-xl-n0\.25,
  .my-xl-n0\.25 {
    margin-top: -.35rem !important
  }

  .mr-xl-n0\.25,
  .mx-xl-n0\.25 {
    margin-right: -.35rem !important
  }

  .mb-xl-n0\.25,
  .my-xl-n0\.25 {
    margin-bottom: -.35rem !important
  }

  .ml-xl-n0\.25,
  .mx-xl-n0\.25 {
    margin-left: -.35rem !important
  }

  .m-xl-n1\/3 {
    margin: -.4666666667rem !important
  }

  .mt-xl-n1\/3,
  .my-xl-n1\/3 {
    margin-top: -.4666666667rem !important
  }

  .mr-xl-n1\/3,
  .mx-xl-n1\/3 {
    margin-right: -.4666666667rem !important
  }

  .mb-xl-n1\/3,
  .my-xl-n1\/3 {
    margin-bottom: -.4666666667rem !important
  }

  .ml-xl-n1\/3,
  .mx-xl-n1\/3 {
    margin-left: -.4666666667rem !important
  }

  .m-xl-n5\/12 {
    margin: -.5833333333rem !important
  }

  .mt-xl-n5\/12,
  .my-xl-n5\/12 {
    margin-top: -.5833333333rem !important
  }

  .mr-xl-n5\/12,
  .mx-xl-n5\/12 {
    margin-right: -.5833333333rem !important
  }

  .mb-xl-n5\/12,
  .my-xl-n5\/12 {
    margin-bottom: -.5833333333rem !important
  }

  .ml-xl-n5\/12,
  .mx-xl-n5\/12 {
    margin-left: -.5833333333rem !important
  }

  .m-xl-n0\.5 {
    margin: -.7rem !important
  }

  .mt-xl-n0\.5,
  .my-xl-n0\.5 {
    margin-top: -.7rem !important
  }

  .mr-xl-n0\.5,
  .mx-xl-n0\.5 {
    margin-right: -.7rem !important
  }

  .mb-xl-n0\.5,
  .my-xl-n0\.5 {
    margin-bottom: -.7rem !important
  }

  .ml-xl-n0\.5,
  .mx-xl-n0\.5 {
    margin-left: -.7rem !important
  }

  .m-xl-n7\/12 {
    margin: -.8166666667rem !important
  }

  .mt-xl-n7\/12,
  .my-xl-n7\/12 {
    margin-top: -.8166666667rem !important
  }

  .mr-xl-n7\/12,
  .mx-xl-n7\/12 {
    margin-right: -.8166666667rem !important
  }

  .mb-xl-n7\/12,
  .my-xl-n7\/12 {
    margin-bottom: -.8166666667rem !important
  }

  .ml-xl-n7\/12,
  .mx-xl-n7\/12 {
    margin-left: -.8166666667rem !important
  }

  .m-xl-n2\/3 {
    margin: -.9333333333rem !important
  }

  .mt-xl-n2\/3,
  .my-xl-n2\/3 {
    margin-top: -.9333333333rem !important
  }

  .mr-xl-n2\/3,
  .mx-xl-n2\/3 {
    margin-right: -.9333333333rem !important
  }

  .mb-xl-n2\/3,
  .my-xl-n2\/3 {
    margin-bottom: -.9333333333rem !important
  }

  .ml-xl-n2\/3,
  .mx-xl-n2\/3 {
    margin-left: -.9333333333rem !important
  }

  .m-xl-n0\.75 {
    margin: -1.05rem !important
  }

  .mt-xl-n0\.75,
  .my-xl-n0\.75 {
    margin-top: -1.05rem !important
  }

  .mr-xl-n0\.75,
  .mx-xl-n0\.75 {
    margin-right: -1.05rem !important
  }

  .mb-xl-n0\.75,
  .my-xl-n0\.75 {
    margin-bottom: -1.05rem !important
  }

  .ml-xl-n0\.75,
  .mx-xl-n0\.75 {
    margin-left: -1.05rem !important
  }

  .m-xl-n10\/12 {
    margin: -1.1666666667rem !important
  }

  .mt-xl-n10\/12,
  .my-xl-n10\/12 {
    margin-top: -1.1666666667rem !important
  }

  .mr-xl-n10\/12,
  .mx-xl-n10\/12 {
    margin-right: -1.1666666667rem !important
  }

  .mb-xl-n10\/12,
  .my-xl-n10\/12 {
    margin-bottom: -1.1666666667rem !important
  }

  .ml-xl-n10\/12,
  .mx-xl-n10\/12 {
    margin-left: -1.1666666667rem !important
  }

  .m-xl-n11\/12 {
    margin: -1.2833333333rem !important
  }

  .mt-xl-n11\/12,
  .my-xl-n11\/12 {
    margin-top: -1.2833333333rem !important
  }

  .mr-xl-n11\/12,
  .mx-xl-n11\/12 {
    margin-right: -1.2833333333rem !important
  }

  .mb-xl-n11\/12,
  .my-xl-n11\/12 {
    margin-bottom: -1.2833333333rem !important
  }

  .ml-xl-n11\/12,
  .mx-xl-n11\/12 {
    margin-left: -1.2833333333rem !important
  }

  .m-xl-n1 {
    margin: -1.4rem !important
  }

  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -1.4rem !important
  }

  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -1.4rem !important
  }

  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -1.4rem !important
  }

  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -1.4rem !important
  }

  .m-xl-n1\.25 {
    margin: -1.75rem !important
  }

  .mt-xl-n1\.25,
  .my-xl-n1\.25 {
    margin-top: -1.75rem !important
  }

  .mr-xl-n1\.25,
  .mx-xl-n1\.25 {
    margin-right: -1.75rem !important
  }

  .mb-xl-n1\.25,
  .my-xl-n1\.25 {
    margin-bottom: -1.75rem !important
  }

  .ml-xl-n1\.25,
  .mx-xl-n1\.25 {
    margin-left: -1.75rem !important
  }

  .m-xl-n1\.5 {
    margin: -2.1rem !important
  }

  .mt-xl-n1\.5,
  .my-xl-n1\.5 {
    margin-top: -2.1rem !important
  }

  .mr-xl-n1\.5,
  .mx-xl-n1\.5 {
    margin-right: -2.1rem !important
  }

  .mb-xl-n1\.5,
  .my-xl-n1\.5 {
    margin-bottom: -2.1rem !important
  }

  .ml-xl-n1\.5,
  .mx-xl-n1\.5 {
    margin-left: -2.1rem !important
  }

  .m-xl-n1\.75 {
    margin: -2.45rem !important
  }

  .mt-xl-n1\.75,
  .my-xl-n1\.75 {
    margin-top: -2.45rem !important
  }

  .mr-xl-n1\.75,
  .mx-xl-n1\.75 {
    margin-right: -2.45rem !important
  }

  .mb-xl-n1\.75,
  .my-xl-n1\.75 {
    margin-bottom: -2.45rem !important
  }

  .ml-xl-n1\.75,
  .mx-xl-n1\.75 {
    margin-left: -2.45rem !important
  }

  .m-xl-n2 {
    margin: -2.8rem !important
  }

  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -2.8rem !important
  }

  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -2.8rem !important
  }

  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -2.8rem !important
  }

  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -2.8rem !important
  }

  .m-xl-n2\.25 {
    margin: -3.15rem !important
  }

  .mt-xl-n2\.25,
  .my-xl-n2\.25 {
    margin-top: -3.15rem !important
  }

  .mr-xl-n2\.25,
  .mx-xl-n2\.25 {
    margin-right: -3.15rem !important
  }

  .mb-xl-n2\.25,
  .my-xl-n2\.25 {
    margin-bottom: -3.15rem !important
  }

  .ml-xl-n2\.25,
  .mx-xl-n2\.25 {
    margin-left: -3.15rem !important
  }

  .m-xl-n2\.5 {
    margin: -3.5rem !important
  }

  .mt-xl-n2\.5,
  .my-xl-n2\.5 {
    margin-top: -3.5rem !important
  }

  .mr-xl-n2\.5,
  .mx-xl-n2\.5 {
    margin-right: -3.5rem !important
  }

  .mb-xl-n2\.5,
  .my-xl-n2\.5 {
    margin-bottom: -3.5rem !important
  }

  .ml-xl-n2\.5,
  .mx-xl-n2\.5 {
    margin-left: -3.5rem !important
  }

  .m-xl-n2\.75 {
    margin: -3.85rem !important
  }

  .mt-xl-n2\.75,
  .my-xl-n2\.75 {
    margin-top: -3.85rem !important
  }

  .mr-xl-n2\.75,
  .mx-xl-n2\.75 {
    margin-right: -3.85rem !important
  }

  .mb-xl-n2\.75,
  .my-xl-n2\.75 {
    margin-bottom: -3.85rem !important
  }

  .ml-xl-n2\.75,
  .mx-xl-n2\.75 {
    margin-left: -3.85rem !important
  }

  .m-xl-n3 {
    margin: -4.2rem !important
  }

  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -4.2rem !important
  }

  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -4.2rem !important
  }

  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -4.2rem !important
  }

  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -4.2rem !important
  }

  .m-xl-n3\.5 {
    margin: -4.9rem !important
  }

  .mt-xl-n3\.5,
  .my-xl-n3\.5 {
    margin-top: -4.9rem !important
  }

  .mr-xl-n3\.5,
  .mx-xl-n3\.5 {
    margin-right: -4.9rem !important
  }

  .mb-xl-n3\.5,
  .my-xl-n3\.5 {
    margin-bottom: -4.9rem !important
  }

  .ml-xl-n3\.5,
  .mx-xl-n3\.5 {
    margin-left: -4.9rem !important
  }

  .m-xl-n4 {
    margin: -5.6rem !important
  }

  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -5.6rem !important
  }

  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -5.6rem !important
  }

  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -5.6rem !important
  }

  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -5.6rem !important
  }

  .m-xl-n5 {
    margin: -7rem !important
  }

  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -7rem !important
  }

  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -7rem !important
  }

  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -7rem !important
  }

  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -7rem !important
  }

  .m-xl-n6 {
    margin: -8.4rem !important
  }

  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -8.4rem !important
  }

  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -8.4rem !important
  }

  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -8.4rem !important
  }

  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -8.4rem !important
  }

  .m-xl-n7 {
    margin: -9.8rem !important
  }

  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -9.8rem !important
  }

  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -9.8rem !important
  }

  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -9.8rem !important
  }

  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -9.8rem !important
  }

  .m-xl-n8 {
    margin: -11.2rem !important
  }

  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -11.2rem !important
  }

  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -11.2rem !important
  }

  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -11.2rem !important
  }

  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -11.2rem !important
  }

  .m-xl-n9 {
    margin: -12.6rem !important
  }

  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -12.6rem !important
  }

  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -12.6rem !important
  }

  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -12.6rem !important
  }

  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -12.6rem !important
  }

  .m-xl-n10 {
    margin: -14rem !important
  }

  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -14rem !important
  }

  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -14rem !important
  }

  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -14rem !important
  }

  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -14rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important
  }

  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important
  }

  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important
  }
}

.mt-gutter {
  margin-top: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .mt-gutter {
    margin-top: 1.4rem !important
  }
}

.mt-ngutter {
  margin-top: -3.3333333333vw !important
}

@media (min-width: 720px) {
  .mt-ngutter {
    margin-top: -1.4rem !important
  }
}

.pt-gutter {
  padding-top: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .pt-gutter {
    padding-top: 1.4rem !important
  }
}

.mt-narrow-gutter {
  margin-top: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .mt-narrow-gutter {
    margin-top: .7rem !important
  }
}

.mt-nnarrow-gutter {
  margin-top: -1.6666666667vw !important
}

@media (min-width: 720px) {
  .mt-nnarrow-gutter {
    margin-top: -.7rem !important
  }
}

.pt-narrow-gutter {
  padding-top: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .pt-narrow-gutter {
    padding-top: .7rem !important
  }
}

.mt-thin-gutter {
  margin-top: .5rem !important
}

.mt-nthin-gutter {
  margin-top: -.5rem !important
}

.pt-thin-gutter {
  padding-top: .5rem !important
}

.mt-hairline-gutter {
  margin-top: 3px !important
}

@media (min-width: 720px) {
  .mt-hairline-gutter {
    margin-top: 4px !important
  }
}

.mt-nhairline-gutter {
  margin-top: -3px !important
}

@media (min-width: 720px) {
  .mt-nhairline-gutter {
    margin-top: -4px !important
  }
}

.pt-hairline-gutter {
  padding-top: 3px !important
}

@media (min-width: 720px) {
  .pt-hairline-gutter {
    padding-top: 4px !important
  }
}

@media (min-width: 480px) {
  .mt-sm-gutter {
    margin-top: 3.3333333333vw !important
  }

  .mt-sm-ngutter {
    margin-top: -3.3333333333vw !important
  }

  .mt-sm-narrow-gutter {
    margin-top: 1.6666666667vw !important
  }

  .mt-sm-nnarrow-gutter {
    margin-top: -1.6666666667vw !important
  }

  .mt-sm-hairline-gutter {
    margin-top: 3px !important
  }

  .mt-sm-nhairline-gutter {
    margin-top: -3px !important
  }
}

@media (min-width: 720px) {
  .mt-md-gutter {
    margin-top: 1.4rem !important
  }

  .mt-md-ngutter {
    margin-top: -1.4rem !important
  }

  .mt-md-narrow-gutter {
    margin-top: .7rem !important
  }

  .mt-md-nnarrow-gutter {
    margin-top: -.7rem !important
  }

  .mt-md-hairline-gutter {
    margin-top: 4px !important
  }

  .mt-md-nhairline-gutter {
    margin-top: -4px !important
  }
}

@media (min-width: 960px) {
  .mt-lg-gutter {
    margin-top: 1.4rem !important
  }

  .mt-lg-ngutter {
    margin-top: -1.4rem !important
  }

  .mt-lg-narrow-gutter {
    margin-top: .7rem !important
  }

  .mt-lg-nnarrow-gutter {
    margin-top: -.7rem !important
  }

  .mt-lg-hairline-gutter {
    margin-top: 4px !important
  }

  .mt-lg-nhairline-gutter {
    margin-top: -4px !important
  }
}

@media (min-width: 1280px) {
  .mt-xl-gutter {
    margin-top: 1.4rem !important
  }

  .mt-xl-ngutter {
    margin-top: -1.4rem !important
  }

  .mt-xl-narrow-gutter {
    margin-top: .7rem !important
  }

  .mt-xl-nnarrow-gutter {
    margin-top: -.7rem !important
  }

  .mt-xl-hairline-gutter {
    margin-top: 4px !important
  }

  .mt-xl-nhairline-gutter {
    margin-top: -4px !important
  }
}

.mb-gutter {
  margin-bottom: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .mb-gutter {
    margin-bottom: 1.4rem !important
  }
}

.mb-ngutter {
  margin-bottom: -3.3333333333vw !important
}

@media (min-width: 720px) {
  .mb-ngutter {
    margin-bottom: -1.4rem !important
  }
}

.pb-gutter {
  padding-bottom: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .pb-gutter {
    padding-bottom: 1.4rem !important
  }
}

.mb-narrow-gutter {
  margin-bottom: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .mb-narrow-gutter {
    margin-bottom: .7rem !important
  }
}

.mb-nnarrow-gutter {
  margin-bottom: -1.6666666667vw !important
}

@media (min-width: 720px) {
  .mb-nnarrow-gutter {
    margin-bottom: -.7rem !important
  }
}

.pb-narrow-gutter {
  padding-bottom: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .pb-narrow-gutter {
    padding-bottom: .7rem !important
  }
}

.mb-thin-gutter {
  margin-bottom: .5rem !important
}

.mb-nthin-gutter {
  margin-bottom: -.5rem !important
}

.pb-thin-gutter {
  padding-bottom: .5rem !important
}

.mb-hairline-gutter {
  margin-bottom: 3px !important
}

@media (min-width: 720px) {
  .mb-hairline-gutter {
    margin-bottom: 4px !important
  }
}

.mb-nhairline-gutter {
  margin-bottom: -3px !important
}

@media (min-width: 720px) {
  .mb-nhairline-gutter {
    margin-bottom: -4px !important
  }
}

.pb-hairline-gutter {
  padding-bottom: 3px !important
}

@media (min-width: 720px) {
  .pb-hairline-gutter {
    padding-bottom: 4px !important
  }
}

@media (min-width: 480px) {
  .mb-sm-gutter {
    margin-bottom: 3.3333333333vw !important
  }

  .mb-sm-ngutter {
    margin-bottom: -3.3333333333vw !important
  }

  .mb-sm-narrow-gutter {
    margin-bottom: 1.6666666667vw !important
  }

  .mb-sm-nnarrow-gutter {
    margin-bottom: -1.6666666667vw !important
  }

  .mb-sm-hairline-gutter {
    margin-bottom: 3px !important
  }

  .mb-sm-nhairline-gutter {
    margin-bottom: -3px !important
  }
}

@media (min-width: 720px) {
  .mb-md-gutter {
    margin-bottom: 1.4rem !important
  }

  .mb-md-ngutter {
    margin-bottom: -1.4rem !important
  }

  .mb-md-narrow-gutter {
    margin-bottom: .7rem !important
  }

  .mb-md-nnarrow-gutter {
    margin-bottom: -.7rem !important
  }

  .mb-md-hairline-gutter {
    margin-bottom: 4px !important
  }

  .mb-md-nhairline-gutter {
    margin-bottom: -4px !important
  }
}

@media (min-width: 960px) {
  .mb-lg-gutter {
    margin-bottom: 1.4rem !important
  }

  .mb-lg-ngutter {
    margin-bottom: -1.4rem !important
  }

  .mb-lg-narrow-gutter {
    margin-bottom: .7rem !important
  }

  .mb-lg-nnarrow-gutter {
    margin-bottom: -.7rem !important
  }

  .mb-lg-hairline-gutter {
    margin-bottom: 4px !important
  }

  .mb-lg-nhairline-gutter {
    margin-bottom: -4px !important
  }
}

@media (min-width: 1280px) {
  .mb-xl-gutter {
    margin-bottom: 1.4rem !important
  }

  .mb-xl-ngutter {
    margin-bottom: -1.4rem !important
  }

  .mb-xl-narrow-gutter {
    margin-bottom: .7rem !important
  }

  .mb-xl-nnarrow-gutter {
    margin-bottom: -.7rem !important
  }

  .mb-xl-hairline-gutter {
    margin-bottom: 4px !important
  }

  .mb-xl-nhairline-gutter {
    margin-bottom: -4px !important
  }
}

.mr-gutter {
  margin-right: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .mr-gutter {
    margin-right: 1.4rem !important
  }
}

.mr-ngutter {
  margin-right: -3.3333333333vw !important
}

@media (min-width: 720px) {
  .mr-ngutter {
    margin-right: -1.4rem !important
  }
}

.pr-gutter {
  padding-right: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .pr-gutter {
    padding-right: 1.4rem !important
  }
}

.mr-narrow-gutter {
  margin-right: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .mr-narrow-gutter {
    margin-right: .7rem !important
  }
}

.mr-nnarrow-gutter {
  margin-right: -1.6666666667vw !important
}

@media (min-width: 720px) {
  .mr-nnarrow-gutter {
    margin-right: -.7rem !important
  }
}

.pr-narrow-gutter {
  padding-right: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .pr-narrow-gutter {
    padding-right: .7rem !important
  }
}

.mr-thin-gutter {
  margin-right: .5rem !important
}

.mr-nthin-gutter {
  margin-right: -.5rem !important
}

.pr-thin-gutter {
  padding-right: .5rem !important
}

.mr-hairline-gutter {
  margin-right: 3px !important
}

@media (min-width: 720px) {
  .mr-hairline-gutter {
    margin-right: 4px !important
  }
}

.mr-nhairline-gutter {
  margin-right: -3px !important
}

@media (min-width: 720px) {
  .mr-nhairline-gutter {
    margin-right: -4px !important
  }
}

.pr-hairline-gutter {
  padding-right: 3px !important
}

@media (min-width: 720px) {
  .pr-hairline-gutter {
    padding-right: 4px !important
  }
}

@media (min-width: 480px) {
  .mr-sm-gutter {
    margin-right: 3.3333333333vw !important
  }

  .mr-sm-ngutter {
    margin-right: -3.3333333333vw !important
  }

  .mr-sm-narrow-gutter {
    margin-right: 1.6666666667vw !important
  }

  .mr-sm-nnarrow-gutter {
    margin-right: -1.6666666667vw !important
  }

  .mr-sm-hairline-gutter {
    margin-right: 3px !important
  }

  .mr-sm-nhairline-gutter {
    margin-right: -3px !important
  }
}

@media (min-width: 720px) {
  .mr-md-gutter {
    margin-right: 1.4rem !important
  }

  .mr-md-ngutter {
    margin-right: -1.4rem !important
  }

  .mr-md-narrow-gutter {
    margin-right: .7rem !important
  }

  .mr-md-nnarrow-gutter {
    margin-right: -.7rem !important
  }

  .mr-md-hairline-gutter {
    margin-right: 4px !important
  }

  .mr-md-nhairline-gutter {
    margin-right: -4px !important
  }
}

@media (min-width: 960px) {
  .mr-lg-gutter {
    margin-right: 1.4rem !important
  }

  .mr-lg-ngutter {
    margin-right: -1.4rem !important
  }

  .mr-lg-narrow-gutter {
    margin-right: .7rem !important
  }

  .mr-lg-nnarrow-gutter {
    margin-right: -.7rem !important
  }

  .mr-lg-hairline-gutter {
    margin-right: 4px !important
  }

  .mr-lg-nhairline-gutter {
    margin-right: -4px !important
  }
}

@media (min-width: 1280px) {
  .mr-xl-gutter {
    margin-right: 1.4rem !important
  }

  .mr-xl-ngutter {
    margin-right: -1.4rem !important
  }

  .mr-xl-narrow-gutter {
    margin-right: .7rem !important
  }

  .mr-xl-nnarrow-gutter {
    margin-right: -.7rem !important
  }

  .mr-xl-hairline-gutter {
    margin-right: 4px !important
  }

  .mr-xl-nhairline-gutter {
    margin-right: -4px !important
  }
}

.ml-gutter {
  margin-left: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .ml-gutter {
    margin-left: 1.4rem !important
  }
}

.ml-ngutter {
  margin-left: -3.3333333333vw !important
}

@media (min-width: 720px) {
  .ml-ngutter {
    margin-left: -1.4rem !important
  }
}

.pl-gutter {
  padding-left: 3.3333333333vw !important
}

@media (min-width: 720px) {
  .pl-gutter {
    padding-left: 1.4rem !important
  }
}

.ml-narrow-gutter {
  margin-left: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .ml-narrow-gutter {
    margin-left: .7rem !important
  }
}

.ml-nnarrow-gutter {
  margin-left: -1.6666666667vw !important
}

@media (min-width: 720px) {
  .ml-nnarrow-gutter {
    margin-left: -.7rem !important
  }
}

.pl-narrow-gutter {
  padding-left: 1.6666666667vw !important
}

@media (min-width: 720px) {
  .pl-narrow-gutter {
    padding-left: .7rem !important
  }
}

.ml-thin-gutter {
  margin-left: .5rem !important
}

.ml-nthin-gutter {
  margin-left: -.5rem !important
}

.pl-thin-gutter {
  padding-left: .5rem !important
}

.ml-hairline-gutter {
  margin-left: 3px !important
}

@media (min-width: 720px) {
  .ml-hairline-gutter {
    margin-left: 4px !important
  }
}

.ml-nhairline-gutter {
  margin-left: -3px !important
}

@media (min-width: 720px) {
  .ml-nhairline-gutter {
    margin-left: -4px !important
  }
}

.pl-hairline-gutter {
  padding-left: 3px !important
}

@media (min-width: 720px) {
  .pl-hairline-gutter {
    padding-left: 4px !important
  }
}

@media (min-width: 480px) {
  .ml-sm-gutter {
    margin-left: 3.3333333333vw !important
  }

  .ml-sm-ngutter {
    margin-left: -3.3333333333vw !important
  }

  .ml-sm-narrow-gutter {
    margin-left: 1.6666666667vw !important
  }

  .ml-sm-nnarrow-gutter {
    margin-left: -1.6666666667vw !important
  }

  .ml-sm-hairline-gutter {
    margin-left: 3px !important
  }

  .ml-sm-nhairline-gutter {
    margin-left: -3px !important
  }
}

@media (min-width: 720px) {
  .ml-md-gutter {
    margin-left: 1.4rem !important
  }

  .ml-md-ngutter {
    margin-left: -1.4rem !important
  }

  .ml-md-narrow-gutter {
    margin-left: .7rem !important
  }

  .ml-md-nnarrow-gutter {
    margin-left: -.7rem !important
  }

  .ml-md-hairline-gutter {
    margin-left: 4px !important
  }

  .ml-md-nhairline-gutter {
    margin-left: -4px !important
  }
}

@media (min-width: 960px) {
  .ml-lg-gutter {
    margin-left: 1.4rem !important
  }

  .ml-lg-ngutter {
    margin-left: -1.4rem !important
  }

  .ml-lg-narrow-gutter {
    margin-left: .7rem !important
  }

  .ml-lg-nnarrow-gutter {
    margin-left: -.7rem !important
  }

  .ml-lg-hairline-gutter {
    margin-left: 4px !important
  }

  .ml-lg-nhairline-gutter {
    margin-left: -4px !important
  }
}

@media (min-width: 1280px) {
  .ml-xl-gutter {
    margin-left: 1.4rem !important
  }

  .ml-xl-ngutter {
    margin-left: -1.4rem !important
  }

  .ml-xl-narrow-gutter {
    margin-left: .7rem !important
  }

  .ml-xl-nnarrow-gutter {
    margin-left: -.7rem !important
  }

  .ml-xl-hairline-gutter {
    margin-left: 4px !important
  }

  .ml-xl-nhairline-gutter {
    margin-left: -4px !important
  }
}

.mr-row,
.mx-row {
  margin-right: -1.6666666667vw !important
}

@media (min-width: 720px) {

  .mr-row,
  .mx-row {
    margin-right: -.7rem !important
  }
}

.ml-row,
.mx-row {
  margin-left: -1.6666666667vw !important
}

@media (min-width: 720px) {

  .ml-row,
  .mx-row {
    margin-left: -.7rem !important
  }
}

.pr-col,
.px-col {
  padding-right: 1.6666666667vw !important
}

@media (min-width: 720px) {

  .pr-col,
  .px-col {
    padding-right: .7rem !important
  }
}

.pl-col,
.px-col {
  padding-left: 1.6666666667vw !important
}

@media (min-width: 720px) {

  .pl-col,
  .px-col {
    padding-left: .7rem !important
  }
}

.mr-col,
.mx-col {
  margin-right: 1.6666666667vw !important
}

@media (min-width: 720px) {

  .mr-col,
  .mx-col {
    margin-right: .7rem !important
  }
}

.ml-col,
.mx-col {
  margin-left: 1.6666666667vw !important
}

@media (min-width: 720px) {

  .ml-col,
  .mx-col {
    margin-left: .7rem !important
  }
}

.pr-container,
.px-container {
  padding-right: 3.3333333333%
}

@media (min-width: 720px) {

  .pr-container,
  .px-container {
    padding-right: 3.3333333333%
  }
}

.pl-container,
.px-container {
  padding-left: 3.3333333333%
}

@media (min-width: 720px) {

  .pl-container,
  .px-container {
    padding-left: 3.3333333333%
  }
}

.font-sans-serif {
  font-family: Montserrat, Helvetica, sans-serif !important
}

.font-serif {
  font-family: Montserrat, Georgia, serif !important
}

.font-monospace {
  font-family: Montserrat, SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace !important
}

.font-normal {
  font-weight: normal !important
}

.font-bold {
  font-weight: bold !important
}

.font-italic {
  font-style: italic !important
}

.font-xxxlarge {
  font-size: 2.3333333333rem;
  line-height: 1.05;
  letter-spacing: -0.02em
}

@media (min-width: 480px) {
  .font-xxxlarge {
    font-size: 2.6666666667rem
  }
}

@media (min-width: 960px) {
  .font-xxxlarge {
    font-size: 3rem
  }
}

.font-xxlarge {
  font-size: 2rem;
  line-height: 1.1;
  letter-spacing: -0.0175em
}

@media (min-width: 960px) {
  .font-xxlarge {
    font-size: 2.25rem;
    line-height: 1.05
  }
}

@media (min-width: 1280px) {
  .font-xxlarge {
    font-size: 2.5rem
  }
}

.font-xlarge {
  font-size: 1.5rem;
  line-height: 1.2;
  letter-spacing: -0.01em
}

@media (min-width: 960px) {
  .font-xlarge {
    font-size: 1.6666666667rem
  }
}

.font-xlarge-small {
  font-size: 1.3333333333rem;
  line-height: 1.25;
  letter-spacing: -0.0075em
}

@media (min-width: 960px) {
  .font-xlarge-small {
    font-size: 1.5rem
  }
}

.font-large {
  font-size: 1.1666666667rem;
  line-height: 1.3;
  letter-spacing: -0.005em
}

@media (min-width: 960px) {
  .font-large {
    font-size: 1.25rem
  }
}

.font-base {
  font-size: 1rem
}

.font-small {
  font-size: .9166666667rem
}

.font-xsmall {
  font-size: .8333333333rem
}

.font-xxsmall {
  font-size: .75rem
}

.font-xxxsmall {
  font-size: .6666666667rem
}

.letter-spacing-n1 {
  letter-spacing: -.01em !important
}

.letter-spacing-n0\.5 {
  letter-spacing: -.005em !important
}

.letter-spacing-0 {
  letter-spacing: 0 !important
}

.letter-spacing-0\.5 {
  letter-spacing: .005em !important
}

.letter-spacing-1 {
  letter-spacing: .01em !important
}

.line-height-1 {
  line-height: 1 !important
}

.line-height-1\.05 {
  line-height: 1.05 !important
}

.line-height-1\.1 {
  line-height: 1.1 !important
}

.line-height-1\.15 {
  line-height: 1.15 !important
}

.line-height-1\.2 {
  line-height: 1.2 !important
}

.line-height-1\.25 {
  line-height: 1.25 !important
}

.line-height-1\.3 {
  line-height: 1.3 !important
}

.line-height-1\.35 {
  line-height: 1.35 !important
}

.line-height-1\.4 {
  line-height: 1.4 !important
}

.line-height-1\.45 {
  line-height: 1.45 !important
}

.line-height-1\.5 {
  line-height: 1.5 !important
}

.line-height-1\.55 {
  line-height: 1.55 !important
}

.line-height-1\.6 {
  line-height: 1.6 !important
}

.line-height-1\.65 {
  line-height: 1.65 !important
}

.line-height-1\.7 {
  line-height: 1.7 !important
}

.line-height-1\.75 {
  line-height: 1.75 !important
}

.line-height-1\.8 {
  line-height: 1.8 !important
}

.line-height-1\.85 {
  line-height: 1.85 !important
}

.line-height-1\.9 {
  line-height: 1.9 !important
}

.line-height-1\.95 {
  line-height: 1.95 !important
}

.line-height-2 {
  line-height: 2 !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.text-indent {
  text-indent: 1.25em
}

.text-outdent {
  text-indent: -1.25em;
  padding-left: 1.25em !important
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

@media (min-width: 480px) {
  .text-sm-left {
    text-align: left !important
  }

  .text-sm-right {
    text-align: right !important
  }

  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width: 720px) {
  .text-md-left {
    text-align: left !important
  }

  .text-md-right {
    text-align: right !important
  }

  .text-md-center {
    text-align: center !important
  }
}

@media (min-width: 960px) {
  .text-lg-left {
    text-align: left !important
  }

  .text-lg-right {
    text-align: right !important
  }

  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width: 1280px) {
  .text-xl-left {
    text-align: left !important
  }

  .text-xl-right {
    text-align: right !important
  }

  .text-xl-center {
    text-align: center !important
  }
}

.uppercase {
  text-transform: uppercase
}

.font-tnum {
  font-variant-numeric: tabular-nums
}

.font-pnum {
  font-variant-numeric: proportional-nums
}

.hangpunc {
  position: relative
}

.hangpunc:before {
  content: "“";
  position: absolute;
  text-align: right;
  left: -0.5em;
  width: 0.5em
}

.balance-text {
  text-wrap: balance
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5)
}

.token.punctuation {
  color: rgba(0, 0, 0, 0.4);
  color: rgba(var(--fg), 0.4)
}

.token.namespace {
  opacity: 0.7
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #BD1C5F
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #00A67D
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: rgba(0, 0, 0, 0.6);
  color: rgba(var(--fg), 0.6);
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05)
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #0082D0
}

.token.function,
.token.class-name {
  color: #F22C3D
}

.token.regex,
.token.important,
.token.variable {
  color: #EA9100
}

.token.important,
.token.bold {
  font-weight: bold
}

.token.italic {
  font-style: italic
}

.token.entity {
  cursor: help
}

pre[data-line] {
  position: relative;
  padding-left: 3.75em
}

.line-highlight {
  position: absolute;
  left: 0;
  right: 0;
  padding: inherit 0;
  margin-top: 1em;
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05);
  pointer-events: none;
  line-height: inherit;
  white-space: pre
}

@media print {
  .line-highlight {
    -webkit-print-print-color-adjust: exact;
    print-color-adjust: exact
  }
}

.line-highlight:before,
.line-highlight[data-end]:after {
  content: attr(data-start);
  position: absolute;
  width: 2.5em;
  top: 0;
  left: 0;
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5)
}

.line-highlight[data-end]:after {
  content: attr(data-end);
  top: unset;
  bottom: 0
}

.line-numbers .line-highlight:before,
.line-numbers .line-highlight:after {
  content: none
}

pre[id].linkable-line-numbers span.line-numbers-rows {
  pointer-events: all
}

pre[id].linkable-line-numbers span.line-numbers-rows>span:before {
  cursor: pointer
}

pre[id].linkable-line-numbers span.line-numbers-rows>span:hover:before {
  background-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(var(--fg), 0.1)
}

pre[class*="language-"].line-numbers {
  position: relative;
  padding-left: 3.75em;
  counter-reset: linenumber
}

pre[class*="language-"].line-numbers>code {
  position: relative;
  white-space: inherit
}

.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  left: -3.75em;
  width: 2.5em;
  user-select: none
}

.line-numbers-rows>span {
  display: block;
  counter-increment: linenumber
}

.line-numbers-rows>span:before {
  content: counter(linenumber);
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5);
  display: block;
  text-align: right
}

/* @font-face {
  font-family: "KaTeX_AMS";
  src: url(../fonts/KaTeX_AMS-Regular.woff2) format("woff2"), url(../fonts/KaTeX_AMS-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Caligraphic";
  src: url(../fonts/KaTeX_Caligraphic-Bold.woff2) format("woff2"), url(../fonts/KaTeX_Caligraphic-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Caligraphic";
  src: url(../fonts/KaTeX_Caligraphic-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Caligraphic-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Fraktur";
  src: url(../fonts/KaTeX_Fraktur-Bold.woff2) format("woff2"), url(../fonts/KaTeX_Fraktur-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Fraktur";
  src: url(../fonts/KaTeX_Fraktur-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Fraktur-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Main";
  src: url(../fonts/KaTeX_Main-Bold.woff2) format("woff2"), url(../fonts/KaTeX_Main-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Main";
  src: url(../fonts/KaTeX_Main-BoldItalic.woff2) format("woff2"), url(../fonts/KaTeX_Main-BoldItalic.woff) format("woff");
  font-weight: bold;
  font-style: italic
}

@font-face {
  font-family: "KaTeX_Main";
  src: url(../fonts/KaTeX_Main-Italic.woff2) format("woff2"), url(../fonts/KaTeX_Main-Italic.woff) format("woff");
  font-weight: normal;
  font-style: italic
}

@font-face {
  font-family: "KaTeX_Main";
  src: url(../fonts/KaTeX_Main-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Main-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Math";
  src: url(../fonts/KaTeX_Math-BoldItalic.woff2) format("woff2"), url(../fonts/KaTeX_Math-BoldItalic.woff) format("woff");
  font-weight: bold;
  font-style: italic
}

@font-face {
  font-family: "KaTeX_Math";
  src: url(../fonts/KaTeX_Math-Italic.woff2) format("woff2"), url(../fonts/KaTeX_Math-Italic.woff) format("woff");
  font-weight: normal;
  font-style: italic
}

@font-face {
  font-family: "KaTeX_SansSerif";
  src: url(../fonts/KaTeX_SansSerif-Bold.woff2) format("woff2"), url(../fonts/KaTeX_SansSerif-Bold.woff) format("woff");
  font-weight: bold;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_SansSerif";
  src: url(../fonts/KaTeX_SansSerif-Italic.woff2) format("woff2"), url(../fonts/KaTeX_SansSerif-Italic.woff) format("woff");
  font-weight: normal;
  font-style: italic
}

@font-face {
  font-family: "KaTeX_SansSerif";
  src: url(../fonts/KaTeX_SansSerif-Regular.woff2) format("woff2"), url(../fonts/KaTeX_SansSerif-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Script";
  src: url(../fonts/KaTeX_Script-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Script-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Size1";
  src: url(../fonts/KaTeX_Size1-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Size1-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Size2";
  src: url(../fonts/KaTeX_Size2-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Size2-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Size3";
  src: url(../fonts/KaTeX_Size3-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Size3-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Size4";
  src: url(../fonts/KaTeX_Size4-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Size4-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: "KaTeX_Typewriter";
  src: url(../fonts/KaTeX_Typewriter-Regular.woff2) format("woff2"), url(../fonts/KaTeX_Typewriter-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal
} */

.katex {
  font: normal 1em "KaTeX_Main", serif;
  line-height: 1.2;
  text-indent: 0;
  text-rendering: auto
}

.katex * {
  -ms-high-contrast-adjust: none !important
}

.katex .katex-mathml {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden
}

.katex .katex-html>.newline {
  display: block
}

.katex .base {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  width: min-content
}

.katex .strut {
  display: inline-block
}

.katex .textbf {
  font-weight: bold
}

.katex .textit {
  font-style: italic
}

.katex .textrm {
  font-family: "Montserrat"
}

.katex .textsf {
  font-family: "Montserrat"
}

.katex .texttt {
  font-family: "Montserrat"
}

.katex .mathdefault {
  font-family: "Montserrat";
  font-style: italic
}

.katex .mathit {
  font-family: "Montserrat";
  font-style: italic
}

.katex .mathrm {
  font-style: normal
}

.katex .mathbf {
  font-family: "Montserrat";
  font-weight: bold
}

.katex .boldsymbol {
  font-family: "Montserrat";
  font-weight: bold;
  font-style: italic
}

.katex .amsrm {
  font-family: "Montserrat"
}

.katex .mathbb,
.katex .textbb {
  font-family: "Montserrat"
}

.katex .mathcal {
  font-family: "Montserrat"
}

.katex .mathfrak,
.katex .textfrak {
  font-family: "Montserrat"
}

.katex .mathtt {
  font-family: "Montserrat"
}

.katex .mathscr,
.katex .textscr {
  font-family: "Montserrat"
}

.katex .mathsf,
.katex .textsf {
  font-family: "Montserrat"
}

.katex .mathboldsf,
.katex .textboldsf {
  font-family: "Montserrat";
  font-weight: bold
}

.katex .mathitsf,
.katex .textitsf {
  font-family: "Montserrat";
  font-style: italic
}

.katex .mainrm {
  font-family: "Montserrat";
  font-style: normal
}

.katex .vlist-t {
  display: inline-table;
  table-layout: fixed
}

.katex .vlist-r {
  display: table-row
}

.katex .vlist {
  display: table-cell;
  vertical-align: bottom;
  position: relative
}

.katex .vlist>span {
  display: block;
  height: 0;
  position: relative
}

.katex .vlist>span>span {
  display: inline-block
}

.katex .vlist>span>.pstrut {
  overflow: hidden;
  width: 0
}

.katex .vlist-t2 {
  margin-right: -2px
}

.katex .vlist-s {
  display: table-cell;
  vertical-align: bottom;
  font-size: 1px;
  width: 2px;
  min-width: 2px
}

.katex .msupsub {
  text-align: left
}

.katex .mfrac>span>span {
  text-align: center
}

.katex .mfrac .frac-line {
  display: inline-block;
  width: 100%;
  border-bottom-style: solid
}

.katex .mfrac .frac-line,
.katex .overline .overline-line,
.katex .underline .underline-line,
.katex .hline,
.katex .hdashline,
.katex .rule {
  min-height: 1px
}

.katex .mspace {
  display: inline-block
}

.katex .llap,
.katex .rlap,
.katex .clap {
  width: 0;
  position: relative
}

.katex .llap>.inner,
.katex .rlap>.inner,
.katex .clap>.inner {
  position: absolute
}

.katex .llap>.fix,
.katex .rlap>.fix,
.katex .clap>.fix {
  display: inline-block
}

.katex .llap>.inner {
  right: 0
}

.katex .rlap>.inner,
.katex .clap>.inner {
  left: 0
}

.katex .clap>.inner>span {
  margin-left: -50%;
  margin-right: 50%
}

.katex .rule {
  display: inline-block;
  border: solid 0;
  position: relative
}

.katex .overline .overline-line,
.katex .underline .underline-line,
.katex .hline {
  display: inline-block;
  width: 100%;
  border-bottom-style: solid
}

.katex .hdashline {
  display: inline-block;
  width: 100%;
  border-bottom-style: dashed
}

.katex .sqrt>.root {
  margin-left: 0.27777778em;
  margin-right: -0.55555556em
}

.katex .sizing,
.katex .fontsize-ensurer {
  display: inline-block
}

.katex .sizing.reset-size1.size1,
.katex .fontsize-ensurer.reset-size1.size1 {
  font-size: 1em
}

.katex .sizing.reset-size1.size2,
.katex .fontsize-ensurer.reset-size1.size2 {
  font-size: 1.2em
}

.katex .sizing.reset-size1.size3,
.katex .fontsize-ensurer.reset-size1.size3 {
  font-size: 1.4em
}

.katex .sizing.reset-size1.size4,
.katex .fontsize-ensurer.reset-size1.size4 {
  font-size: 1.6em
}

.katex .sizing.reset-size1.size5,
.katex .fontsize-ensurer.reset-size1.size5 {
  font-size: 1.8em
}

.katex .sizing.reset-size1.size6,
.katex .fontsize-ensurer.reset-size1.size6 {
  font-size: 2em
}

.katex .sizing.reset-size1.size7,
.katex .fontsize-ensurer.reset-size1.size7 {
  font-size: 2.4em
}

.katex .sizing.reset-size1.size8,
.katex .fontsize-ensurer.reset-size1.size8 {
  font-size: 2.88em
}

.katex .sizing.reset-size1.size9,
.katex .fontsize-ensurer.reset-size1.size9 {
  font-size: 3.456em
}

.katex .sizing.reset-size1.size10,
.katex .fontsize-ensurer.reset-size1.size10 {
  font-size: 4.148em
}

.katex .sizing.reset-size1.size11,
.katex .fontsize-ensurer.reset-size1.size11 {
  font-size: 4.976em
}

.katex .sizing.reset-size2.size1,
.katex .fontsize-ensurer.reset-size2.size1 {
  font-size: 0.83333333em
}

.katex .sizing.reset-size2.size2,
.katex .fontsize-ensurer.reset-size2.size2 {
  font-size: 1em
}

.katex .sizing.reset-size2.size3,
.katex .fontsize-ensurer.reset-size2.size3 {
  font-size: 1.16666667em
}

.katex .sizing.reset-size2.size4,
.katex .fontsize-ensurer.reset-size2.size4 {
  font-size: 1.33333333em
}

.katex .sizing.reset-size2.size5,
.katex .fontsize-ensurer.reset-size2.size5 {
  font-size: 1.5em
}

.katex .sizing.reset-size2.size6,
.katex .fontsize-ensurer.reset-size2.size6 {
  font-size: 1.66666667em
}

.katex .sizing.reset-size2.size7,
.katex .fontsize-ensurer.reset-size2.size7 {
  font-size: 2em
}

.katex .sizing.reset-size2.size8,
.katex .fontsize-ensurer.reset-size2.size8 {
  font-size: 2.4em
}

.katex .sizing.reset-size2.size9,
.katex .fontsize-ensurer.reset-size2.size9 {
  font-size: 2.88em
}

.katex .sizing.reset-size2.size10,
.katex .fontsize-ensurer.reset-size2.size10 {
  font-size: 3.45666667em
}

.katex .sizing.reset-size2.size11,
.katex .fontsize-ensurer.reset-size2.size11 {
  font-size: 4.14666667em
}

.katex .sizing.reset-size3.size1,
.katex .fontsize-ensurer.reset-size3.size1 {
  font-size: 0.71428571em
}

.katex .sizing.reset-size3.size2,
.katex .fontsize-ensurer.reset-size3.size2 {
  font-size: 0.85714286em
}

.katex .sizing.reset-size3.size3,
.katex .fontsize-ensurer.reset-size3.size3 {
  font-size: 1em
}

.katex .sizing.reset-size3.size4,
.katex .fontsize-ensurer.reset-size3.size4 {
  font-size: 1.14285714em
}

.katex .sizing.reset-size3.size5,
.katex .fontsize-ensurer.reset-size3.size5 {
  font-size: 1.28571429em
}

.katex .sizing.reset-size3.size6,
.katex .fontsize-ensurer.reset-size3.size6 {
  font-size: 1.42857143em
}

.katex .sizing.reset-size3.size7,
.katex .fontsize-ensurer.reset-size3.size7 {
  font-size: 1.71428571em
}

.katex .sizing.reset-size3.size8,
.katex .fontsize-ensurer.reset-size3.size8 {
  font-size: 2.05714286em
}

.katex .sizing.reset-size3.size9,
.katex .fontsize-ensurer.reset-size3.size9 {
  font-size: 2.46857143em
}

.katex .sizing.reset-size3.size10,
.katex .fontsize-ensurer.reset-size3.size10 {
  font-size: 2.96285714em
}

.katex .sizing.reset-size3.size11,
.katex .fontsize-ensurer.reset-size3.size11 {
  font-size: 3.55428571em
}

.katex .sizing.reset-size4.size1,
.katex .fontsize-ensurer.reset-size4.size1 {
  font-size: 0.625em
}

.katex .sizing.reset-size4.size2,
.katex .fontsize-ensurer.reset-size4.size2 {
  font-size: 0.75em
}

.katex .sizing.reset-size4.size3,
.katex .fontsize-ensurer.reset-size4.size3 {
  font-size: 0.875em
}

.katex .sizing.reset-size4.size4,
.katex .fontsize-ensurer.reset-size4.size4 {
  font-size: 1em
}

.katex .sizing.reset-size4.size5,
.katex .fontsize-ensurer.reset-size4.size5 {
  font-size: 1.125em
}

.katex .sizing.reset-size4.size6,
.katex .fontsize-ensurer.reset-size4.size6 {
  font-size: 1.25em
}

.katex .sizing.reset-size4.size7,
.katex .fontsize-ensurer.reset-size4.size7 {
  font-size: 1.5em
}

.katex .sizing.reset-size4.size8,
.katex .fontsize-ensurer.reset-size4.size8 {
  font-size: 1.8em
}

.katex .sizing.reset-size4.size9,
.katex .fontsize-ensurer.reset-size4.size9 {
  font-size: 2.16em
}

.katex .sizing.reset-size4.size10,
.katex .fontsize-ensurer.reset-size4.size10 {
  font-size: 2.5925em
}

.katex .sizing.reset-size4.size11,
.katex .fontsize-ensurer.reset-size4.size11 {
  font-size: 3.11em
}

.katex .sizing.reset-size5.size1,
.katex .fontsize-ensurer.reset-size5.size1 {
  font-size: 0.55555556em
}

.katex .sizing.reset-size5.size2,
.katex .fontsize-ensurer.reset-size5.size2 {
  font-size: 0.66666667em
}

.katex .sizing.reset-size5.size3,
.katex .fontsize-ensurer.reset-size5.size3 {
  font-size: 0.77777778em
}

.katex .sizing.reset-size5.size4,
.katex .fontsize-ensurer.reset-size5.size4 {
  font-size: 0.88888889em
}

.katex .sizing.reset-size5.size5,
.katex .fontsize-ensurer.reset-size5.size5 {
  font-size: 1em
}

.katex .sizing.reset-size5.size6,
.katex .fontsize-ensurer.reset-size5.size6 {
  font-size: 1.11111111em
}

.katex .sizing.reset-size5.size7,
.katex .fontsize-ensurer.reset-size5.size7 {
  font-size: 1.33333333em
}

.katex .sizing.reset-size5.size8,
.katex .fontsize-ensurer.reset-size5.size8 {
  font-size: 1.6em
}

.katex .sizing.reset-size5.size9,
.katex .fontsize-ensurer.reset-size5.size9 {
  font-size: 1.92em
}

.katex .sizing.reset-size5.size10,
.katex .fontsize-ensurer.reset-size5.size10 {
  font-size: 2.30444444em
}

.katex .sizing.reset-size5.size11,
.katex .fontsize-ensurer.reset-size5.size11 {
  font-size: 2.76444444em
}

.katex .sizing.reset-size6.size1,
.katex .fontsize-ensurer.reset-size6.size1 {
  font-size: 0.5em
}

.katex .sizing.reset-size6.size2,
.katex .fontsize-ensurer.reset-size6.size2 {
  font-size: 0.6em
}

.katex .sizing.reset-size6.size3,
.katex .fontsize-ensurer.reset-size6.size3 {
  font-size: 0.7em
}

.katex .sizing.reset-size6.size4,
.katex .fontsize-ensurer.reset-size6.size4 {
  font-size: 0.8em
}

.katex .sizing.reset-size6.size5,
.katex .fontsize-ensurer.reset-size6.size5 {
  font-size: 0.9em
}

.katex .sizing.reset-size6.size6,
.katex .fontsize-ensurer.reset-size6.size6 {
  font-size: 1em
}

.katex .sizing.reset-size6.size7,
.katex .fontsize-ensurer.reset-size6.size7 {
  font-size: 1.2em
}

.katex .sizing.reset-size6.size8,
.katex .fontsize-ensurer.reset-size6.size8 {
  font-size: 1.44em
}

.katex .sizing.reset-size6.size9,
.katex .fontsize-ensurer.reset-size6.size9 {
  font-size: 1.728em
}

.katex .sizing.reset-size6.size10,
.katex .fontsize-ensurer.reset-size6.size10 {
  font-size: 2.074em
}

.katex .sizing.reset-size6.size11,
.katex .fontsize-ensurer.reset-size6.size11 {
  font-size: 2.488em
}

.katex .sizing.reset-size7.size1,
.katex .fontsize-ensurer.reset-size7.size1 {
  font-size: 0.41666667em
}

.katex .sizing.reset-size7.size2,
.katex .fontsize-ensurer.reset-size7.size2 {
  font-size: 0.5em
}

.katex .sizing.reset-size7.size3,
.katex .fontsize-ensurer.reset-size7.size3 {
  font-size: 0.58333333em
}

.katex .sizing.reset-size7.size4,
.katex .fontsize-ensurer.reset-size7.size4 {
  font-size: 0.66666667em
}

.katex .sizing.reset-size7.size5,
.katex .fontsize-ensurer.reset-size7.size5 {
  font-size: 0.75em
}

.katex .sizing.reset-size7.size6,
.katex .fontsize-ensurer.reset-size7.size6 {
  font-size: 0.83333333em
}

.katex .sizing.reset-size7.size7,
.katex .fontsize-ensurer.reset-size7.size7 {
  font-size: 1em
}

.katex .sizing.reset-size7.size8,
.katex .fontsize-ensurer.reset-size7.size8 {
  font-size: 1.2em
}

.katex .sizing.reset-size7.size9,
.katex .fontsize-ensurer.reset-size7.size9 {
  font-size: 1.44em
}

.katex .sizing.reset-size7.size10,
.katex .fontsize-ensurer.reset-size7.size10 {
  font-size: 1.72833333em
}

.katex .sizing.reset-size7.size11,
.katex .fontsize-ensurer.reset-size7.size11 {
  font-size: 2.07333333em
}

.katex .sizing.reset-size8.size1,
.katex .fontsize-ensurer.reset-size8.size1 {
  font-size: 0.34722222em
}

.katex .sizing.reset-size8.size2,
.katex .fontsize-ensurer.reset-size8.size2 {
  font-size: 0.41666667em
}

.katex .sizing.reset-size8.size3,
.katex .fontsize-ensurer.reset-size8.size3 {
  font-size: 0.48611111em
}

.katex .sizing.reset-size8.size4,
.katex .fontsize-ensurer.reset-size8.size4 {
  font-size: 0.55555556em
}

.katex .sizing.reset-size8.size5,
.katex .fontsize-ensurer.reset-size8.size5 {
  font-size: 0.625em
}

.katex .sizing.reset-size8.size6,
.katex .fontsize-ensurer.reset-size8.size6 {
  font-size: 0.69444444em
}

.katex .sizing.reset-size8.size7,
.katex .fontsize-ensurer.reset-size8.size7 {
  font-size: 0.83333333em
}

.katex .sizing.reset-size8.size8,
.katex .fontsize-ensurer.reset-size8.size8 {
  font-size: 1em
}

.katex .sizing.reset-size8.size9,
.katex .fontsize-ensurer.reset-size8.size9 {
  font-size: 1.2em
}

.katex .sizing.reset-size8.size10,
.katex .fontsize-ensurer.reset-size8.size10 {
  font-size: 1.44027778em
}

.katex .sizing.reset-size8.size11,
.katex .fontsize-ensurer.reset-size8.size11 {
  font-size: 1.72777778em
}

.katex .sizing.reset-size9.size1,
.katex .fontsize-ensurer.reset-size9.size1 {
  font-size: 0.28935185em
}

.katex .sizing.reset-size9.size2,
.katex .fontsize-ensurer.reset-size9.size2 {
  font-size: 0.34722222em
}

.katex .sizing.reset-size9.size3,
.katex .fontsize-ensurer.reset-size9.size3 {
  font-size: 0.40509259em
}

.katex .sizing.reset-size9.size4,
.katex .fontsize-ensurer.reset-size9.size4 {
  font-size: 0.46296296em
}

.katex .sizing.reset-size9.size5,
.katex .fontsize-ensurer.reset-size9.size5 {
  font-size: 0.52083333em
}

.katex .sizing.reset-size9.size6,
.katex .fontsize-ensurer.reset-size9.size6 {
  font-size: 0.5787037em
}

.katex .sizing.reset-size9.size7,
.katex .fontsize-ensurer.reset-size9.size7 {
  font-size: 0.69444444em
}

.katex .sizing.reset-size9.size8,
.katex .fontsize-ensurer.reset-size9.size8 {
  font-size: 0.83333333em
}

.katex .sizing.reset-size9.size9,
.katex .fontsize-ensurer.reset-size9.size9 {
  font-size: 1em
}

.katex .sizing.reset-size9.size10,
.katex .fontsize-ensurer.reset-size9.size10 {
  font-size: 1.20023148em
}

.katex .sizing.reset-size9.size11,
.katex .fontsize-ensurer.reset-size9.size11 {
  font-size: 1.43981481em
}

.katex .sizing.reset-size10.size1,
.katex .fontsize-ensurer.reset-size10.size1 {
  font-size: 0.24108004em
}

.katex .sizing.reset-size10.size2,
.katex .fontsize-ensurer.reset-size10.size2 {
  font-size: 0.28929605em
}

.katex .sizing.reset-size10.size3,
.katex .fontsize-ensurer.reset-size10.size3 {
  font-size: 0.33751205em
}

.katex .sizing.reset-size10.size4,
.katex .fontsize-ensurer.reset-size10.size4 {
  font-size: 0.38572806em
}

.katex .sizing.reset-size10.size5,
.katex .fontsize-ensurer.reset-size10.size5 {
  font-size: 0.43394407em
}

.katex .sizing.reset-size10.size6,
.katex .fontsize-ensurer.reset-size10.size6 {
  font-size: 0.48216008em
}

.katex .sizing.reset-size10.size7,
.katex .fontsize-ensurer.reset-size10.size7 {
  font-size: 0.57859209em
}

.katex .sizing.reset-size10.size8,
.katex .fontsize-ensurer.reset-size10.size8 {
  font-size: 0.69431051em
}

.katex .sizing.reset-size10.size9,
.katex .fontsize-ensurer.reset-size10.size9 {
  font-size: 0.83317261em
}

.katex .sizing.reset-size10.size10,
.katex .fontsize-ensurer.reset-size10.size10 {
  font-size: 1em
}

.katex .sizing.reset-size10.size11,
.katex .fontsize-ensurer.reset-size10.size11 {
  font-size: 1.19961427em
}

.katex .sizing.reset-size11.size1,
.katex .fontsize-ensurer.reset-size11.size1 {
  font-size: 0.20096463em
}

.katex .sizing.reset-size11.size2,
.katex .fontsize-ensurer.reset-size11.size2 {
  font-size: 0.24115756em
}

.katex .sizing.reset-size11.size3,
.katex .fontsize-ensurer.reset-size11.size3 {
  font-size: 0.28135048em
}

.katex .sizing.reset-size11.size4,
.katex .fontsize-ensurer.reset-size11.size4 {
  font-size: 0.32154341em
}

.katex .sizing.reset-size11.size5,
.katex .fontsize-ensurer.reset-size11.size5 {
  font-size: 0.36173633em
}

.katex .sizing.reset-size11.size6,
.katex .fontsize-ensurer.reset-size11.size6 {
  font-size: 0.40192926em
}

.katex .sizing.reset-size11.size7,
.katex .fontsize-ensurer.reset-size11.size7 {
  font-size: 0.48231511em
}

.katex .sizing.reset-size11.size8,
.katex .fontsize-ensurer.reset-size11.size8 {
  font-size: 0.57877814em
}

.katex .sizing.reset-size11.size9,
.katex .fontsize-ensurer.reset-size11.size9 {
  font-size: 0.69453376em
}

.katex .sizing.reset-size11.size10,
.katex .fontsize-ensurer.reset-size11.size10 {
  font-size: 0.83360129em
}

.katex .sizing.reset-size11.size11,
.katex .fontsize-ensurer.reset-size11.size11 {
  font-size: 1em
}

.katex .delimsizing.size1 {
  font-family: "Montserrat"
}

.katex .delimsizing.size2 {
  font-family: "Montserrat"
}

.katex .delimsizing.size3 {
  font-family: "Montserrat"
}

.katex .delimsizing.size4 {
  font-family: "Montserrat"
}

.katex .delimsizing.mult .delim-size1>span {
  font-family: "Montserrat"
}

.katex .delimsizing.mult .delim-size4>span {
  font-family: "Montserrat"
}

.katex .nulldelimiter {
  display: inline-block;
  width: 0.12em
}

.katex .delimcenter {
  position: relative
}

.katex .op-symbol {
  position: relative
}

.katex .op-symbol.small-op {
  font-family: "Montserrat"
}

.katex .op-symbol.large-op {
  font-family: "Montserrat"
}

.katex .op-limits>.vlist-t {
  text-align: center
}

.katex .accent>.vlist-t {
  text-align: center
}

.katex .accent .accent-body {
  position: relative
}

.katex .accent .accent-body:not(.accent-full) {
  width: 0
}

.katex .overlay {
  display: block
}

.katex .mtable .vertical-separator {
  display: inline-block;
  margin: 0 -0.025em;
  border-right: 0.05em solid;
  min-width: 1px
}

.katex .mtable .vs-dashed {
  border-right: 0.05em dashed
}

.katex .mtable .arraycolsep {
  display: inline-block
}

.katex .mtable .col-align-c>.vlist-t {
  text-align: center
}

.katex .mtable .col-align-l>.vlist-t {
  text-align: left
}

.katex .mtable .col-align-r>.vlist-t {
  text-align: right
}

.katex .svg-align {
  text-align: left
}

.katex svg {
  display: block;
  position: absolute;
  width: 100%;
  height: inherit;
  fill: currentColor;
  stroke: currentColor;
  fill-rule: nonzero;
  fill-opacity: 1;
  stroke-width: 1;
  stroke-linecap: butt;
  stroke-linejoin: miter;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-dashoffset: 0;
  stroke-opacity: 1
}

.katex svg path {
  stroke: none
}

.katex .stretchy {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden
}

.katex .stretchy:before,
.katex .stretchy:after {
  content: ""
}

.katex .hide-tail {
  width: 100%;
  position: relative;
  overflow: hidden
}

.katex .halfarrow-left {
  position: absolute;
  left: 0;
  width: 50.2%;
  overflow: hidden
}

.katex .halfarrow-right {
  position: absolute;
  right: 0;
  width: 50.2%;
  overflow: hidden
}

.katex .brace-left {
  position: absolute;
  left: 0;
  width: 25.1%;
  overflow: hidden
}

.katex .brace-center {
  position: absolute;
  left: 25%;
  width: 50%;
  overflow: hidden
}

.katex .brace-right {
  position: absolute;
  right: 0;
  width: 25.1%;
  overflow: hidden
}

.katex .x-arrow-pad {
  padding: 0 0.5em
}

.katex .x-arrow,
.katex .mover,
.katex .munder {
  text-align: center
}

.katex .boxpad {
  padding: 0 0.3em 0 0.3em
}

.katex .fbox {
  box-sizing: border-box;
  border: 0.04em solid black
}

.katex .fcolorbox {
  box-sizing: border-box;
  border: 0.04em solid
}

.katex .cancel-pad {
  padding: 0 0.2em 0 0.2em
}

.katex .cancel-lap {
  margin-left: -0.2em;
  margin-right: -0.2em
}

.katex .sout {
  border-bottom-style: solid;
  border-bottom-width: 0.08em
}

.katex-display {
  display: block;
  margin: 1em 0;
  text-align: center
}

.katex-display>.katex {
  display: block;
  text-align: center;
  white-space: nowrap
}

.katex-display>.katex>.katex-html {
  display: block;
  position: relative
}

.katex-display>.katex>.katex-html>.tag {
  position: absolute;
  right: 0
}

.js .js-lazy {
  opacity: 0;
  transition: opacity .2s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.js video.js-lazy {
  transition: opacity .2s cubic-bezier(0.645, 0.045, 0.355, 1) .2s
}

.js .js-lazy-loaded {
  opacity: 1
}

.kg-width-wide {
  position: relative;
  max-width: 1470px;
  width: 93.3333333333vw;
  left: 0;
  margin-top: 4.2rem;
  margin-bottom: 4.2rem
}

@media (min-width: 720px) {
  .kg-width-wide {
    width: 93.3333333333vw
  }
}

@media (min-width: 1280px) {
  .kg-width-wide {
    margin-left: calc(50% - 46.6666666667vw);
    transform: translateX(calc(46.6666666667vw - 50%))
  }
}

.kg-width-wide img {
  width: 100%
}

.kg-width-full {
  position: relative;
  width: 100vw;
  left: 0;
  transform: translateX(-3.3333333333vw);
  margin-top: 4.2rem;
  margin-bottom: 4.2rem
}

@media (min-width: 720px) {
  .kg-width-full {
    transform: translateX(-3.3333333333vw)
  }
}

@media (min-width: 1280px) {
  .kg-width-full {
    left: 50%;
    transform: translateX(-50vw)
  }
}

.kg-width-full img {
  width: 100%
}

.kg-width-full figcaption {
  width: 90vw;
  margin-left: auto;
  margin-right: auto
}

@media (min-width: 720px) {
  .kg-width-full figcaption {
    width: 92.5vw
  }
}

@media (min-width: 1280px) {
  .kg-width-full figcaption {
    text-align: center
  }
}

.kg-gallery-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.4rem
}

.kg-gallery-row {
  display: flex;
  flex-direction: row;
  justify-content: center
}

.kg-gallery-image img {
  display: block;
  margin: 0;
  width: 100%;
  height: 100%
}

.kg-gallery-row:not(:first-of-type) {
  margin-top: 3.3333333333vw
}

@media (min-width: 720px) {
  .kg-gallery-row:not(:first-of-type) {
    margin-top: 1.4rem
  }
}

.kg-gallery-image:not(:first-of-type) {
  margin-left: 3.3333333333vw
}

@media (min-width: 720px) {
  .kg-gallery-image:not(:first-of-type) {
    margin-left: 1.4rem
  }
}

.kg-bookmark-card {
  border-top: 1px solid rgba(var(--fg), 0.0875);
  border-bottom: 1px solid rgba(var(--fg), 0.0875)
}

.kg-bookmark-card+.kg-bookmark-card {
  border-top: unset;
  margin-top: -1.4rem
}

.kg-bookmark-container {
  background-image: unset !important
}

.kg-bookmark-content {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem
}

.kg-bookmark-title {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: unset;
  letter-spacing: unset;
  font-weight: bold
}

.kg-bookmark-description {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: unset;
  letter-spacing: unset;
  opacity: 0.8
}

.kg-bookmark-metadata,
.kg-bookmark-icon,
.kg-bookmark-author,
.kg-bookmark-publisher,
.kg-bookmark-thumbnail {
  display: none
}

:root {
  --nav-symbol-width: 6rem;
  --nav-padding: 10px;
  --nav-height: 67px
}

@media (min-width: 480px) {
  :root {
    --nav-symbol-width: 6rem
  }
}

@media (min-width: 960px) {
  :root {
    --nav-padding: 10px;
    --nav-height: 70px
  }
}

.post-header--color-white {
  --fg: 255, 255, 255
}

.post-header--dark-gray {
  color: #FFFFFF;
  background-color: #000000;
  --fg: 255, 255, 255;
  --bg: 0, 0, 0
}

.post-header--mid-gray {
  color: #000000;
  background-color: #A8A8A8;
  --fg: 0, 0, 0;
  --bg: 168, 168, 168
}

.post-header--light-gray {
  color: #000000;
  background-color: #FFFFFF;
  --fg: 0, 0, 0;
  --bg: 255, 255, 255
}

.post-header--dark-red {
  color: #AFC1FF;
  background-color: #541600;
  --fg: 175, 193, 255;
  --bg: 84, 22, 0
}

.post-header--mid-red {
  color: #000000;
  background-color: #FF4500;
  --fg: 0, 0, 0;
  --bg: 255, 69, 0
}

.post-header--light-red {
  color: #0000FF;
  background-color: #FFE0DB;
  --fg: 0, 0, 255;
  --bg: 255, 224, 219
}

.post-header--dark-green {
  color: #FF8BFF;
  background-color: #193718;
  --fg: 255, 139, 255;
  --bg: 25, 55, 24
}

.post-header--mid-green {
  color: #000000;
  background-color: #51DA4C;
  --fg: 0, 0, 0;
  --bg: 81, 218, 76
}

.post-header--light-green {
  color: #C500C5;
  background-color: #D9FFD8;
  --fg: 197, 0, 197;
  --bg: 217, 255, 216
}

.post-header--dark-blue {
  color: #FFF639;
  background-color: #00002E;
  --fg: 255, 246, 57;
  --bg: 0, 0, 46
}

.post-header--mid-blue {
  color: #FFFFFF;
  background-color: #0000FF;
  --fg: 255, 255, 255;
  --bg: 0, 0, 255
}

.post-header--light-blue {
  color: #343322;
  background-color: #E5F1FF;
  --fg: 52, 51, 34;
  --bg: 229, 241, 255
}

.post-header--dark-cyan {
  color: #FFC98A;
  background-color: #005454;
  --fg: 255, 201, 138;
  --bg: 0, 84, 84
}

.post-header--mid-cyan {
  color: #000000;
  background-color: #00FFFF;
  --fg: 0, 0, 0;
  --bg: 0, 255, 255
}

.post-header--light-cyan {
  color: #833600;
  background-color: #CAFFFF;
  --fg: 131, 54, 0;
  --bg: 202, 255, 255
}

.post-header--dark-magenta {
  color: #51DA4C;
  background-color: #400040;
  --fg: 81, 218, 76;
  --bg: 64, 0, 64
}

.post-header--mid-magenta {
  color: #000000;
  background-color: #FF45FF;
  --fg: 0, 0, 0;
  --bg: 255, 69, 255
}

.post-header--light-magenta {
  color: #2D712A;
  background-color: #FFEBFF;
  --fg: 45, 113, 42;
  --bg: 255, 235, 255
}

.post-header--dark-yellow {
  color: #E5F1FF;
  background-color: #343322;
  --fg: 229, 241, 255;
  --bg: 52, 51, 34
}

.post-header--mid-yellow {
  color: #000000;
  background-color: #FFF639;
  --fg: 0, 0, 0;
  --bg: 255, 246, 57
}

.post-header--light-yellow {
  color: #0000FF;
  background-color: #FFFFB6;
  --fg: 0, 0, 255;
  --bg: 255, 255, 182
}

.post-header--dark-orange {
  color: #E5F1FF;
  background-color: #401900;
  --fg: 229, 241, 255;
  --bg: 64, 25, 0
}

.post-header--mid-orange {
  color: #0404AC;
  background-color: #FE7600;
  --fg: 4, 4, 172;
  --bg: 254, 118, 0
}

.post-header--light-orange {
  color: #0000FF;
  background-color: #FFF1D6;
  --fg: 0, 0, 255;
  --bg: 255, 241, 214
}

.post-header--dark-brown {
  color: #AFC1FF;
  background-color: #2A1003;
  --fg: 175, 193, 255;
  --bg: 42, 16, 3
}

.post-header--mid-brown {
  color: #E5F1FF;
  background-color: #A0522D;
  --fg: 229, 241, 255;
  --bg: 160, 82, 45
}

.post-header--light-brown {
  color: #0000FF;
  background-color: #E8C7B6;
  --fg: 0, 0, 255;
  --bg: 232, 199, 182
}

.post-header--dark-violet {
  color: #7CF178;
  background-color: #28044A;
  --fg: 124, 241, 120;
  --bg: 40, 4, 74
}

.post-header--mid-violet {
  color: #A6FFA3;
  background-color: #8A2BE2;
  --fg: 166, 255, 163;
  --bg: 138, 43, 226
}

.post-header--light-violet {
  color: #2D712A;
  background-color: #FEE6FF;
  --fg: 45, 113, 42;
  --bg: 254, 230, 255
}

.centering-tabs {
  white-space: nowrap;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 23px
}

.centering-tabs__inner {
  transition: 250ms transform
}

.centering-tabs__tab {
  display: inline-block;
  font-weight: normal;
  opacity: .5;
  padding: 10px
}

.centering-tabs__tab--isActive {
  opacity: 1
}

.centering-tabs__content__item {
  display: none
}

.centering-tabs__content__item.centering-tabs__content__item--isActive {
  display: block
}

.nav-row {
  padding-top: var(--nav-padding);
  padding-bottom: var(--nav-padding)
}

.nav-symbol-wrap {
  position: relative
}

.nav-symbol-wrap,
.nav-symbol {
  width: var(--nav-symbol-width)
}

.nav-symbol {
  display: block;
  margin-top: -0.25rem
}

@media (min-width: 480px) {
  .nav-symbol {
    margin-left: -1px
  }
}

.nav-symbol svg {
  display: block
}

.nav-link.active,
.nav-link.active-parent {
  opacity: 0.5
}

.post-card-tags {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5)
}

.post-card-tags a:hover {
  color: #000;
  color: rgba(var(--fg), 1)
}

.post-card-full[data-year="2023"]+.post-card-full[data-year="2023"] .post-card-full-hide,
.post-card-full[data-year="2022"]+.post-card-full[data-year="2022"] .post-card-full-hide,
.post-card-full[data-year="2021"]+.post-card-full[data-year="2021"] .post-card-full-hide,
.post-card-full[data-year="2020"]+.post-card-full[data-year="2020"] .post-card-full-hide,
.post-card-full[data-year="2019"]+.post-card-full[data-year="2019"] .post-card-full-hide,
.post-card-full[data-year="2018"]+.post-card-full[data-year="2018"] .post-card-full-hide,
.post-card-full[data-year="2017"]+.post-card-full[data-year="2017"] .post-card-full-hide,
.post-card-full[data-year="2016"]+.post-card-full[data-year="2016"] .post-card-full-hide,
.post-card-full[data-year="2015"]+.post-card-full[data-year="2015"] .post-card-full-hide {
  display: none
}

.post-excerpt {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: unset;
  letter-spacing: unset
}

.post-excerpt a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.4);
  text-decoration-color: rgba(var(--fg), 0.4);
  transition: color 100ms cubic-bezier(0.645, 0.045, 0.355, 1)
}

.post-excerpt a:hover {
  opacity: .6 !important
}

.post-excerpt-large {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 1.1666666667rem;
  line-height: 1.3;
  letter-spacing: -0.005em;
  font-weight: unset
}

@media (min-width: 960px) {
  .post-excerpt-large {
    font-size: 1.25rem
  }
}

.js-excerpt,
.js-custom-media {
  display: none
}

.post-author:after,
.post-tag:after {
  content: ",\00a0"
}

.post-author:last-of-type:after,
.post-tag:last-of-type:after {
  content: ""
}

.post-author:nth-last-of-type(2):after {
  content: " &\00a0"
}

.post-header-banner {
  max-height: 24rem
}

@media (min-width: 720px) {
  .post-header-date {
    margin-top: calc(0.8rem - 10px)
  }
}

@media (min-width: 720px) {
  .post-header-date--large-excerpt {
    margin-top: calc(0.8rem - 8px)
  }
}

@media (min-width: 720px) {
  .post-header-date--content {
    margin-top: calc(0.8rem - 11px)
  }
}

@media (min-width: 720px) {
  .post-header-no-excerpt-spacer {
    padding-bottom: 1.05rem
  }
}

.post-toc {
  position: sticky;
  top: -1px;
  transform: translateX(-3.3333333vw);
  margin-bottom: 40px;
  background-color: #fff;
  width: 100vw;
  z-index: 1
}

@media (min-width: 1280px) {
  .post-toc {
    position: absolute;
    transform: none;
    margin-bottom: 0;
    background-color: transparent;
    height: 100%;
    width: 50%;
    top: 0;
    left: -50%
  }
}

.post-toc.stuck {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1)
}

@media (min-width: 1280px) {
  .post-toc.stuck {
    box-shadow: none
  }
}

.post-toc-scrollContainer {
  width: 100%;
  padding: 20px 3.333333vw;
  overflow: auto;
  scrollbar-width: none;
  -webkit-overflow-scrolling: touch
}

@media (min-width: 1280px) {
  .post-toc-scrollContainer {
    height: 100%;
    padding: 0 0 var(--padding-offset);
    overflow: initial
  }
}

.post-toc-trigger {
  position: absolute;
  top: -1px;
  left: 0;
  height: 1px;
  width: 100%
}

.post-toc-scrollContainer::-webkit-scrollbar {
  display: none
}

.post-toc-inner {
  min-width: max-content
}

@media (min-width: 1280px) {
  .post-toc-inner {
    position: sticky;
    top: 24px;
    padding: 0 0.7rem;
    min-width: 0
  }
}

ol.post-toc-chapter-list {
  margin-bottom: 0;
  display: grid;
  grid-auto-flow: column;
  gap: 0 32px
}

@media (min-width: 1280px) {
  ol.post-toc-chapter-list {
    max-width: 83%;
    display: block
  }
}

.post-footer {
  position: relative;
  max-width: 1470px;
  width: 93.3333333333vw;
  left: 0;
  margin-top: 4.2rem;
  margin-bottom: 4.2rem;
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: unset;
  letter-spacing: unset;
  margin-bottom: 0
}

@media (min-width: 720px) {
  .post-footer {
    width: 93.3333333333vw
  }
}

@media (min-width: 1280px) {
  .post-footer {
    margin-left: calc(50% - 46.6666666667vw);
    transform: translateX(calc(46.6666666667vw - 50%))
  }
}

.post-footer img {
  width: 100%
}

.post-footer a:not(.no-underline) {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 0.05em;
  text-decoration-color: rgba(0, 0, 0, 0.2) !important;
  text-decoration-color: rgba(var(--fg), 0.2) !important
}

.post-footer hr {
  margin-top: 0;
  margin-bottom: 0
}

.post-footer p {
  margin-bottom: .7rem
}

.post-footer ol,
.post-footer li:last-of-type,
.post-footer p:last-child {
  margin-bottom: 0
}

.post-footer pre {
  margin-top: 0;
  margin-bottom: .7rem
}

.post-footer .row {
  margin-top: .6708333333rem;
  margin-bottom: .5541666667rem
}

.post-footer .col {
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5);
  margin-top: .0875rem;
  margin-bottom: .0875rem
}

.post-footer,
.post-footer--authors,
.post-footer--tags {
  margin-top: 5.6rem
}

.post-footer--authors,
.post-footer--tags {
  padding-bottom: 1px;
  margin-bottom: -1px
}

.post-footer+.post-footer {
  margin-top: 0
}

.release-cover>video {
  transform: scale(1.0075)
}

.last-child-mb-1>*:last-child {
  margin-bottom: 1.4rem !important
}

.footer {
  margin-top: 1rem
}

.footer-logo {
  width: 6rem
}

@media (min-width: 960px) {
  .footer-logo {
    top: 2px
  }
}

.footer-logo svg {
  display: block
}

.footer-icon {
  font-size: 1.375em
}

@media (min-width: 480px) {
  .footer-icon {
    font-size: 1.25em
  }
}

.subscribe-email,
.subscribe-button {
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15);
  border-color: rgba(var(--fg), 0.15)
}

.subscribe-form .subscribe-email,
.subscribe-form .subscribe-button {
  height: 1.5rem
}

.subscribe-form--large .subscribe-email,
.subscribe-form--large .subscribe-button {
  height: 2rem
}

.subscribe-email {
  min-width: 12rem;
  appearance: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 0.4em;
  padding-right: 0.4em;
  border-right-width: 0;
  outline: none;
  background-color: transparent;
  padding-top: 2px;
  padding-bottom: 0
}

.subscribe-form--large .subscribe-email {
  min-width: 16rem
}

.subscribe-email:hover {
  background-color: rgba(0, 0, 0, 0.02);
  background-color: rgba(var(--fg), 0.02)
}

.subscribe-email:focus {
  background-color: #fff;
  background-color: rgba(var(--bg), 1);
  border-color: rgba(0, 0, 0, 0.15);
  border-color: rgba(var(--fg), 0.15)
}

.subscribe-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 0.25em;
  padding-right: 0.06em;
  background-color: #fff;
  background-color: rgba(var(--bg), 1)
}

.video-swap.is-video-swapped,
.video-swap .video-target {
  max-height: 100vh;
  height: 56.25vw
}

.video-swap,
.video-cover {
  position: relative
}

.video-trigger {
  position: relative;
  transition: filter .2s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.video-trigger.js-lazy {
  transition: opacity .2s cubic-bezier(0.645, 0.045, 0.355, 1), filter .2s cubic-bezier(0.645, 0.045, 0.355, 1)
}

.video-trigger:hover {
  filter: brightness(80%)
}

.video-trigger>span {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(-0.5rem, -50%);
  text-align: center;
  color: #fff
}

.is-video-swapped .video-trigger {
  display: none
}

.video-trigger .icon {
  font-size: 1.5em;
  margin-right: .175rem;
  position: relative;
  top: 0.45rem
}

.video-target {
  visibility: hidden;
  position: absolute;
  top: 0;
  z-index: -1
}

.is-video-swapped .video-target {
  visibility: visible;
  position: unset;
  top: unset;
  z-index: unset
}

.video-cover .fluidvids {
  visibility: hidden;
  position: absolute;
  top: 0;
  z-index: -1
}

.video-cover.is-video-swapped .fluidvids {
  visibility: visible;
  position: unset;
  top: unset;
  z-index: unset
}

.footnote-ref,
.reference-ref {
  margin-left: 0.05em;
  margin-right: 0.05em
}

.reference-ref.grouped {
  margin-left: -0.05em
}

.reference-ref.grouped:before {
  content: ","
}

.footnote-ref a,
.reference-ref a {
  text-decoration: none !important
}

.footnote-ref a:target,
.footnote-item:target,
.reference-ref a:target,
.reference:target {
  background-color: rgba(0, 0, 0, 0.05);
  background-color: rgba(var(--fg), 0.05)
}

.reference p {
  display: inline
}

.footnotes-sep {
  display: none
}

.footnotes p {
  margin-bottom: 0
}

.footnotes-list {
  margin-bottom: 0 !important
}

.jekyll-content .footnotes {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: .75rem;
  font-weight: unset;
  letter-spacing: unset;
  color: rgba(0, 0, 0, 0.5);
  color: rgba(var(--fg), 0.5);
  margin-top: 2.8rem;
  margin-bottom: 1.4rem
}

.jekyll-content .footnotes:before {
  content: "";
  width: 4em;
  height: 1px;
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  background-color: rgba(var(--fg), 0.2)
}

.jekyll-content .footnotes ol {
  margin-top: .7rem
}

.jekyll-content .footnotes p {
  margin-bottom: .7rem
}

.api-secondary-nav .api-secondary-nav__mobile-list {
  display: none
}

.api-secondary-nav__toggle__close {
  visibility: hidden
}

.api-secondary-nav__toggle__open {
  visibility: visible
}

.api-secondary-nav--open .api-secondary-nav__mobile-list {
  display: block
}

@media (min-width: 960px) {
  .api-secondary-nav--open .api-secondary-nav__mobile-list {
    visibility: hidden
  }
}

.api-secondary-nav--open .api-secondary-nav__toggle__close {
  visibility: visible
}

.api-secondary-nav--open .api-secondary-nav__toggle__open {
  visibility: hidden
}

.code-block {
  background-color: #202123;
  border-radius: 6px;
  display: block;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 15px;
  line-height: 21px;
  padding: 22px 25px;
  overflow: auto;
  white-space: pre-wrap
}

.code-block--terminal {
  color: #D9D9E3;
  color: #D9D9E3
}

.code-block--expandable__toggle {
  margin-top: 10px;
  height: 22px;
  transform: translateY(22px);
  position: relative;
  width: 100%
}

.code-block--expandable__toggle:before {
  content: url("data:image/svg+xml,%3Csvg width='15' height='15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.875 6.25a.625.625 0 0 0 .25.5l5 3.75a.625.625 0 0 0 .75 0l5-3.75a.625.625 0 1 0-.75-1L7.5 9.219 2.875 5.75a.625.625 0 0 0-1 .5Z' fill='%23F7F7F8'/%3E%3C/svg%3E");
  display: inline-block;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%)
}

.code-block--expandable__content {
  display: none
}

.code-block--expandable--expanded .code-block--expandable__content {
  display: block
}

.code-block--expandable--expanded .code-block--expandable__toggle:before {
  transform: translateX(-50%) translateY(-4px) rotate(180deg)
}

#post-api-policies-terms .content ol,
#post-api-policies-terms .content ul,
#post-api-policies-privacy .content ol,
#post-api-policies-privacy .content ul,
#post-api-policies-sharing-publication .content ol,
#post-api-policies-sharing-publication .content ul,
#post-api-policies-developer-agreement .content ol,
#post-api-policies-developer-agreement .content ul,
#post-api-policies-free-trial-agreement .content ol,
#post-api-policies-free-trial-agreement .content ul,
#post-api-policies-community-guidelines .content ol,
#post-api-policies-community-guidelines .content ul,
#post-api-policies-codex-terms .content ol,
#post-api-policies-codex-terms .content ul {
  padding-left: 1.25em
}

#post-api-pricing .table td,
#post-api-pricing .table th {
  width: 33%
}

#post-api-pricing .table td:first-of-type,
#post-api-pricing .table th:first-of-type {
  width: 33%
}

#post-api-pricing .table tr:nth-child(odd) td {
  background-color: transparent
}

#post-api-pricing .table tr:nth-child(even) td {
  background-color: rgba(25, 25, 39, 0.05)
}

.page-api pre.language-python,
.page-microsoft-for-startups pre.language-python,
.page-contact-sales pre.language-python {
  padding: 0;
  background-color: #191927;
  border-radius: 6px
}

.page-api pre.language-python,
.page-api pre.language-python .punctuation,
.page-api pre.language-python .operator,
.page-microsoft-for-startups pre.language-python,
.page-microsoft-for-startups pre.language-python .punctuation,
.page-microsoft-for-startups pre.language-python .operator,
.page-contact-sales pre.language-python,
.page-contact-sales pre.language-python .punctuation,
.page-contact-sales pre.language-python .operator {
  color: white
}

.page-api pre.language-python .string,
.page-microsoft-for-startups pre.language-python .string,
.page-contact-sales pre.language-python .string {
  color: #009c72
}

.page-api pre.language-python .triple-quoted-string,
.page-microsoft-for-startups pre.language-python .triple-quoted-string,
.page-contact-sales pre.language-python .triple-quoted-string {
  color: #a3a3a8
}

.page-api pre.language-python code,
.page-microsoft-for-startups pre.language-python code,
.page-contact-sales pre.language-python code {
  display: block;
  white-space: pre-wrap
}

.page-api .colfax-17,
.page-microsoft-for-startups .colfax-17,
.page-contact-sales .colfax-17 {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 16.67px;
  line-height: 1.39
}

.page-api .colfax-18,
.page-microsoft-for-startups .colfax-18,
.page-contact-sales .colfax-18 {
  font-family: Montserrat, Helvetica, sans-serif;
  font-size: 18.33px;
  line-height: 1.4
}

.page-api .mono-17,
.page-microsoft-for-startups .mono-17,
.page-contact-sales .mono-17 {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
  font-size: 16.67px;
  line-height: 1.39;
  font-weight: 700
}

.page-api .charter-20,
.page-microsoft-for-startups .charter-20,
.page-contact-sales .charter-20 {
  font-family: Charter;
  font-size: 20px;
  line-height: 1.4
}

.page-api .familytime-card,
.page-microsoft-for-startups .familytime-card,
.page-contact-sales .familytime-card {
  padding: 53px 41px
}

.page-api .familytime-card .colfax-17,
.page-microsoft-for-startups .familytime-card .colfax-17,
.page-contact-sales .familytime-card .colfax-17 {
  margin-bottom: 28px
}

@media (min-width: 720px) {

  .page-api .sm\:ml-auto,
  .page-microsoft-for-startups .sm\:ml-auto,
  .page-contact-sales .sm\:ml-auto {
    margin-left: auto
  }
}

.page-api .arrow-list-container ul,
.page-microsoft-for-startups .arrow-list-container ul,
.page-contact-sales .arrow-list-container ul {
  padding: 0
}

.page-api .arrow-list-container li,
.page-microsoft-for-startups .arrow-list-container li,
.page-contact-sales .arrow-list-container li {
  background-repeat: no-repeat;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAARCAYAAADQWvz5AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEYSURBVHgBpZI7jsIwEIY9idtILhaJiMbNPrQVuQF02+4ROAonyBVoqTgC3IBUCNGQBkFBYSSKVDEzyEhWeHiAX7LskezP/zyECChNv3Nc61brs/voXiQCArA93LSU0fQRLAiq6+gfQBg8KoRN2u0f/RJot1uWAHHfwTQ6nN6CgWAKH/cI4sLSWujTJ2xHnrMZPh648MpZLJ7Q8bgvkuTjgMc/XIoaIWUyripTQZp+zTHDrnhZtthuVxmmBkq8JVBKaSWpaBhp9jOw5D534bnoxpSG3TUSFdd1TtM41DVkl86xu9aE0Gz57Wc58iHn36M422wWhX8n6KgJoVlqQlgghOQ+BNMZ3brHAWkHGd6DsEQz0un8Bgf2BJsFa9UjcENcAAAAAElFTkSuQmCC");
  background-position: left 2px top 8px;
  background-size: 10px;
  list-style-position: inside;
  padding-left: 26px;
  list-style-type: none
}

.page-api__pre-1 {
  max-width: 657px
}

.page-api__pre-1 .keyword {
  color: #f22c3d
}

.page-api__pre-2 .keyword {
  color: #3582ca
}

.page-api__pre-2 .function {
  color: #df4145
}

.align-middle {
  vertical-align: middle
}

.invisible {
  visibility: hidden
}

@media (min-width: 720px) {
  .md\:d-none {
    display: none !important
  }
}

@media (min-width: 720px) {
  .md\:d-block {
    display: block !important
  }
}

@media (min-width: 480px) {
  .sm\:d-inline {
    display: inline !important
  }
}

.d-grid {
  display: grid
}

.normal-case {
  text-transform: none
}

.opacity-0 {
  opacity: 0
}

.opacity-5 {
  opacity: 0.05
}

.opacity-10 {
  opacity: 0.1
}

.opacity-20 {
  opacity: 0.2
}

.opacity-25 {
  opacity: 0.25
}

.opacity-30 {
  opacity: 0.3
}

.opacity-40 {
  opacity: 0.4
}

.opacity-50 {
  opacity: 0.5
}

.opacity-60 {
  opacity: 0.6
}

.opacity-70 {
  opacity: 0.7
}

.opacity-75 {
  opacity: 0.75
}

.opacity-80 {
  opacity: 0.8
}

.opacity-90 {
  opacity: 0.9
}

.opacity-95 {
  opacity: 0.95
}

.opacity-100 {
  opacity: 1
}

.scroll-behavior-smooth {
  scroll-behavior: smooth
}

.fw-700 {
  font-weight: 700 !important;
}

[style*="--aspect-ratio"]>:first-child {
  width: 100%
}

[style*="--aspect-ratio"]>img {
  height: auto
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)))
  }

  [style*="--aspect-ratio"]>:first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%
  }
}

.text-bold {
  font-family: 'MontserratBold';
}

.white-space-nowrap {
  white-space: nowrap;
}

.white-border {
  border: 2px solid var(--primary-bg);
  border-radius: 3em;
  padding: 0.7em 1.333333em 0.6em;
}

.primary-border {
  border: 2px solid var(--primary-color);
  border-radius: 3em;
  padding: 0.7em 1.333333em 0.6em;
}